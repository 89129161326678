import Vue from 'vue'
import VueRouter from 'vue-router'

import Profile from './components/Profile.vue'
import SiteDiary from './components/SiteDiary.vue'
import BudgetManager from './components/BudgetManager'
import FileManager from './components/FileManager'
import ProjectAndChart from './components/ProjectAndChart.vue'
import ProjectPlan from './components/ProjectPlan'
import Login from './components/Login'
import Register from './components/Register'
import ForgotPassword from './components/ForgotPassword'
import VerifyAccount from './components/Verify'
import Snag from './components/Snag'
// import Notifications from './components/Notifications'
import Activities from './components/Activities'
import MessageComponent from './components/Messages'
import DesignTracker from './components/DesignTracker'
import Decisions from './components/Decisions'

import InviteMember from './components/InviteMember'
import InviteClient from './components/InviteClient'
import AcceptInvite from './components/AcceptInvite'
import DialyWorkerLogs from './components/DailyWorkerLogs.vue'

import GanttChart from './subcomponents/Gantt'

Vue.use(VueRouter)


let routes = [
    // Login
    // { path: '/login', component: Bar },
    { path: '/', name: 'index', component: Login},
    { path: '/register', name: 'register', component: Register },
    { path: '/forgot-password', name: 'forgotpassword', component: ForgotPassword },
    { path: '/profile', name: 'profile', component: Profile},
    { path: '/sitediary', name: 'sitediary', component: SiteDiary },
    { path: '/ganttchart', name: 'ganttchart', component: GanttChart },
    { path: '/budgetmanager', name: 'budgetmanager', component: BudgetManager,
        beforeEnter: (to, from, next) => {
            if(localStorage.getItem('role')==1 || localStorage.getItem('role')==3){
                next();
            }
            else{
                next('/');
            }
        } 
    },
    { path: '/filemanager', name: 'filemanager', component: FileManager },
    { path: '/projectplanner', name: 'projectplanner', component: ProjectAndChart },
    { path: '/designtracker', name: 'designtracker', component: DesignTracker },
    { path: '/decisions', name: 'decision', component: Decisions },
    { path: '/verify/:token', name: 'verifyaccount', component: VerifyAccount },
    { path: '/snags', name: 'snags', component: Snag },
    // { path: '/notifications', name: 'notifications', component: Notifications },
    { path: '/activities', name: 'activities', component: Activities },
    { path: '/messages', name: 'messages', component: MessageComponent },
    { path: '/team', name: 'invitemember', component: InviteMember, 
        beforeEnter: (to, from, next) => {
            if(localStorage.getItem('role')==1){
                next();
            }
            else{
                next('/');
            }
        }
    },
    { path: '/client', name: 'inviteclient', component: InviteClient, 
        beforeEnter: (to, from, next) => {
            if(localStorage.getItem('role')==1){
                next();
            }
            else{
                next('/');
            }
        }
    },
    { path: '/ainvite/:token', name: 'acceptInvite', component: AcceptInvite },
    { path: '/dailyworkerlogs', name: 'dailyworkerlogs', component: DialyWorkerLogs },
    { path: '/projectnew', name: 'projectnew', component: ProjectPlan },
]

export default new VueRouter({
    mode: 'history',
    routes
})

// export const router = new VueRouter({
//     mode: 'history',
//     routes
// })