<template>
    <v-container>
        <v-card>
            <v-card-title style="background: #e3e3e4 !important;"><small><span style="position:relative; top: 5px;" class="material-icons-outlined mr-1">view_agenda</span> Design Tracker</small></v-card-title>
            <v-card-text>
                <v-tabs
                    v-model="tab"
                    class="pl-3 custom-tabs mt-3"
                    lg="12"
                    sm="12"
                    cols="12"
                >
                    <v-tab href="#tab-1" class="custom-tab-1 mr-3">
                        <div id="myTemplatesButton">
                            Furnitures, Fixture & Fittings
                        </div>
                    </v-tab>
                    
                    <v-tab href="#tab-2" class="custom-tab-1 mr-3">
                        <div id="systemTemplatesButton">
                            Appliances
                        </div>
                    </v-tab>

                    <v-tab href="#tab-3" class="custom-tab-1 mr-3">
                        <div id="systemTemplatesButton">
                            Flooring Calculations
                        </div>
                    </v-tab>
                    
                </v-tabs>
                <v-tabs-items v-if="tab === 'tab-1'">
                    <v-container>
                        <v-card>
                            <v-card-title>Furnitures, Fixture & Fittings</v-card-title>
                            <v-card-text>
                                <v-row
                                    no-gutters
                                >
                                    <v-col
                                        cols="12"
                                    >
                                        <v-list>
                                            <v-list-group
                                                v-for="item in getfff"
                                                :key="item.id"
                                                no-action
                                            >
                                                <template v-slot:activator>
                                                    <v-list-item-content>
                                                        <v-list-item-title><h4>{{ item.room }}</h4></v-list-item-title>
                                                        <v-list-item-subtitle>
                                                            <label v-if="item.child_info_done.length != item.child_info.length">{{ item.child_info.length - item.child_info_done.length }} tasks remaining</label>
                                                            <label v-else>All done! Good job</label>
                                                        </v-list-item-subtitle>
                                                        <v-progress-linear
                                                            color="#00ae3b"
                                                            height="12"
                                                            :value="Math.ceil(item.child_info_done.length * (100 / item.child_info.length))"
                                                        >
                                                        <!-- v-if="Math.ceil(item.child_info_done.length * (100 / item.child_info.length))<100" -->
                                                        <!-- {{ Math.ceil(item.child_info_done.length * (100 / item.child_info.length)) }} % -->
                                                        </v-progress-linear>
                                                        <!-- <v-progress-linear
                                                            color="blue-grey"
                                                            height="12"
                                                            :value="Math.ceil(item.child_info_done.length * (100 / item.child_info.length))"
                                                            v-else
                                                        >100 %
                                                        </v-progress-linear> -->
                                                    </v-list-item-content>
                                                </template>

                                                <v-data-table
                                                    :headers="role == 1 || role == 2 ? headersfff : headersfffclient"
                                                    :items="item.child_info"
                                                    class="elevation-2"
                                                    style="width: 100%;"
                                                    :hide-default-footer="true"
                                                    disable-pagination
                                                    mobile-breakpoint="1"
                                                    show-select
                                                    v-model="item.child_info_done"
                                                    @input="ProgressFFF(item.child_info_done, item.id);"
                                                >

                                                    <!-- Check Box -->
                                                    <!-- <template v-slot:item.is_done="props">
                                                        <v-checkbox
                                                            @change="ProgressFFF(item.child_info_done, props, item.id);"
                                                            v-model="props.item.is_done"
                                                        ></v-checkbox>
                                                    </template> -->

                                                    <!-- Item Name -->
                                                    <template v-slot:item.item="props">
                                                        <!-- <strong>{{ props.item.item }}</strong> -->
                                                        <v-edit-dialog
                                                            :return-value.sync="props.item.item"
                                                            large
                                                            persistent
                                                            @save="save(props)"
                                                            @cancel="cancel"
                                                            @open="open"
                                                            @close="close"
                                                        >
                                                            <strong>{{ props.item.item }}</strong>
                                                            <template v-slot:input>
                                                                <div class="mt-4 text-h6">
                                                                    Update Item
                                                                </div>
                                                                <v-text-field
                                                                    v-model="props.item.item"
                                                                    label="Edit"
                                                                    single-line
                                                                    counter
                                                                    autofocus
                                                                ></v-text-field>
                                                            </template>

                                                        </v-edit-dialog>
                                                    </template>
                                                    
                                                    <!-- Item Quantity -->
                                                    <template v-slot:item.quantity="props">
                                                        <v-edit-dialog
                                                            :return-value.sync="props.item.quantity"
                                                            large
                                                            persistent
                                                            @save="save(props)"
                                                            @cancel="cancel"
                                                            @open="open"
                                                            @close="close"
                                                        >
                                                            <small style="cursor:pointer;" v-if="props.item.quantity == null">add a quantity</small>
                                                            <strong v-else>{{ props.item.quantity }}</strong>

                                                            <template v-slot:input>
                                                                <div class="mt-4 text-h6">
                                                                    Update Quantity
                                                                </div>
                                                                <v-text-field
                                                                    v-model="props.item.quantity"
                                                                    label="Edit"
                                                                    single-line
                                                                    counter
                                                                    autofocus
                                                                    type="number"
                                                                ></v-text-field>
                                                            </template>

                                                        </v-edit-dialog>
                                                    </template>

                                                    <!-- Color -->
                                                    <template v-slot:item.color="props">
                                                        <v-edit-dialog
                                                            :return-value.sync="props.item.color"
                                                            large
                                                            persistent
                                                            @save="save(props)"
                                                            @cancel="cancel"
                                                            @open="open"
                                                            @close="close"
                                                        >
                                                        <small style="cursor:pointer;" v-if="props.item.color == null">add a color</small>
                                                        <label v-else>{{props.item.color}}</label>
                                                        <template v-slot:input>
                                                            <div class="mt-4 text-h6">
                                                                Update Colour
                                                            </div>
                                                            <v-text-field
                                                                v-model="props.item.color"
                                                                label="Edit"
                                                                single-line
                                                                counter
                                                                autofocus
                                                            ></v-text-field>
                                                        </template>
                                                        </v-edit-dialog>
                                                    </template>

                                                    <!-- Supplier -->
                                                    <template v-slot:item.getsupplier="props">
                                                        <v-edit-dialog
                                                            :return-value.sync="props.item.supplier_id"
                                                            large
                                                            persistent
                                                            @save="save(props)"
                                                            @cancel="cancel"
                                                            @open="open"
                                                            @close="close"
                                                        >
                                                        <small style="cursor:pointer;" v-if="props.item.supplier_id === null">add a supplier</small>
                                                        <label v-else>{{props.item.supplier_id}}</label>
                                                        <template v-slot:input>
                                                            <div class="mt-4 text-h6">
                                                                Update Supplier
                                                            </div>
                                                            <!-- <v-autocomplete
                                                                v-model="props.item.getsupplier"
                                                                item-text="company_name"
                                                                item-value="user_id"
                                                                :items="getsupplierdesigntracker"
                                                            ></v-autocomplete> -->
                                                            <v-text-field
                                                                v-model="props.item.supplier_id"
                                                                label="Edit"
                                                                single-line
                                                                counter
                                                                autofocus
                                                            ></v-text-field>
                                                        </template>
                                                        </v-edit-dialog>
                                                    </template>

                                                    <!-- Product Code -->
                                                    <template v-slot:item.product_code="props">
                                                        <v-edit-dialog
                                                            :return-value.sync="props.item.product_code"
                                                            large
                                                            persistent
                                                            @save="save(props)"
                                                            @cancel="cancel"
                                                            @open="open"
                                                            @close="close"
                                                        >
                                                        <small style="cursor:pointer;" v-if="props.item.product_code == null">add a product code</small>
                                                        <label v-else>{{props.item.product_code}}</label>
                                                        <template v-slot:input>
                                                            <div class="mt-4 text-h6">
                                                                Update Product Code
                                                            </div>
                                                            <v-text-field
                                                                v-model="props.item.product_code"
                                                                label="Edit"
                                                                single-line
                                                                counter
                                                                autofocus
                                                            ></v-text-field>
                                                        </template>
                                                        </v-edit-dialog>
                                                    </template>

                                                    <!-- Link to Product -->
                                                    <template v-slot:item.link_to_product="props">
                                                        <v-edit-dialog
                                                            :return-value.sync="props.item.link_to_product"
                                                            large
                                                            persistent
                                                            @save="save(props)"
                                                            @cancel="cancel"
                                                            @open="open"
                                                            @close="close"
                                                        >
                                                        <small style="cursor:pointer;" v-if="props.item.link_to_product == null">add a link to product</small>
                                                        <label v-else>{{props.item.link_to_product}}</label>
                                                        <template v-slot:input>
                                                            <div class="mt-4 text-h6">
                                                                Update Link To Product
                                                            </div>
                                                            <v-text-field
                                                                v-model="props.item.link_to_product"
                                                                label="Edit"
                                                                single-line
                                                                counter
                                                                autofocus
                                                            ></v-text-field>
                                                        </template>
                                                        </v-edit-dialog>
                                                    </template>

                                                    <!-- Notes -->
                                                    <template v-slot:item.notes="props">
                                                        <v-edit-dialog
                                                            :return-value.sync="props.item.notes"
                                                            large
                                                            persistent
                                                            @save="save(props)"
                                                            @cancel="cancel"
                                                            @open="open"
                                                            @close="close"
                                                        >
                                                        <small style="cursor:pointer;" v-if="props.item.notes == null">add a note</small>
                                                        <label v-else>{{props.item.notes}}</label>
                                                        <template v-slot:input>
                                                            <div class="mt-4 text-h6">
                                                                Update Notes
                                                            </div>
                                                            <v-text-field
                                                                v-model="props.item.notes"
                                                                label="Edit"
                                                                single-line
                                                                counter
                                                                autofocus
                                                            ></v-text-field>
                                                        </template>
                                                        </v-edit-dialog>
                                                    </template>

                                                    <!-- Budget -->
                                                    <template v-slot:item.budget="props">
                                                        <div v-if="role == 1 || role == 2">
                                                            <v-edit-dialog
                                                                :return-value.sync="props.item.budget"
                                                                large
                                                                persistent
                                                                @save="save(props)"
                                                                @cancel="cancel"
                                                                @open="open"
                                                                @close="close"
                                                            >
                                                            <small style="cursor:pointer;" v-if="props.item.budget == null">add a budget</small>
                                                            <label v-else>{{props.item.budget}}</label>
                                                            <template v-slot:input>
                                                                <div class="mt-4 text-h6">
                                                                    Update Budget
                                                                </div>
                                                                <v-text-field
                                                                    v-model="props.item.budget"
                                                                    label="Edit"
                                                                    single-line
                                                                    counter
                                                                    autofocus
                                                                    type="number"
                                                                ></v-text-field>
                                                            </template>
                                                            </v-edit-dialog>
                                                        </div>
                                                    </template>

                                                    <!-- Actual -->
                                                    <template v-slot:item.actual="props">
                                                        <div v-if="role == 1 || role == 2">
                                                            <v-edit-dialog
                                                                :return-value.sync="props.item.actual"
                                                                large
                                                                persistent
                                                                @save="save(props)"
                                                                @cancel="cancel"
                                                                @open="open"
                                                                @close="close"
                                                            >
                                                            <small style="cursor:pointer;" v-if="props.item.actual == null">add an actual</small>
                                                            <label v-else>{{props.item.actual}}</label>
                                                            <template v-slot:input>
                                                                <div class="mt-4 text-h6">
                                                                    Update Actual
                                                                </div>
                                                                <v-text-field
                                                                    v-model="props.item.actual"
                                                                    label="Edit"
                                                                    single-line
                                                                    counter
                                                                    autofocus
                                                                    type="number"
                                                                ></v-text-field>
                                                            </template>
                                                            </v-edit-dialog>
                                                        </div>
                                                    </template>

                                                </v-data-table>

                                            </v-list-group>
                                        </v-list>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-container>
                </v-tabs-items>
                <v-tabs-items v-if="tab === 'tab-2'">
                    <v-container>
                        <v-card>
                            <v-card-title>Appliances</v-card-title>
                            <v-card-text>
                                <v-row
                                    no-gutters
                                >
                                    <v-col
                                        cols="12"
                                    >
                                        <v-list>
                                            <v-list-group
                                                v-for="item in getAppliances"
                                                :key="item.id"
                                                no-action
                                            >
                                                <template v-slot:activator>
                                                    <v-list-item-content>
                                                        <v-list-item-title><strong>{{item.room}}</strong></v-list-item-title>
                                                        <v-list-item-subtitle>
                                                            <label v-if="item.child_info_done.length != item.child_info.length">{{ item.child_info.length - item.child_info_done.length }} tasks remaining</label>
                                                            <label v-else>All done! Good job</label>
                                                        </v-list-item-subtitle>
                                                        <v-progress-linear
                                                            color="#00ae3b"
                                                            height="12"
                                                            :value="Math.ceil(item.child_info_done.length * (100 / item.child_info.length))"
                                                        >
                                                        <!-- v-if="Math.ceil(item.child_info_done.length * (100 / item.child_info.length))<100" -->
                                                        <!-- {{ Math.ceil(item.child_info_done.length * (100 / item.child_info.length)) }} % -->
                                                        </v-progress-linear>
                                                        <!-- <v-progress-linear
                                                            color="blue-grey"
                                                            height="12"
                                                            :value="Math.ceil(item.child_info_done.length * (100 / item.child_info.length))"
                                                            v-else
                                                        >100 %
                                                        </v-progress-linear> -->
                                                    </v-list-item-content>
                                                </template>

                                                <v-data-table
                                                    :headers="role == 1 || role == 2 ? headersfff : headersfffclient"
                                                    :items="item.child_info"
                                                    :single-select="false"
                                                    show-select
                                                    v-model="item.child_info_done"
                                                    class="elevation-2"
                                                    style="width: 100%;"
                                                    :hide-default-footer="true"
                                                    disable-pagination
                                                    mobile-breakpoint="1"
                                                    @input="ProgressAppliances(item.child_info_done, item.id);"
                                                >

                                                    <!-- Item Name -->
                                                    <template v-slot:item.item="props">
                                                        <div><strong>{{ props.item.item }}</strong></div>
                                                    </template>
                                                    <template v-slot:item.quantity="props">
                                                        <v-edit-dialog
                                                            :return-value.sync="props.item.quantity"
                                                            large
                                                            persistent
                                                            @save="saveapp(props)"
                                                            @cancel="cancel"
                                                            @open="open"
                                                            @close="close"
                                                        >
                                                        <!-- <div>{{ props.item.quantity }}</div> -->
                                                        <small style="cursor:pointer;" v-if="props.item.quantity == null">add quantity</small>
                                                        <label v-else>{{props.item.quantity}}</label>
                                                        <template v-slot:input>
                                                            <div class="mt-4 text-h6">
                                                                Update Quantity
                                                            </div>
                                                            <v-text-field
                                                                v-model="props.item.quantity"
                                                                label="Edit"
                                                                single-line
                                                                counter
                                                                autofocus
                                                            ></v-text-field>
                                                        </template>
                                                        </v-edit-dialog>
                                                    </template>
                                                    <template v-slot:item.color="props">
                                                        <v-edit-dialog
                                                            :return-value.sync="props.item.color"
                                                            large
                                                            persistent
                                                            @save="saveapp(props)"
                                                            @cancel="cancel"
                                                            @open="open"
                                                            @close="close"
                                                        >
                                                        <!-- <div>{{ props.item.color }}</div> -->
                                                        <small style="cursor:pointer;" v-if="props.item.color == null">add a colour</small>
                                                        <label v-else>{{props.item.color}}</label>
                                                        <template v-slot:input>
                                                            <div class="mt-4 text-h6">
                                                                Update Colour
                                                            </div>
                                                            <v-text-field
                                                                v-model="props.item.color"
                                                                label="Edit"
                                                                single-line
                                                                counter
                                                                autofocus
                                                            ></v-text-field>
                                                        </template>
                                                        </v-edit-dialog>
                                                    </template>
                                                    <template v-slot:item.getsupplier="props">
                                                        <v-edit-dialog
                                                            :return-value.sync="props.item.getsupplier"
                                                            large
                                                            persistent
                                                            @save="saveapp(props)"
                                                            @cancel="cancel"
                                                            @open="open"
                                                            @close="close"
                                                        >
                                                        <small style="cursor:pointer;" v-if="props.item.getsupplier == null">add a supplier</small>
                                                        <label v-else>{{props.item.getsupplier.company_name}}</label>
                                                        <template v-slot:input>
                                                            <div class="mt-4 text-h6">
                                                                Update Supplier
                                                            </div>
                                                            <v-autocomplete
                                                                v-model="props.item.getsupplier"
                                                                item-text="company_name"
                                                                item-value="user_id"
                                                                :items="getsupplierdesigntracker"
                                                            ></v-autocomplete>
                                                        </template>
                                                        </v-edit-dialog>
                                                    </template>
                                                    <template v-slot:item.product_code="props">
                                                        <v-edit-dialog
                                                            :return-value.sync="props.item.product_code"
                                                            large
                                                            persistent
                                                            @save="saveapp(props)"
                                                            @cancel="cancel"
                                                            @open="open"
                                                            @close="close"
                                                        >
                                                        <!-- <div>{{ props.item.product_code }}</div> -->
                                                        <small style="cursor:pointer;" v-if="props.item.product_code == null">add a colour</small>
                                                        <label v-else>{{props.item.product_code}}</label>
                                                        <template v-slot:input>
                                                            <div class="mt-4 text-h6">
                                                                Update Product Code
                                                            </div>
                                                            <v-text-field
                                                                v-model="props.item.product_code"
                                                                label="Edit"
                                                                single-line
                                                                counter
                                                                autofocus
                                                            ></v-text-field>
                                                        </template>
                                                        </v-edit-dialog>
                                                        <!-- <div>{{ props.item.product_code }}</div> -->
                                                    </template>
                                                    <template v-slot:item.link_to_product="props">
                                                        <v-edit-dialog
                                                            :return-value.sync="props.item.link_to_product"
                                                            large
                                                            persistent
                                                            @save="saveapp(props)"
                                                            @cancel="cancel"
                                                            @open="open"
                                                            @close="close"
                                                        >
                                                        <!-- <div>{{ props.item.link_to_product }}</div> -->
                                                        <small style="cursor:pointer;" v-if="props.item.link_to_product == null">add link to product</small>
                                                        <label v-else>{{props.item.link_to_product}}</label>
                                                        <template v-slot:input>
                                                            <div class="mt-4 text-h6">
                                                                Update Link To Product
                                                            </div>
                                                            <v-text-field
                                                                v-model="props.item.link_to_product"
                                                                label="Edit"
                                                                single-line
                                                                counter
                                                                autofocus
                                                            ></v-text-field>
                                                        </template>
                                                        </v-edit-dialog>
                                                        <!-- <div>{{ props.item.link_to_product }}</div> -->
                                                    </template>
                                                    <template v-slot:item.notes="props">
                                                        <v-edit-dialog
                                                            :return-value.sync="props.item.notes"
                                                            large
                                                            persistent
                                                            @save="saveapp(props)"
                                                            @cancel="cancel"
                                                            @open="open"
                                                            @close="close"
                                                        >
                                                        <small style="cursor:pointer;" v-if="props.item.notes == null">add a note</small>
                                                        <label v-else>{{props.item.notes}}</label>
                                                        <!-- <div>{{ props.item.notes }}</div> -->
                                                        <template v-slot:input>
                                                            <div class="mt-4 text-h6">
                                                                Update Notes
                                                            </div>
                                                            <v-text-field
                                                                v-model="props.item.notes"
                                                                label="Edit"
                                                                single-line
                                                                counter
                                                                autofocus
                                                            ></v-text-field>
                                                        </template>
                                                        </v-edit-dialog>
                                                    </template>
                                                    <template v-slot:item.budget="props">
                                                        <div v-if="role == 1 || role == 2">
                                                            <v-edit-dialog
                                                                :return-value.sync="props.item.budget"
                                                                large
                                                                persistent
                                                                @save="saveapp(props)"
                                                                @cancel="cancel"
                                                                @open="open"
                                                                @close="close"
                                                            >
                                                            <small style="cursor:pointer;" v-if="props.item.budget == null">add a budget</small>
                                                            <label v-else>€ {{props.item.budget | formatNumber}}</label>
                                                            <!-- <div>{{ props.item.budget }}</div> -->
                                                            <template v-slot:input>
                                                                <div class="mt-4 text-h6">
                                                                    Update Budget
                                                                </div>
                                                                <v-text-field
                                                                    v-model="props.item.budget"
                                                                    label="Edit"
                                                                    single-line
                                                                    counter
                                                                    autofocus
                                                                ></v-text-field>
                                                            </template>
                                                            </v-edit-dialog>
                                                        </div>
                                                    </template>
                                                    <template v-slot:item.actual="props">
                                                        <div v-if="role == 1 || role == 2">
                                                            <v-edit-dialog
                                                                :return-value.sync="props.item.actual"
                                                                large
                                                                persistent
                                                                @save="saveapp(props)"
                                                                @cancel="cancel"
                                                                @open="open"
                                                                @close="close"
                                                            >
                                                            <small style="cursor:pointer;" v-if="props.item.actual == null">add an actual</small>
                                                            <label v-else>€ {{props.item.actual | formatNumber}}</label>
                                                            <!-- <div>{{ props.item.actual }}</div> -->
                                                            <template v-slot:input>
                                                                <div class="mt-4 text-h6">
                                                                    Update Actual
                                                                </div>
                                                                <v-text-field
                                                                    v-model="props.item.actual"
                                                                    label="Edit"
                                                                    single-line
                                                                    counter
                                                                    autofocus
                                                                ></v-text-field>
                                                            </template>
                                                            </v-edit-dialog>
                                                        </div>
                                                    </template>
                                                </v-data-table>
                                            </v-list-group>
                                        </v-list>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-container>
                </v-tabs-items>
                <v-tabs-items v-if="tab === 'tab-3'">
                    <v-container>
                        <v-card>
                            <v-card-title>Flooring Calculations</v-card-title>
                            <v-card-text>
                                <v-row
                                    no-gutters
                                >
                                    <v-col
                                        cols="12"
                                    >
                                        <v-list>
                                            <v-list-group
                                                v-for="item in getFlooringCalculation"
                                                :key="item.id"
                                                no-action
                                            >
                                                <template v-slot:activator>
                                                    <v-list-item-content>
                                                        <v-list-item-title><strong>{{item.location}}</strong></v-list-item-title>
                                                        <v-list-item-subtitle>
                                                            <label v-if="item.child_info_done.length != item.child_info.length">{{ item.child_info.length - item.child_info_done.length }} tasks remaining</label>
                                                            <label v-else>All done! Good job</label>
                                                        </v-list-item-subtitle>
                                                        <v-progress-linear
                                                            color="#00ae3b"
                                                            height="12"
                                                            :value="Math.ceil(item.child_info_done.length * (100 / item.child_info.length))"
                                                        >
                                                        <!-- v-if="Math.ceil(item.child_info_done.length * (100 / item.child_info.length))<100" -->
                                                        <!-- {{ Math.ceil(item.child_info_done.length * (100 / item.child_info.length)) }} % -->
                                                        </v-progress-linear>
                                                        <!-- <v-progress-linear
                                                            color="blue-grey"
                                                            height="12"
                                                            :value="Math.ceil(item.child_info_done.length * (100 / item.child_info.length))"
                                                            v-else
                                                        >100 %
                                                        </v-progress-linear> -->
                                                    </v-list-item-content>
                                                </template>

                                                <v-data-table
                                                    :headers="calcHeaders"
                                                    class="elevation-2"
                                                    style="width: 100%;"
                                                    :hide-default-footer="true"
                                                    disable-pagination
                                                    mobile-breakpoint="1"
                                                    :items="item.child_info"
                                                    :single-select="false"
                                                    show-select
                                                    v-model="item.child_info_done"
                                                    @input="ProgressFlooring(item.child_info_done, item.id);"
                                                >
                                                    <template v-slot:item.location="props">
                                                        <strong>{{ props.item.location }}</strong>
                                                    </template>
                                                    <template v-slot:item.sqm="props">
                                                        <v-edit-dialog
                                                            :return-value.sync="props.item.sqm"
                                                            large
                                                            persistent
                                                            @save="savecalc(props)"
                                                            @cancel="cancel"
                                                            @open="open"
                                                            @close="close"
                                                        >
                                                        <!-- <div>{{ props.item.sqm }}</div> -->
                                                        <small style="cursor:pointer;" v-if="props.item.sqm == null">add an sqm</small>
                                                        <label v-else>{{props.item.sqm}}</label>
                                                        <template v-slot:input>
                                                            <div class="mt-4 text-h6">
                                                                Update Square Meter
                                                            </div>
                                                            <v-text-field
                                                                v-model="props.item.sqm"
                                                                label="Edit"
                                                                single-line
                                                                counter
                                                                autofocus
                                                            ></v-text-field>
                                                        </template>
                                                        </v-edit-dialog>
                                                    </template>
                                                    <template v-slot:item.floor_type="props">
                                                        <v-edit-dialog
                                                            :return-value.sync="props.item.floor_type"
                                                            large
                                                            persistent
                                                            @save="savecalc(props)"
                                                            @cancel="cancel"
                                                            @open="open"
                                                            @close="close"
                                                        >
                                                        <!-- <div>{{ props.item.floor_type }}</div> -->
                                                        <small style="cursor:pointer;" v-if="props.item.floor_type == null">add floor type</small>
                                                        <label v-else>{{props.item.floor_type}}</label>
                                                        <template v-slot:input>
                                                            <div class="mt-4 text-h6">
                                                                Update Floor Type
                                                            </div>
                                                            <v-text-field
                                                                v-model="props.item.floor_type"
                                                                label="Edit"
                                                                single-line
                                                                counter
                                                                autofocus
                                                            ></v-text-field>
                                                        </template>
                                                        </v-edit-dialog>
                                                    </template>
                                                    <template v-slot:item.price_per_sqm="props">
                                                        <v-edit-dialog
                                                            :return-value.sync="props.item.price_per_sqm"
                                                            large
                                                            persistent
                                                            @save="savecalc(props)"
                                                            @cancel="cancel"
                                                            @open="open"
                                                            @close="close"
                                                        >
                                                        <!-- <div>{{ props.item.price_per_sqm }}</div> -->
                                                        <small style="cursor:pointer;" v-if="props.item.price_per_sqm == null">add an price per sqm</small>
                                                        <label v-else>{{props.item.price_per_sqm}}</label>
                                                        <template v-slot:input>
                                                            <div class="mt-4 text-h6">
                                                                Update Price Per Square Meter
                                                            </div>
                                                            <v-text-field
                                                                v-model="props.item.price_per_sqm"
                                                                label="Edit"
                                                                single-line
                                                                counter
                                                                autofocus
                                                            ></v-text-field>
                                                        </template>
                                                        </v-edit-dialog>
                                                    </template>
                                                    <template v-slot:item.total="props">
                                                        <!-- <div>{{ props.item.total }}</div> -->
                                                        <v-edit-dialog
                                                            :return-value.sync="props.item.total"
                                                            large
                                                            persistent
                                                            @save="savecalc(props)"
                                                            @cancel="cancel"
                                                            @open="open"
                                                            @close="close"
                                                        >
                                                        <!-- <div>{{ props.item.price_per_sqm }}</div> -->
                                                        <small style="cursor:pointer;" v-if="props.item.total == null">add an price per sqm</small>
                                                        <label v-else>€ {{props.item.total | formatNumber}}</label>
                                                        <template v-slot:input>
                                                            <div class="mt-4 text-h6">
                                                                Update Total
                                                            </div>
                                                            <v-text-field
                                                                v-model="props.item.total"
                                                                label="Edit"
                                                                single-line
                                                                counter
                                                                autofocus
                                                            ></v-text-field>
                                                        </template>
                                                        </v-edit-dialog>
                                                    </template>
                                                    <template v-slot:item.getsupplier="props">
                                                        <v-edit-dialog
                                                            :return-value.sync="props.item.getsupplier"
                                                            large
                                                            persistent
                                                            @save="savecalc(props)"
                                                            @cancel="cancel"
                                                            @open="open"
                                                            @close="close"
                                                        >
                                                        <small style="cursor:pointer;" v-if="props.item.getsupplier == null">add a supplier</small>
                                                        <label v-else>{{props.item.getsupplier.company_name}}</label>
                                                        <template v-slot:input>
                                                            <div class="mt-4 text-h6">
                                                                Update Supplier
                                                            </div>
                                                            <v-autocomplete
                                                                v-model="props.item.getsupplier"
                                                                item-text="company_name"
                                                                item-value="user_id"
                                                                :items="getsupplierdesigntracker"
                                                            ></v-autocomplete>
                                                        </template>
                                                        </v-edit-dialog>
                                                        <!-- <div>{{ props.item.getsupplier }}</div> -->
                                                    </template>
                                                    <template v-slot:item.notes="props">
                                                        <v-edit-dialog
                                                            :return-value.sync="props.item.notes"
                                                            large
                                                            persistent
                                                            @save="savecalc(props)"
                                                            @cancel="cancel"
                                                            @open="open"
                                                            @close="close"
                                                        >
                                                        <!-- <div>{{ props.item.notes }}</div> -->
                                                        <small style="cursor:pointer;" v-if="props.item.notes == null">add an price per sqm</small>
                                                        <label v-else>{{props.item.notes}}</label>
                                                        <template v-slot:input>
                                                            <div class="mt-4 text-h6">
                                                                Update Notes
                                                            </div>
                                                            <v-text-field
                                                                v-model="props.item.notes"
                                                                label="Edit"
                                                                single-line
                                                                counter
                                                                autofocus
                                                            ></v-text-field>
                                                        </template>
                                                        </v-edit-dialog>
                                                    </template>
                                                </v-data-table>
                                            </v-list-group>
                                        </v-list>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-container>
                </v-tabs-items>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {
  },
  props: [
  ],
  data () {
    return {
      url: null,
      urlParams: 'empty',
      userId: null,
      tab: null,
      headersfff: [
        // {text: 'ID', value: 'index'},
        // {text: '', value: 'is_done'},
        {text: 'Item', value: 'item'},
        {text: 'Quantity', value: 'quantity'},
        {text: 'Colour', value: 'color'},
        {text: 'Supplier', value: 'getsupplier'},
        {text: 'Product Code', value: 'product_code'},
        {text: 'Link To Product', value: 'link_to_product'},
        {text: 'Notes', value: 'notes'},
        {text: 'Budget', value: 'budget'},
        {text: 'Actual', value: 'actual'},
      ],
      headersfffclient: [
        // {text: 'ID', value: 'index'},
        // {text: '', value: 'is_done'},
        {text: 'Item', value: 'item'},
        {text: 'Quantity', value: 'quantity'},
        {text: 'Colour', value: 'color'},
        {text: 'Supplier', value: 'getsupplier'},
        {text: 'Product Code', value: 'product_code'},
        {text: 'Link To Product', value: 'link_to_product'},
        {text: 'Notes', value: 'notes'},
      ],
      calcHeaders:[
        // {text: 'ID', value: 'index'},
        {text: 'Location', value: 'location'},
        {text: 'SQM', value: 'sqm'},
        {text: 'Floor Type', value: 'floor_type'},
        {text: 'Price Per SQM', value: 'price_per_sqm'},
        {text: 'Total', value: 'total'},
        {text: 'Supplier', value: 'getsupplier'},
        {text: 'Notes', value: 'notes'},
      ],
      toeditdata: null,
      role: null
    }
  },
  mounted () {
  },
  created () {
    if(localStorage.getItem("token") === null){
      this.$router.push({name: 'index'})
      return
    }
    if(localStorage.getItem('project_id') !== null){
      this.urlParams = localStorage.getItem('project_id')
    }
    this.userId = localStorage.getItem('id')
    this.$store.dispatch('setDrawer', true)
    this.url = process.env.VUE_APP_URL
    this.role = localStorage.getItem('role')
    this.fetchfff()
    this.fetchAppliances()
    this.fetchFlooringCalculation()
    this.getsupplier()
  },
  watch: {
    reloadFn(data){
      if(data){
        this.urlParams = localStorage.getItem('project_id')
        this.fetchAppliances()
        return this.fetchfff()
      }
    }
  },
  computed: {
    ...mapGetters({
      getfff:                   'getFFF',
      getAppliances:            'getAppliances',
      getFlooringCalculation:   'getFlooringCalculation',
      getchildFFFData:          'getchildFFFData',
      getchildApplianceData:    'getchildApplianceData',
      getchildCalcData:         'getchildCalcData',
      getsupplierdesigntracker: 'getsupplierdesigntracker'
    }),
    itemsWithIndex() {
      return this.getchildFFFData.map(
        (items, index) => ({
            ...items,
            index: index + 1
        }))
    },
    appliancesWithIndex() {
      return this.getchildApplianceData.map(
        (items, index) => ({
            ...items,
            index: index + 1
        }))
    },
    calcDataIndex(){
      return this.getchildCalcData.map(
        (items, index) => ({
            ...items,
            index: index + 1
        }))
    },
    reloadFn(){
      return this.$store.getters['getReload']
    },
  },
  methods: {
    async getsupplier(){
      await this.$axios.get('getSupplierList')
      .then(({data}) => {
        // console.log(data)
        this.$store.dispatch('setsupplierdesigntracker', data.data)
      })
    },
    showSnackBar(message){
      this.$store.commit("setMessage", 
        {show: true, message: message}, 
        {root: 1})
    },
    async fetchfff(){
      await this.$axios.get(`gFFF/${this.userId}/${this.urlParams}`)
      .then(({data}) => {
        if(data.data.length === 0 && this.role == 1){
          this.showSnackBar('Please Wait.. Generating Template')
          this.generatefff()
          return
        }
        this.$store.dispatch('setFFF', data.data)
        this.$store.dispatch('setReload', false)
      })
    },
    async generatefff(){
      await this.$axios.post(`genFFF/${this.userId}/${this.urlParams}`)
      .then(({data}) => {
        if(data.response){
          this.fetchfff()
        }
      })
    },
    // appliances
    async fetchAppliances(){
      await this.$axios.get(`getAppliances/${this.userId}/${this.urlParams}`)
      .then(({data}) => {
        if(data.data.length === 0){
          this.generateAppliances()
          return
        }
        this.$store.dispatch('setAppliances', data.data)
      })
    },
    async generateAppliances(){
      await this.$axios.post(`genAppliances/${this.userId}/${this.urlParams}`)
      .then(({data}) => {
        if(data.response){
          this.fetchAppliances()
        }
      })
    },
    async fetchFlooringCalculation(){
      await this.$axios.get(`getFlooringCalculation/${this.userId}/${this.urlParams}`)
      .then(({data}) => {
        if(data.data.length === 0){
          this.generateFlooringCalculation()
          return
        }
        this.$store.dispatch('setFlooringCalculation', data.data)
      })
    },
    async generateFlooringCalculation(){
    //   console.log('meow')
      await this.$axios.post(`genFlooringCalculation/${this.userId}/${this.urlParams}`)
      .then(({data}) => {
        if(data.response){
          this.fetchFlooringCalculation()
        }
      })
    },
    // searchfff
    async searchfff(d){
        console.log(d);
        // this.$store.dispatch('setchildFFFData', d);
    },
    async save(datatp){
      await this.$axios.patch(`updateFFF`, {data: datatp.item})
      .then(({data}) => {
        if(data.response){
        //   this.fetchfff()
          this.searchfff(this.toeditdata)
        }
      })
    },
    async saveapp(datap){
      await this.$axios.patch(`updateApp`, datap.item)
      .then(({data}) => {
        if(data.response){
        //   this.fetchAppliances()
          this.searchAppliances(this.toeditdata)
        }
      })
    },
    async savecalc(datap){
      await this.$axios.patch('updateCalc', datap.item)
      .then(({data}) => {
        if(data.response){
        //   this.fetchFlooringCalculation()
          this.searchCalc(this.toeditdata)
        }
      })
    },
    cancel () {},
    open () {},
    close () {},
    async searchAppliances(datatopass){
      this.toeditdata = datatopass
      await this.$axios.post(`gApplianceDet/${this.urlParams}`, datatopass)
      .then(({data}) => {
        this.$store.dispatch('setchildApplianceData', data.data)
      })
    },
    async searchCalc(datatopass){
      this.toeditdata = datatopass
      await this.$axios.post(`gCalcDet/${this.urlParams}`, datatopass)
      .then(({data}) => {
        if(data.response){
          this.$store.dispatch('setchildCalcData', data.data)
        }
      })
    },

    ProgressFFF(selected_item, parent_id){
        
      const payload = {
          data: selected_item,
          parent_id: parent_id
      };

      this.$axios.post('/update/done/fff', payload)
      .then(({data}) => {
          console.log(data);
      });

    },

    ProgressAppliances(selected_item, parent_id){

      const payload = {
          data: selected_item,
          parent_id: parent_id
      };

      this.$axios.post('/update/done/appliance', payload)
      .then(({data}) => {
          console.log(data);
      });

    },

    ProgressFlooring(selected_item, parent_id){

      const payload = {
          data: selected_item,
          parent_id: parent_id
      };

      this.$axios.post('/update/done/flooring', payload)
      .then(({data}) => {
          console.log(data);
      });

    }

  },
}
</script>

<style scoped lang="scss">

</style>