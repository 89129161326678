<template>
    <div style="background-color: black;">
        <v-tour name="tourGuide" :steps="steps">
            <template slot-scope="tour">
                <transition name="fade">
                    <v-step
                        v-if="tour.steps[tour.currentStep]"
                        :key="tour.currentStep"
                        :is-first="tour.isFirst"
                        :is-last="tour.isLast"
                        :labels="tour.labels"
                        :next-step="tour.nextStep"
                        :previous-step="tour.previousStep"
                        :skip="tour.skip"
                        :step="tour.steps[tour.currentStep]"
                        :stop="tour.stop"
                        :highlight="tour.highlight">
                        <template>
                            <div slot="content">
                                <div v-if="!tour.steps[tour.currentStep].is_decision">
                                    <h4>{{ tour.steps[tour.currentStep].title }}</h4>
                                    <hr/>
                                    <p style="text-align: justify;padding:10px;" >{{ tour.steps[tour.currentStep].content }}</p>
                                </div>
                                <div v-else>
                                    <h4>{{ tour.steps[tour.currentStep].title }}</h4>
                                    <hr/>
                                    <p style="text-align: justify;padding:10px;" >
                                        Continue filling out? Yes or No? <br/>
                                        If <strong>Yes</strong>, leave the tour to resume once done. <br/>
                                        If <strong>No</strong>, continue with the tour.
                                    </p>
                                </div>
                            </div>
                            <div slot="actions">
                                <hr/>
                                <v-row justify="center" class="mt-3 mb-2 px-2" v-if="!tour.steps[tour.currentStep].is_decision">
                                    <v-btn text small @click="tour.skip" class="white--text">Do not show</v-btn>
                                    <v-spacer></v-spacer>
                                    <v-btn text small @click="tour.previousStep" v-if="!tour.isFirst" class="white--text">Previous</v-btn>
                                    <v-btn text small @click="tour.nextStep" v-if="!tour.isLast" class="white--text">Next</v-btn>
                                    <v-btn text small @click="tour.stop" class="white--text" v-if="tour.isLast">Done</v-btn>
                                </v-row>
                                <v-row justify="center" class="mt-3 mb-2 px-2" v-else>
                                    <v-spacer></v-spacer>
                                    <v-btn text small @click="tour.stop" class="white--text">Yes</v-btn>
                                    <v-btn text small @click="tour.nextStep" class="white--text">No</v-btn>
                                </v-row>
                            </div>
                        </template>
                    </v-step>
                </transition>
            </template>
        </v-tour>
    </div>
</template>

<script>
import { tour_guide } from '../tour.js'

export default {
    data: () => ({
        tour_guide_steps: Object.freeze([])
    }),
    computed: {
        steps() {
            return this.tour_guide_steps
        }
    },
    mounted() {
        
    },
    methods: {
        start_tour() {
            setTimeout(() => {                               
                this.$tours.tourGuide.start()
            }, 500);            
        },
        set_tour_steps(page) {
            this.$tours.tourGuide.stop()
            this.tour_guide_steps = [...tour_guide()[page]()]
        }
    }
}
</script>
<style scoped>
.v-step {
    max-width: 450px !important;
}
</style>