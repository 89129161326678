<template>
  <v-container>
    <v-card
      class="mx-auto my-12"
    >
      <v-card-title style="background: #e3e3e4 !important;"><small><span style="position: relative; top: 5px;" class="material-icons-outlined mr-1">insert_drive_file</span>File Manager</small></v-card-title>
      <v-row
        class="ml-3"
        no-gutters
      >
        <div id="addDirectory">
        <v-btn
          v-if="!addDir"
          dark
          color="#062a82"
          class="mt-5 mb-5 ml-3"
          @click="addDir = true"
        >
          <span class="material-icons-outlined mr-1">
            add
          </span> Add Directory
        </v-btn>
        </div>
        <v-col
          v-if="addDir"
          class="d-flex mt-5"
          cols="12"
          sm="12"
          md="4"
        >
          <v-text-field
            name="input-7-1"
            label="Directory Name"
            v-model="directoryName"
            placeholder="Directory Name"
          ></v-text-field>
          <v-btn
            text
            @click="saveNewDirectory"
          >
            <span class="material-icons-outlined">
              save
            </span>
          </v-btn>
          <v-btn
            text
            @click="addDir = false"
          >
            <span class="material-icons-outlined">
              cancel
            </span>
          </v-btn>
        </v-col>
      </v-row>
      <v-card-text>
        <div id="directoryTable"></div>
        <v-row
          class="justify-center"
          no-gutters
        >
          <v-text-field
            lg="12"
            md="12"
            sm="12"
            v-model="searchFileManager"
            label="Search In File Manager"
            hide-details
          ></v-text-field>
          <v-col
            class="d-flex mt-5"
            cols="12"
            sm="12"
          >
            <v-data-table
              :headers="headers"
              :items="directory"
              class="elevation-1"
              style="width: 100%;"
              :search="searchFileManager"
              mobile-breakpoint="0">
              <template v-slot:item.name="props">
                <div id="editItem"></div>
                <v-edit-dialog
                  style="cursor: pointer;"
                  :return-value.sync="props.item.name"
                  large
                  @save="editName(props)"
                  @cancel="cancel"
                  @open="open"
                  @close="close"
                >
                  <!-- <div>€ {{ props.item.name | formatNumber }}</div> -->
                  <div>{{ props.item.name }}</div>
                  <template v-slot:input>
                    <div class="mt-4 text-h6">
                      Update Name
                    </div>
                    <v-text-field
                      v-model="props.item.name"
                      label="Edit"
                      single-line
                      counter
                      autofocus
                    ></v-text-field>
                  </template>
                </v-edit-dialog>
              </template>
              <template v-slot:item.contains="props">
                <div style="cursor: pointer;" @click="showInfo(props)">
                  <span class="material-icons-outlined">
                    file_present
                  </span>
                  {{props.item.contains}}
                </div>
              </template>
              <template v-slot:item.total_size="props">
                <div style="cursor: pointer;" @click="showInfo(props)">
                  <span class="material-icons-outlined">
                    monitor_weight
                  </span>
                  {{parseFloat(props.item.total_size).toFixed(2) | bytes}}
                </div>
              </template>
              <template v-slot:item.updated_at="props">
                <div style="cursor: pointer;" @click="showInfo(props)">
                  {{props.item.updated_at}}
                </div>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
      <v-row justify="center">
        <v-dialog
          v-model="dialogImage"
          hide-overlay
          transition="dialog-bottom-transition"
        >
          <v-card>
            <v-toolbar
              dark
              color="#062a82"
            >
              <v-btn
                icon
                dark
                @click="dialogImage = false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>Settings</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-items>
                <v-btn
                  dark
                  text
                  @click="dialogDelete = true"
                >
                  <span class="material-icons-outlined">
                    delete
                  </span>
                </v-btn>
              </v-toolbar-items>
            </v-toolbar>
            <v-list
              three-line
              subheader
            >
              <v-subheader>File Content</v-subheader>
              <div v-if="loading" align="center">
                <img src="https://acegif.com/wp-content/uploads/loading-23.gif" width="500" height="auto"/>
              </div>
              <v-list-item v-if="!loading">
                <v-list-item-content>
                  <v-list-item-subtitle>
                    <v-file-input
                      style="cursor: pointer;"
                      @change="upload"
                      label="Click here to upload documents"
                      v-model="file"
                      multiple
                    ></v-file-input>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="!loading">
                <v-list-item-content>
                  <v-row
                    no-gutters
                  >
                    <v-col
                      v-for="(image, index) in images" :key="index"
                      class="fluid mt-5"
                      cols="4"
                      sm="4"
                      md="4"
                    >
                      <v-card v-if="image.extension_file === 'pdf'" elevation="1">
                        <v-card-title
                          class="justify-center"
                          @click="viewPDF(image.path)"
                        >
                          <v-img src="https://upload.wikimedia.org/wikipedia/commons/8/87/PDF_file_icon.svg" contain max-width="125"/>
                        </v-card-title>
                        <v-card-text class="justify-center">
                          <strong @click="viewPDF(image.path)">{{image.name}}.{{image.extension_file}}</strong>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer/>
                          <v-btn 
                            icon
                            @click="share(image)"
                          >
                            <v-icon>mdi-download</v-icon>
                          </v-btn>
                          <v-btn
                            icon
                            @click="deleteimg(image)"
                          >
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                      <v-card
                        v-if="image.extension_file === 'docx'"
                        elevation="1"
                      >
                        <v-card-title
                          class="justify-center"
                          @click="opendocs(image.path)"
                        >
                          <v-img src="https://upload.wikimedia.org/wikipedia/commons/6/66/Google_Docs_2020_Logo.svg" contain max-width="125"/>
                        </v-card-title>
                        <v-card-text
                          class="justify-center"
                        >
                          <label @click="opendocs(image.path)">{{image.name}}.{{image.extension_file}}</label>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer/>
                          <v-btn 
                            icon
                            @click="share(image)"
                          >
                            <v-icon>mdi-download</v-icon>
                          </v-btn>
                          <v-btn
                            icon
                            @click="deleteimg(image)"
                          >
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                      <v-card
                        v-if="image.extension_file === 'xlsx'"
                        elevation="1"
                      >
                        <v-card-title
                          class="justify-center"
                          @click="opendocs(image.path)"
                        >
                          <v-img src="https://upload.wikimedia.org/wikipedia/commons/3/34/Microsoft_Office_Excel_%282019%E2%80%93present%29.svg" contain max-width="125"/>
                        </v-card-title>
                        <v-card-text
                          class="justify-center"
                        >
                          <label @click="opendocs(image.path)">{{image.name}}.{{image.extension_file}}</label>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer/>
                          <v-btn 
                            icon
                            @click="share(image)"
                          >
                            <v-icon>mdi-download</v-icon>
                          </v-btn>
                          <v-btn
                            icon
                            @click="deleteimg(image)"
                          >
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                      <v-card
                        v-if="image.extension_file === 'jpg' || image.extension_file === 'png' || image.extension_file === 'webp'"
                        elevation="1"
                      >
                        <v-card-title
                          class="justify-center"
                        >
                          <v-img
                            :lazy-src="`${url}${image.path}`"
                            max-height="500"
                            max-width="300"
                            @click="showImage(image.path)"
                            style="cursor: pointer;display:block;margin-left:auto;margin-right:auto;"
                            :src="`${url}${image.path}`"
                          >
                          </v-img>
                        </v-card-title>
                        <v-card-actions>
                          <v-spacer/>
                          <v-btn 
                            icon
                            @click="share(image)"
                          >
                            <v-icon>mdi-download</v-icon>
                          </v-btn>
                          <v-btn
                            icon
                            @click="deleteimg(image)"
                          >
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                      <v-card
                        elevation="1"
                        v-if="image.extension_file === 'mp4' || image.extension_file === 'qt'"
                      >
                        <v-card-title
                          class="justify-center"
                          @click="opendocs(image.path)"
                        >
                          <v-img src="https://www.svgrepo.com/show/131006/mp4.svg" contain max-width="125"/>
                        </v-card-title>
                        <v-card-text
                          class="justify-center"
                        >
                          <label @click="openVideo(image.path, image.extension_file)">{{image.name}}.{{image.extension_file}}</label>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer/>
                          <v-btn 
                            icon
                            @click="share(image)"
                          >
                            <v-icon>mdi-download</v-icon>
                          </v-btn>
                          <v-btn
                            icon
                            @click="deleteimg(image)"
                          >
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                      <v-card
                        elevation="1"
                        v-if="image.extension_file === 'dwg'"
                      >
                        <v-card-title
                          class="justify-center"
                        >
                          <label>{{image.name}}.{{image.extension_file}}<br/>No preview for this file extension</label><br/>
                        </v-card-title>
                        <v-card-actions>
                          <v-spacer/>
                          <v-btn 
                            icon
                            @click="share(image)"
                          >
                            <v-icon>mdi-download</v-icon>
                          </v-btn>
                          <v-btn
                            icon
                            @click="deleteimg(image)"
                          >
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-dialog>
        <v-dialog
          v-model="dialogDelete"
          max-width="290"
        >
          <v-card>
            <v-card-title>
              <small>Are you sure delete this record?</small>
            </v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="green darken-1"
                text
                @click="dialogDelete = false"
              >
                <span class="material-icons-outlined">cancel</span>
              </v-btn>
              <v-btn
                color="green darken-1"
                text
                @click="deleteDirectory"
              >
                <span class="material-icons-outlined">delete</span>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- PDF -->
        <v-dialog
          v-model="pdfDialog"
        >
          <v-card>
            <v-toolbar
              dark
              color="#062a82"
            >
              <v-btn
                icon
                dark
                @click="pdfDialog = false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-spacer></v-spacer>
            </v-toolbar>
            <!-- <pdf :src="pdfPath"></pdf> -->
            <pdf
              v-for="i in 15"
              :key="i"
              :src="pdfPath"
              :progress="1"
              :page="i"
            ></pdf>
          </v-card>
        </v-dialog>
        <!-- END PDF -->
        <!-- Word -->
        <v-dialog
          v-model="isDocs"
        >
          <v-card>
            <v-toolbar
              dark
              color="#062a82"
            >
              <v-btn
                icon
                dark
                @click="isDocs = false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-spacer></v-spacer>
            </v-toolbar>
            <!-- <pdf :src="pdfPath"></pdf> -->
            <VueDocPreview :value="docsValue" type="office" />
          </v-card>
        </v-dialog>
        <!-- END Word -->1
        <!-- Video -->
        <v-dialog
          v-model="isVideo"
        >
          <v-card>
            <v-toolbar
              dark
              color="#062a82"
            >
              <v-btn
                icon
                dark
                @click="isVideo = false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-row
              justify="center"
              align="center"
              no-gutters
            >
              <v-col
                cols="12"
                sm="12"
              >
                <center>
                  <video-player
                    class="video-player-box justify-content-center"
                    ref="videoPlayer"
                    :options="playerOptions"
                    :playsinline="true"
                  />
                </center>
              </v-col>
            </v-row>
          </v-card>
        </v-dialog>
        <!-- END Video -->
        <!-- images -->
        <v-dialog
          v-model="dialogImageShow"
        >
          <v-card>
            <v-toolbar
              dark
              color="#062a82"
            >
              <v-btn
                icon
                dark
                @click="dialogImageShow = false;"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title></v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-img
              :cover="true"
              :lazy-src="`${url}${imgSrc}`"
              :src="`${url}${imgSrc}`"
            ></v-img>
          </v-card>
        </v-dialog>
        <!-- end images -->
      </v-row>
    </v-card>
    <v-tour name="myTour" :steps="steps"></v-tour>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import pdf from 'vue-pdf'
import VueDocPreview from 'vue-doc-preview'
import 'video.js/dist/video-js.css'
import { videoPlayer } from 'vue-video-player'
  export default {
    data: () => ({
      loading: false,
      headers: [
        {text: 'Name', value: 'name'},
        {text: 'Contains', value: 'contains'},
        {text: 'Total Size', value: 'total_size'},
        {text: 'Last Updated', value: 'updated_at'}
      ],
      pDir: {},
      url: null,
      dialogImage: false,
      dialogImageShow: false,
      dialogDelete: false,
      file: [],
      addDir: false,
      directoryName: '',
      isDocs: false,
      docsValue: null,
      pdfDialog: false,
      pdfPath: null,
      numPages: undefined,
      isVideo: false,
      playerOptions: {
        // videojs options
        muted: true,
        language: "en",
        autoplay: true,
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        sources: [
          {
            type: '',
            src: ''
          }
        ],
        // poster: "/static/images/author.jpg"
      },
      imgSrc: '',
      searchFileManager: '',
      steps: [
        {
          target: '#addDirectory',  // We're using document.querySelector() under the hood
          content: `You can add a new File Directory here.`
        },
        {
          target: '#editItem',
          content: 'Or edit a completed one here.'
        },
      ],
      urlParams: 'empty',
      accepts: ['jpeg', 'jpg', 'png', 'pps', 'ppt', 'pptx', 'xls', 'xlsm', 'xlsx', 'mov', 'mp4', 'doc', 'docx', 'pdf']
    }),
    components: {
      pdf,
      VueDocPreview,
      videoPlayer,
    },
    mounted () {
      if(localStorage.getItem('introFile') === null){
        this.$tours['myTour'].start()
        localStorage.setItem('introFile', true)
      }
    },
    created () {
      if(localStorage.getItem("token") === null){
        this.$router.push({name: 'index'})
        return
      }
      if(localStorage.getItem('project_id') !== null){
        this.urlParams = localStorage.getItem('project_id')
      }
      this.url = process.env.VUE_APP_URL
      this.getDir()
    },
    computed: {
      ...mapGetters({
        directory:          'getFileManager',
        images:             'getFileManagerImages'
      }),
      reloadFn(){
        return this.$store.getters['getReload']
      }
    },
    watch: {
      reloadFn(data){
        if(data){
          this.urlParams = localStorage.getItem('project_id')
          return this.getDir()
        }
      }
    },
    methods: {
      showSnackBar(message){
        this.$store.commit("setMessage", 
        {show: true, message: message}, 
        {root: 1})
      },
      async getDir(){
        await this.$axios.get(`getDir/${this.urlParams}`)
        .then(({data}) => {
          if(data.response){
            if(data.data.length === 0){
              this.showSnackBar("Generating Default Template... Please Wait...")
              this.generateDir()
              return
            }
            this.$store.dispatch('setReload', false)
            this.$store.dispatch('setFileManager', data.data)
            this.$store.dispatch('setDrawer', true)
          }else{
            localStorage.removeItem('token')
            localStorage.removeItem('id')
            localStorage.removeItem('name')
            localStorage.removeItem('username')
            localStorage.removeItem('email')
            localStorage.removeItem('phone')
            this.$router.push({ name: 'index' })
          }
        })
      },
      async generateDir(){
        await this.$axios.get(`generateDir/${this.urlParams}`)
        .then(({data}) => {
          if(data.response){
            this.showSnackBar(data.message)
            this.getDir()
          }
        })
      },
      async showInfo(data){
        const dirId = data.item.id
        this.pDir = data.item
        const toPass = {
          id: dirId
        }
        await this.$axios.get(`getImages/${this.urlParams}`, toPass)
        .then(({data}) => {
          if(data.response){
            this.$store.dispatch('setFileManagerImages', data.data)
            this.dialogImage = true
          }
        })
      },
      async upload(){
        if(this.file.length == 0) return this.showSnackBar('Please select a file to upload.');
        for(let item of this.file){
          let ext = item.name.split('.')[1];
          if(!this.accepts.includes(ext)) return this.showSnackBar('This file is not allowed to be uploaded ' + item.name);
        }
        let file_count = 0;
        this.loading = true
        const formData = new FormData()
        formData.append('dirId', this.pDir.id)
        for(let item of this.file){
          file_count+=1;
          formData.append('file_' + file_count, item);
        }
        formData.append('file_count', file_count);
        await this.$axios.post(`uploadFile/${this.urlParams}`, formData)
        .then(({data}) => {
          if(data.response){
            this.$store.dispatch('setFileManager', data.data)
            this.$store.dispatch('setFileManagerImages', data.images)
            this.file = []
            this.loading = false
          }
          else{
            this.loading = false;
            this.showSnackBar(data.message);
          }
        })
      },
      share(data){
        const link = data.path
        const url = this.url
        const out = url + link
        window.open(out)
        // const clipboardData =
        //   event.clipboardData ||
        //   window.clipboardData ||
        //   event.originalEvent?.clipboardData ||
        //   navigator.clipboard;
        // clipboardData.writeText(out);
        // this.showSnackBar("Link Copied!")
      },
      async deleteimg(data){
        await this.$axios.delete(`deleteImage/${data.id}/${this.pDir.id}/${this.urlParams}`)
        .then(({data}) => {
          if(data.response){
            this.$store.dispatch('setFileManager', data.data)
            this.$store.dispatch('setFileManagerImages', data.images)
          }
        })
      },
      async deleteDirectory(){
        await this.$axios.delete(`deleteDir/${this.pDir.id}/${this.urlParams}`)
        .then(({data}) => {
          // console.log(data)
          if(data.response){
            this.$store.dispatch('setFileManager', data.data)
            this.dialogImage = false
            this.dialogDelete = false
          }
        })
      },
      async saveNewDirectory(){
        const toPass = {
          directory: this.directoryName
        }
        await this.$axios.post(`addDirectory/${this.urlParams}`, toPass)
        .then(({data}) => {
          if(data.response){
            this.directoryName = ''
            this.addDir = false
            this.$store.dispatch('setFileManager', data.data)
          }
        })
      },
      viewPDF(data){
        // console.log(data)
        //this.pdfPath = this.url + data
        //this.pdfDialog = true
        window.open(this.url + data)
      },
      opendocs(data){
        // console.log(data)
        this.docsValue = this.url + '/' + data
        this.isDocs = true
      },
      openVideo(data, extFile){
        if(extFile === 'qt'){
          this.showSnackBar('File is not supported at the moment')
          return
        }
        this.isVideo = true
        this.playerOptions.sources[0].src = ''
        this.playerOptions.sources[0].type = ''
        // playerOptions: {
        //   // videojs options
        //   muted: true,
        //   language: "en",
        //   playbackRates: [0.7, 1.0, 1.5, 2.0],
        //   sources: [
        //     {
        //       type: '',
        //       src: ''
        //     }
        //   ],
        //   // poster: "/static/images/author.jpg"
        // },
        this.playerOptions.sources[0].src = this.url + data
        this.playerOptions.sources[0].type = "video/"+extFile
      },
      showImage(data){
        this.imgSrc = data
        this.dialogImageShow = true
      },
      open() {},
      cancel() {},
      close() {},
      async editName(data){
        await this.$axios.patch(`updateName/${this.urlParams}`, {data: data.item})
        .then(({data}) => {
          if(data.response){
            this.$store.dispatch('setFileManager', data.data)
          }
        })
      }
    }
  }
</script>