<template>
    <v-container>
        <h4 class="mb-5"><span style="position: relative; top: 5px;" class="material-icons-outlined mr-1">chat</span>Messages</h4>
        <v-tabs
            v-model="tab"
            class="pl-3 custom-tabs mt-3"
            lg="12"
            sm="12"
            cols="12"
            @change="SwitchTabs()"
        >
            <v-tab v-if="role == 1 || role == 2" href="#tab-1" class="custom-tab-1 mr-3">
                <div id="budgetTab">
                    <small>Team Chat</small>
                </div>
            </v-tab>
            
            <v-tab href="#tab-2" class="custom-tab-1 mr-3" v-if="role == 1 || role == 3">
                <div id="costTab">
                    <small v-if="role == 1">Client Chat</small>
                    <small v-else>Contractor Chat</small>
                </div>
            </v-tab>
        </v-tabs>
        <v-tabs-items v-if="tab === 'tab-1'">
            <v-row
                no-gutters
                class="mt-3"
            >
                <v-col
                    cols="12"
                    class="elevation-2"
                >
                <v-row
                    no-gutters
                >
                    <v-container
                    color="blue darken-5"
                    >
                        <h3
                            justify="center"
                        >
                        Team Chat
                        </h3>
                    </v-container>
                    <hr style="border:1px solid gray; width: 100%;"/>

                    <div id="chat1" class="overflow-y-auto" style="height:430px;width: 100%">
                        <v-container
                            style="height:110px;"
                            v-for="(convo, convoIndex) in conversation"
                            :key="convoIndex"
                        >
                            <v-card
                                v-if="convo.sender_id != user_id"
                                color="blue darken-2"
                                style="float: left;max-width:300px"
                                class="d-flex"
                            >
                                <v-card-text>
                                    <small class="white--text">{{ convo.getuser.name }}</small>
                                    <h4 class="white--text">{{convo.message}}</h4>
                                    <small class="white--text">{{convo.created_at | formatDateChat}}</small>
                                </v-card-text>
                                <v-btn
                                    v-if="convo.image != null"
                                    style="height:100px;width:100px;"
                                    @click="ExpandImage(convo.image[0].filepath)"
                                    
                                >
                                    <v-img
                                        :src="`${url}/${convo.image[0].filepath}`"
                                        style="height:100px;width:100px;"
                                        contain
                                    >
                                    </v-img>
                                </v-btn>
                            </v-card>
                            <v-card
                                v-else
                                color="blue darken-4"
                                style="float: right;"
                                class="d-flex"
                            >
                                <v-btn
                                    v-if="convo.image != null"
                                    style="height:100px;width:100px;"
                                    @click="ExpandImage(convo.image[0].filepath)"
                                >
                                    <v-img
                                        :src="`${url}/${convo.image[0].filepath}`"
                                        style="height:100px;width:100px;"
                                        contain
                                    >
                                    </v-img>
                                </v-btn>
                                <v-card-text>
                                    <small class="white--text">{{ convo.getuser.name }}</small>
                                    <h4 class="white--text">{{convo.message}}</h4>
                                    <small class="white--text">{{convo.created_at | formatDateChat}}</small>
                                </v-card-text>
                            </v-card>
                        </v-container>
                    </div>
                    
                    <v-container>
                        <v-row
                            class="pa-2"
                        >
                            <v-file-input
                                style="max-width:5%; padding-top:8px"
                                hide-input
                                truncate-length="15"
                                placeholder="Add Images"
                                prepend-icon="image"
                                v-model="upload_image"
                            ></v-file-input>
                            
                            <v-text-field
                                rounded
                                v-model="message"
                                placeholder="Reply Here..."
                                outlined
                                counter
                                maxlength="100"
                                append-icon="send"
                                @click:append="SendMessage(conversation_type)"
                                @keypress.enter="SendMessage(conversation_type)"
                                :disabled="project_id === ''"
                            ></v-text-field>
                        </v-row>
                        <v-row class="d-flex" style="margin-top:-35px" v-if="upload_image !== null">
                            <v-btn icon @click="upload_image = null"><span class="material-icons-outlined">delete</span></v-btn>
                            <h5 class="pt-2" style="font-weight:normal">image attachment</h5>
                        </v-row>
                    </v-container>
                </v-row>

                <!-- body content chat -->
                </v-col>
            </v-row>
        </v-tabs-items>
        
        <v-tabs-items v-else-if="tab === 'tab-2'">

            <v-row
            no-gutters
            class="mt-3"
            >
                <v-col
                cols="12"
                class="elevation-2"
                >
                <v-row
                no-gutters
                >
                    <v-container
                    color="blue darken-5"
                    >
                        <h3
                        justify="center"
                        >
                        Client Chat
                        </h3>
                    </v-container>
                    <hr style="border:1px solid gray; width: 100%;"/>

                    <div id="chat2" class="overflow-y-auto" style="height:430px;width: 100%">
                        <v-container
                            style="height:110px"
                            v-for="(convo, convoIndex) in conversation"
                            :key="convoIndex"
                        >
                            <v-card
                                v-if="convo.sender_id != user_id"
                                color="blue darken-2"
                                style="float: left;"
                                class="d-flex"
                            >
                                <v-card-text>
                                    <small class="white--text">{{ convo.getuser.name }}</small>
                                    <h4 class="white--text">{{convo.message}}</h4>
                                    <small class="white--text">{{convo.created_at | formatDateChat}}</small>
                                </v-card-text>
                                <v-btn
                                    v-if="convo.image != null"
                                    style="height:100px;width:100px;"
                                    @click="ExpandImage(convo.image[0].filepath)"
                                    
                                >
                                    <v-img
                                        :src="`${url}/${convo.image[0].filepath}`"
                                        style="height:100px;width:100px;"
                                        contain
                                    >
                                    </v-img>
                                </v-btn>
                            </v-card>
                            <v-card
                                v-else
                                color="blue darken-4"
                                style="float: right;"
                                class="d-flex"
                            >
                                <v-btn
                                    v-if="convo.image != null"
                                    style="height:100px;width:100px;"
                                    @click="ExpandImage(convo.image[0].filepath)"
                                    
                                >
                                    <v-img
                                        :src="`${url}/${convo.image[0].filepath}`"
                                        style="height:100px;width:100px;"
                                        contain
                                    >
                                    </v-img>
                                </v-btn>
                                <v-card-text>
                                    <small class="white--text">{{ convo.getuser.name }}</small>
                                    <h4 class="white--text">{{convo.message}}</h4>
                                    <small class="white--text">{{convo.created_at | formatDateChat}}</small>
                                </v-card-text>
                            </v-card>
                        </v-container>
                    </div>
                    
                    <v-container fluid>
                        <v-row
                            class="pa-2"
                        >
                            <v-file-input
                                style="max-width:5%; padding-top:8px"
                                hide-input
                                truncate-length="15"
                                placeholder="Add Images"
                                prepend-icon="image"
                                v-model="upload_image"
                            ></v-file-input>
                            
                            <v-text-field
                                rounded
                                v-model="message"
                                placeholder="Reply Here..."
                                outlined
                                counter
                                maxlength="100"
                                append-icon="send"
                                @click:append="SendMessage(conversation_type)"
                                @keypress.enter="SendMessage(conversation_type)"
                                :disabled="project_id === ''"
                            ></v-text-field>
                        </v-row>
                        <v-row class="d-flex" style="margin-top:-35px" v-if="upload_image !== null">
                            <v-btn icon @click="upload_image = null"><span class="material-icons-outlined">delete</span></v-btn>
                            <h5 class="pt-2" style="font-weight:normal">image attachment</h5>
                        </v-row>
                    </v-container>
                </v-row>
                <!-- body content chat -->
                </v-col>
            </v-row>
        </v-tabs-items>
        <v-dialog
            v-model="expand_image"
            max-width="500px"
        >
            <v-card>
                <v-card-title>
                    Attachment
                </v-card-title>
                <v-card-text>
                    <v-img
                        contain
                        :src="`${url}/${selected_image}`"
                    >
                    </v-img>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        text
                        @click="expand_image = false"
                    >
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
// import { mapGetters } from 'vuex'
export default {
  components: {
  },
  props: [
  ],
  data () {
    return {
        user_id: 0,
        project_id: '',
        conversation: [],
        conversation_type: 0,
        tab: '',
        search: null,
        url: null,
        message: null,
        messageInstance: null,
        role: null,
        switched_tab: true,
        upload_image: null,
        expand_image: false,
        selected_image: null,
    }
  },
  mounted () {
    this.$echo.private('reload-message.' + localStorage.getItem('id'))
    .listen('ReloadMessages', () => {
        this.LoadConversation(this.conversation_type);
    });

  },
  created () {
    if(localStorage.getItem("token") === null){
        this.$router.push({name: 'index'})
        return
    }
    this.$store.dispatch('setDrawer', true)
    this.url = process.env.VUE_APP_URL
    this.role = localStorage.getItem('role')

    //Set
    this.user_id = localStorage.getItem('id');
    this.project_id = localStorage.getItem('project_id');
    if(this.role < 3){
        this.tab == 'tab-1'
    }
    else{
        this.tab == 'tab-2'
    }
    this.conversation_type = this.tab == 'tab-1' ? null : 1;

    //Load
    this.LoadConversation(this.conversation_type);

  },
  computed: {
      reloadMessages(){
          //From VUEX
          return this.$store.getters['getRMessage'];
      }
  },
  watch: {
    //From Computed Function
    reloadMessages(data){
        if(data){
            //Load
            this.project_id = localStorage.getItem('project_id');
            //this.LoadConversation(this.conversation_type);
        }
    }
  },
  methods: {
    showSnackBar(message){
      this.$store.commit("setMessage", 
      {show: true, message: message}, 
      {root: 1})
    },
    async getConvo(toPass){
        this.messageInstance = toPass
        await this.$axios.post('/getChatConvo', {data: toPass})
        .then(({data}) => {
            if(data.response){
                this.$store.dispatch('setConvo', data.data)
                this.$store.dispatch('setGrabConvo', false)
            }
        })
    },
    async toggleSend(toPass){
        // console.log(this.convoData, this.message)
        if(this.message === '' || this.message === null){
            this.showSnackBar('Message Field should not be empty!')
            return
        }
        await this.$axios.post('sendMessageChat', {chat_id: toPass, msg: this.message})
        .then(({data}) => {
            if(data.response){
                this.showSnackBar(data.message)
                this.$store.dispatch('setConvo', data.data)
                this.message = null
            }
        })
    },
    ExpandImage(image){
        this.expand_image = true
        this.selected_image = image
    },
    SwitchTabs(){
        this.conversation_type = this.tab == 'tab-1' ? 0 : 1;
        this.LoadConversation(this.conversation_type);
        this.switched_tab = true
        this.upload_image = null
        this.message = null
    },

    async LoadConversation(type){
        if(this.project_id==""){
            this.showSnackBar('Please select a project.');
        }
        else{
            let payload =  {
                project_id: this.project_id,
                type: type
            };
            await this.$axios.get('loadConversation', payload)
            .then(({data}) => {
                // console.log(data)
                if(data.data.length > this.conversation.length || this.switched_tab == true){
                    this.conversation = data.data;
                    // console.log(this.conversation)
                    this.$nextTick(() => {
                        this.scrollToElement()
                    });
                    this.switched_tab = false
                }
                //this.conversation = data.data;
            });
            this.$store.dispatch('setRMessage', false);
        } 
    },

    scrollToElement(){
        if(this.tab == "tab-1"){
            var element = document.getElementById('chat1').scrollHeight
            document.getElementById('chat1').scrollTop = element
        }
        else if(this.tab == "tab-2"){
            var element2 = document.getElementById('chat2').scrollHeight
            document.getElementById('chat2').scrollTop = element2
        }
    },

    async SendMessage(type){
        //Validation
        if(this.message === "" || this.message === null){
            this.showSnackBar('Message Field should not be empty!');
        }
        else{
            const formData = new FormData();
            formData.append('user_id', this.user_id)
            formData.append('project_id', this.project_id)
            formData.append('message', this.message)
            formData.append('role', this.role)
            formData.append('type', type)
            formData.append('upload_image', this.upload_image)
            /*let payload = {
                user_id: this.user_id,
                project_id: this.project_id,
                message: this.message,
                type: type
            };*/

            await this.$axios.post('sendMessageChat', formData)
            .then(({data}) => {
                console.log(data)
                this.showSnackBar(data.message)
                if(data.response){
                    this.conversation = data.data
                    this.$nextTick(() => {
                        this.scrollToElement()
                    });
                    // this.$store.dispatch('setConvo', data.data)
                    this.message = null
                    this.upload_image = null
                }
            });
        }
    }
  }
}
</script>

<style scoped lang="scss">

</style>