<template>
  <v-container>
    <v-card
      class="mx-auto my-12"
    >
      <v-card-title style="background: #e3e3e4 !important;"><small><span style="position: relative; top: 5px;" class="material-icons-outlined mr-1">note</span>Site Diary</small></v-card-title>
      <v-row
        no-gutters
      >
        <div id="siteDiaryBtn">
        <v-btn
          v-if="!isAdd"
          class="mt-5 mb-5 ml-3"
          cols="12"
          width="100%;"
          dark
          color="#062a82"
          md="12"
          sm="12"
          @click="isAdd = true; siteDiaryDialog = true; fetchProjectClient()"
        >
          <span class="material-icons-outlined mr-1">add</span> Add Entry
        </v-btn>
        </div>
      </v-row>

      <!-- Modal Add New Site Diary -->
      <v-row v-if="isAdd" justify="center">
        <v-dialog
          v-model="siteDiaryDialog"
          scrollable
          persistent
          max-width="600px"
        >
          <v-card>
            <v-toolbar
              dark
              color="#062a82"
            >
              <v-btn
                icon
                dark
                @click="siteDiaryDialog = false; isAdd = false;"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title><small>Add Site Diary</small></v-toolbar-title>
            </v-toolbar>
            <v-divider></v-divider>
            <v-card-text style="height: 600px;">
              <v-list
                three-line
                subheader
              >
                <div v-if="loading" align="center">
                  <img src="https://acegif.com/wp-content/uploads/loading-23.gif" width="500" height="auto"/>
                </div>
                <v-list-item v-if="!loading">
                  <v-list-item-content>
                      <label>Date Today: {{dateToday}}</label>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="!loading">
                  <v-list-item-content>
                    <v-menu
                      v-model="menu2"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          filled
                          v-model="addSiteDiary.date"
                          label="Site Date"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="addSiteDiary.date"
                        @input="menu2 = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="!loading">
                  <v-list-item-content>
                  <v-text-field
                    filled
                    name="input-7-1"
                    label="Log Name"
                    v-model="addSiteDiary.logname"
                    placeholder="Log Name"
                  ></v-text-field>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="!loading">
                  <v-list-item-content>
                  <wysiwyg
                    v-model="addSiteDiary.notes"
                    label="Notes"
                  />
                  </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="!loading">
                  <v-list-item-content>
                    <v-combobox
                      filled
                      v-model="addSiteDiary.tags"
                      :items="siteDiaryTags"
                      :search-input.sync="search"
                      hide-selected
                      label="Add some tags"
                      multiple
                      persistent-hint
                      small-chips
                    >
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>
                              No results matching "<strong>{{ search }}</strong>". Press <kbd>enter</kbd> to create a new one
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-combobox>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="!loading">
                  <v-list-item-content>
                    <v-file-input
                      filled
                      label="Image"
                      name="file"
                      v-model="addSiteDiary.file"
                    ></v-file-input>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item v-if="role == 1">
                  <v-list-item-content>
                    <v-row align="center">
                      <v-col
                        class="d-flex"
                        cols="12"
                        sm="6"
                      >
                        <v-checkbox
                          v-model="addSiteDiary.is_share"
                          :label="`Share with Client`"
                        ></v-checkbox>
                      </v-col>
                      <v-col
                        class="d-flex"
                        cols="12"
                        sm="6"
                      >
                        <v-select 
                          v-model="addSiteDiary.selected_client"
                          :items="item_client"
                          item-text="name"
                          item-value="id"
                          multiple
                          chips
                          hint="Select a Client"
                          persistent-hint
                          :disabled="addSiteDiary.is_share==true ? false :  true"
                        ></v-select>
                      </v-col>
                    </v-row>


                  </v-list-item-content>
                </v-list-item>

              </v-list>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions style="background-color:white;">
              <v-spacer></v-spacer>
              <v-btn
                v-if="isAdd"
                class="elevation-0"
                @click="siteDiaryDialog = false; isAdd = false"
              >
                <span class="material-icons-outlined mr-1">cancel</span> Cancel
              </v-btn>
              <v-btn
                v-if="isAdd"
                class="pl-8 pr-8"
                dark
                color="#062a82"
                @click="saveSiteDiary"
              >
                <span class="material-icons-outlined mr-1">save</span> Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>

      <v-card-text>
        <div id="siteDiaryList"></div>
        <v-row
          class="justify-center"
          no-gutters
        >
          <v-text-field
            lg="12"
            md="12"
            sm="6"
            v-model="search"
            clearable
            label="Search In Site Diary"
            hide-details
          ></v-text-field>
          <v-col
            class="d-flex mt-5"
            cols="12"
            sm="12"
          >
            <v-data-table
              :headers="headersDiary"
              :items="siteDiary"
              class="elevation-1"
              style="width: 100%;"
              :search="search"
              mobile-breakpoint="0">
              <template v-slot:item="item">
                <tr>
                  <td style="cursor: pointer;" @click="showInfo(item)">{{item['item'].log_name}}<br/><small>{{item['item'].getuser.name}}</small></td>
                  <td style="cursor: pointer;" @click="showInfo(item)"><p v-html="item['item'].notes" style="overflow-y:auto;max-height:500px;"></p></td>
                  <td style="cursor: pointer;" @click="showInfo(item)">
                    <div v-for="(tag, index) in item" :key="index">
                      <div v-for="(tags, indexTag) in tag['tags']" :key="indexTag">{{tags}}<br/></div>
                    </div>
                  </td>
                  <td style="cursor: pointer;" @click="showInfo(item)">{{item['item'].created_at | formatDate}}</td>
                  <td style="cursor: pointer;" @click="showInfo(item)">{{item['item'].site_diary_date | formatDate}}</td>
                  <td style="cursor: pointer;">
                    <v-img
                      v-if="item['item'].image != null"
                      :lazy-src="`${url}frontend/images/${item['item'].image}`"
                      max-height="500"
                      max-width="250"
                      @click="showImage(item)"
                      :src="`${url}frontend/images/${item['item'].image}`"
                    >
                    </v-img>
                  </td>
                  <td style="cursor: pointer;" @click="showInfo(item)">
                    <v-icon v-if="item['item'].is_shared==1">
                      mdi-folder-account
                    </v-icon>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>

      <v-dialog
        v-model="dialogEditLog"
        scrollable
        max-width="600px"
        hide-overlay
        persistent
      >
        <v-card>
          <v-toolbar
            dark
            color="#062a82"
          >
            <v-btn
              icon
              dark
              @click="dialogEditLog = false; getData();"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title></v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-list
            three-line
            subheader
          >
            <v-subheader>Site Diary Edit: {{logsToEdit.log_name}}</v-subheader>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Log Name</v-list-item-title>
                <v-list-item-subtitle>
                  <v-text-field
                    name="input-7-1"
                    v-model="logsToEdit.log_name"
                    :placeholder="logsToEdit.log_name"
                  ></v-text-field>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Notes</v-list-item-title>
                <v-list-item-subtitle>
                  <wysiwyg
                    v-model="logsToEdit.notes"
                    label="Notes"
                  />
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Tags</v-list-item-title>
                <v-list-item-subtitle>
                  <v-combobox
                    v-model="logsToEdit.tags"
                    :items="siteDiaryTags"
                    :search-input.sync="search"
                    hide-selected
                    label="Add some tags"
                    multiple
                    persistent-hint
                    small-chips
                  >
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>
                            No results matching "<strong>{{ search }}</strong>". Press <kbd>enter</kbd> to create a new one
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-combobox>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Site Diary Date</v-list-item-title>
                <v-list-item-subtitle>
                  <v-menu
                    v-model="menuEdit"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="logsToEdit.site_diary_date"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="logsToEdit.site_diary_date"
                      @input="menuEdit = false"
                    ></v-date-picker>
                  </v-menu>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <div v-if="logsToEdit.image != null">
                  <v-checkbox
                    v-model="removeImage"
                    label="Remove Image"
                  ></v-checkbox>
                  <v-list-item-title>Saved Image</v-list-item-title>
                  <v-img
                    :lazy-src="`${url}frontend/images/${logsToEdit.image}`"
                    max-height="500"
                    max-width="250"
                    :src="`${url}/frontend/images/${logsToEdit.image}`"
                  >
                  </v-img>
                </div>
                <div v-else>
                  <v-list-item-title>No Image</v-list-item-title>
                </div>
                <v-list-item-subtitle>
                  <v-file-input
                    placeholder="Set Image"
                    v-model="logsToEdit.file"
                  ></v-file-input>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item v-if="role == 1">
                <v-list-item-content>
                  <v-row align="center">
                    <v-col
                      class="d-flex"
                      cols="12"
                      sm="6"
                    >
                      <v-checkbox
                        v-model="logsToEdit.is_shared"
                        :label="`Share with Client`"
                      ></v-checkbox>
                    </v-col>
                    <v-col
                      class="d-flex"
                      cols="12"
                      sm="6"
                    >
                      <v-select 
                        v-model="logsToEdit.selected_client"
                        :items="item_client"
                        item-text="name"
                        item-value="id"
                        multiple
                        chips
                        hint="Select a Client"
                        persistent-hint
                        :disabled="logsToEdit.is_shared==true ? false :  true"
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-list-item>

          </v-list>
          <v-card-actions style="background-color:white;">
          <v-spacer></v-spacer>
            <v-btn
                light
                text
                @click="dialogDelete = true"
              >
                <span class="material-icons-outlined">delete</span> Delete
            </v-btn>
            <v-btn
              light
              text
              @click="saveEditedLogs"
            >
              <span class="material-icons-outlined">edit</span> Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
        v-model="dialogDelete"
        max-width="290"
      >
        <v-card>
          <v-card-title>
            <small>Are you sure delete this record?</small>
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="green darken-1"
              text
              @click="dialogDelete = false"
            >
              <span class="material-icons-outlined">cancel</span>
            </v-btn>
            <v-btn
              color="green darken-1"
              text
              @click="deleteLog"
            >
              <span class="material-icons-outlined">delete</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
        v-model="dialogImage"
      >
        <v-card>
          <v-toolbar
            dark
            color="#062a82"
          >
            <v-btn
              icon
              dark
              @click="dialogImage = false;"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title></v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-img
            :cover="true"
            :lazy-src="`${url}frontend/images/${imgSrc}`"
            :src="`${url}frontend/images/${imgSrc}`"
          ></v-img>
        </v-card>
      </v-dialog>

    </v-card>
    <v-tour name="myTour" :steps="steps"></v-tour>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
  export default {
    data: () => ({
      userId: null,
      loading: false,
      role: null,
      item_client: [],
      url: null,
      headersDiary: [
        {text: 'Log Name', value: 'log_name'},
        {text: 'Notes', value: 'notes'},
        {text: 'Tag', value: 'tags'},
        {text: 'Date', value: 'created_at'},
        {text: 'Site Date', value: 'site_diary_date'},
        {text: 'Media', value: 'image'},
        {text: '', value: ''}
      ],
      isAdd: false,
      siteDiaryDialog: false,
      dateToday: null,
      menu2: false,
      menuEdit: false,
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString(),
      search: null,
      addSiteDiary: {
        date: '',
        logname: '',
        notes: '',
        tags: [],
        file: [],
        is_share: false,
        selected_client: []
      },
      logsToEdit: {},
      dialogEditLog: false,
      dialogDelete: false,
      removeImage: false,
      dialogImage: false,
      imgSrc: '',
      steps: [
        {
          target: '#siteDiaryBtn',
          content: `You can add your Site Diary entires here.`
        }
      ],
      urlParams: 'empty'
    }),
    components: {

    },
    mounted () {
      if(localStorage.getItem('introSiteDiary') === null){
        this.$tours['myTour'].start()
        localStorage.setItem('introSiteDiary', true)
      }
    },
    created () {
      if(localStorage.getItem("token") === null){
        this.$router.push({name: 'index'})
        return
      }
      if(localStorage.getItem('project_id') !== null){
        this.urlParams = localStorage.getItem('project_id')
      }
      this.userId = localStorage.getItem('id')
      this.role = localStorage.getItem('role')
      this.url = process.env.VUE_APP_URL
      this.dateToday = Date.parse(new Date()).format('d M, Y')
      this.getData()
    },
    watch: {
      reloadFn(data){
        if(data){
          this.urlParams = localStorage.getItem('project_id')
          return this.getData()
        }
      }
    },
    computed: {
      ...mapGetters({
        siteDiary:          'getSiteDiary',
        siteDiaryTags:      'getSiteDiaryTags'
      }),
      reloadFn(){
        return this.$store.getters['getReload']
      }
    },
    methods: {
      showSnackBar(message){
        this.$store.commit("setMessage", 
        {show: true, message: message}, 
        {root: 1})
      },
      async getData(){
        await this.$axios.get(`getSiteDiary/${this.urlParams}`)
        .then(({data}) => {
          this.$store.dispatch('setReload', false)
          this.$store.dispatch('setDrawer', true)
          if (this.role == 1) {
            if (data.data.length === 0 && this.urlParams === 'empty') {
              this.generateTemplate()
              return
            }
            this.$store.dispatch('setSiteDiary', data.data)
          }
          if (this.role == 2) {
            this.$store.dispatch('setSiteDiary', data.data)
          }
          if (this.role == 3) {
            let arr = []
            data.data.forEach(entry => {
              if (entry.users_id == this.userId) {
                arr.push(entry)
              }
              else {
                entry.getsharedclient.forEach(client => {
                  if (client.client_id == this.userId) {
                    arr.push(entry)
                  }
                })
              }
            })
            this.$store.dispatch('setSiteDiary', arr)
          }
        })
        await this.$axios.get('getTags')
        .then(({data}) => {
          if(data.response){
            const toTag = []
            data.data.forEach(q => {
              toTag.push(q.tags)
            })
            this.$store.dispatch('setSiteDiaryTags', toTag)
          }else{
            localStorage.removeItem('token')
            localStorage.removeItem('id')
            localStorage.removeItem('name')
            localStorage.removeItem('username')
            localStorage.removeItem('email')
            localStorage.removeItem('phone')
            this.$router.push({ name: 'index' })
          }
        })
      },
      async generateTemplate(){
        this.showSnackBar('Generating Template, Please wait...')
        await this.$axios.get(`genSiteTmp/${this.urlParams}`)
        .then(({data}) => {
          if(data.response){
            this.getData()
            this.showSnackBar(data.message)
          }
        })
      },
      async saveSiteDiary() {
        this.loading = true
        const formData = new FormData()
        formData.append('date', this.addSiteDiary.date)
        formData.append('file', this.addSiteDiary.file)
        formData.append('logname', this.addSiteDiary.logname)
        formData.append('notes', this.addSiteDiary.notes)
        formData.append('tags', this.addSiteDiary.tags)
        formData.append('is_shared', this.addSiteDiary.is_share)
        formData.append('client', this.addSiteDiary.selected_client)
        await this.$axios.post(`addSiteDiaryEntry/${this.urlParams}`, formData)
        .then(({data}) => {
          if(data.response){
            this.loading = false
            this.is_share = false
            this.selected_client = []
            this.addSiteDiary = {
              date: '',
              logname: '',
              notes: '',
              tags: [],
              file: [],
              is_shared: false,
              selected_client: []
            }
            const toTag = []
            data.tags.forEach(q => {
              toTag.push(q.tags)
            })
            this.$store.dispatch('setSiteDiaryTags', toTag)
            if (this.role == 1) this.$store.dispatch('setSiteDiary', data.data);
            if (this.role == 2) {
              this.$store.dispatch('setSiteDiary', data.data)
            }
            if (this.role == 3) {
              let arr = []
              data.data.forEach(entry => {
                if (entry.users_id == this.userId) {
                  arr.push(entry)
                }
                else {
                  entry.getsharedclient.forEach(client => {
                    if (client.client_id == this.userId) {
                      arr.push(entry)
                    }
                  })
                }
              })
              this.$store.dispatch('setSiteDiary', arr)
            }
            this.isAdd = false
            this.siteDiaryDialog = false
            this.showSnackBar(data.message)
            return
          }else{
            this.showSnackBar(data.message)
            this.loading = false
            return
          } 
        })
      },
      showInfo(data) {
        if (this.role == 3 && data['item'].is_shared) {
          this.showSnackBar('You may only edit your own logs')
          return
        }
        //Load Project Client
        this.fetchProjectClient();
        //Set
        this.logsToEdit = data.item;
        this.logsToEdit.selected_client = [];
        //Loop Selected Client
        for(let i=0;i<data.item.getsharedclient.length;i++){
          this.logsToEdit.selected_client.push(data.item.getsharedclient[i]['client_id']);
        }
        this.dialogEditLog = true

      },
      async saveEditedLogs(){
        const formData = new FormData()
        formData.append('date', this.logsToEdit.site_diary_date)
        formData.append('file', this.logsToEdit.file)
        formData.append('logname', this.logsToEdit.log_name)
        formData.append('notes', this.logsToEdit.notes)
        formData.append('tags', this.logsToEdit.tags)
        formData.append('is_shared', this.logsToEdit.is_shared)
        formData.append('id', this.logsToEdit.id)
        formData.append('client', this.logsToEdit.selected_client)
        formData.append('dImage', this.removeImage)
        await this.$axios.post(`editDiaryLog/${this.urlParams}`, formData)
        .then(({data}) => {
          if(data.response){
            const toTag = []
            data.tags.forEach(q => {
              toTag.push(q.tags)
            })
            this.$store.dispatch('setSiteDiaryTags', toTag)
            this.getData()
            this.dialogEditLog = false
            this.removeImage = false
            this.showSnackBar(data.message)
            return
          }else{
            this.showSnackBar(data.message)
            return
          }
        })
      },
      async deleteLog(){
        this.dialogDelete = false
        await this.$axios.delete(`deleteItem/${this.logsToEdit.id}/${this.urlParams}`)
        .then(({data}) => {
          const toTag = []
          data.tags.forEach(q => {
            toTag.push(q.tags)
          })
          this.$store.dispatch('setSiteDiaryTags', toTag)
          if (this.role == 1) this.$store.dispatch('setSiteDiary', data.data);
          if (this.role == 2) {
            this.$store.dispatch('setSiteDiary', data.data)
          }
          if (this.role == 3) {
            let arr = []
            data.data.forEach(entry => {
              if (entry.users_id == this.userId) {
                arr.push(entry)
              }
              else {
                entry.getsharedclient.forEach(client => {
                  if (client.client_id == this.userId) {
                    arr.push(entry)
                  }
                })
              }
            })
            this.$store.dispatch('setSiteDiary', arr)
          }
          this.dialogEditLog = false
          this.showSnackBar(data.message)
        })
      },
      showImage(data){
        this.imgSrc = data.item.image
        this.dialogImage = true
      },
      
      // Fetch Project Client
      async fetchProjectClient(){
        
        let payload = {
          user_id: this.userId,
          project_id: this.urlParams
        };

        await this.$axios.get('/sitediary/fetch/projectclient', payload).then(({data}) => {
                    
          if(data.success){

            this.item_client = [];
            data.client.forEach(value => {
              this.item_client.push({
                id: value.id,
                name: value.name
              });
            });

          }

        });

      }

    }
  }
</script>
<style scoped lang="scss">
.v-data-table-header th {
  white-space: nowrap;
}
</style>