import Vue from 'vue';

export class SyncFromBOQ {

    constructor(project_id, users_id) {
        this.project_id = project_id;
        this.users_id = users_id;        
    }

    check_syncfromboq_to_pp() {
        return Vue.axios.get(`projectplanner/check_pp_is_sycn_to_boq/${this.project_id}/${this.users_id}`)
            .then( (data) => {
                if ( data.status == 200 ) {
                    const result = data.data
                    if ( result.response == 'success' ) {
                        return result.data
                    } else {
                        console.log(result.data)
                    }
                } else {
                    console.log(data.statusText)
                }
            })
    }

    save_syncfromboq_to_pp_config(is_sync) {
        return Vue.axios.post(`projectplanner/syncdata/${this.project_id}/${this.users_id}`, {
            is_synced: is_sync
        }).then( (data) => {
            if ( data.status == 200 ) {
                const result = data.data
                if ( result.response == 'success' ) {
                    return result.data
                } else {
                    console.log(result.data)
                }
            } else {
                console.log(data.statusText)
            }
            
        })
    }

}