<template>
  <v-container>
    <v-card>
      <v-card-title style="background: #e3e3e4 !important;"><small><v-icon class="mr-2">mdi-arrow-decision</v-icon>Decisions</small></v-card-title>
      <v-card-text>
        <v-row no-gutters> 
          <v-col>
            <v-btn
              depressed
              color="primary"
              class="ma-5 float-end"
              @click="FetchDecisionParentInformation()"
            >
              Add Entry 
              <!-- <v-icon>mdi-plus</v-icon> -->
            </v-btn>
          </v-col>
        </v-row>
        <v-row
          no-gutters
        >
          <v-col>
            <v-list three-line>
              <v-list-group
                v-for="item in getdecision"
                :key="item.id"
                no-action
                class="ma-2"
              >
                  <template v-slot:activator>
                    <v-list-item-content>
                      <v-list-item-title>
                        <strong>{{item.item}}</strong>
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        <label v-if="item.getnotdone_count > 0">{{item.getnotdone_count}} tasks remaining</label>
                        <label v-else>All done! Good job</label>
                      </v-list-item-subtitle>
                      <v-list-item-subtitle>
                        Progress
                        <v-progress-linear
                          :value="Math.ceil(item.getdone_count * (100 / item.child.length))"
                          color="#00ae3b"
                          background-color="#e1e1e1"
                          height="12"
                        ></v-progress-linear>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                  <v-data-table
                    :headers="decisionHeaders"
                    :items="item.child"
                    class="elevation-2"
                    style="width: 100%;"
                    :hide-default-footer="true"
                    disable-pagination
                    mobile-breakpoint="1">
                    <template v-slot:item.is_done="props">
                      <v-checkbox
                        @change="setdone(props.item)"
                        v-model="props.item.is_done"
                      ></v-checkbox>
                    </template>
                    <template v-slot:item.item="props">
                      <div
                        v-if="props.item.is_done"
                      >
                        <label style="text-decoration: line-through;">{{props.item.item}}</label>
                      </div>
                      <div
                        v-else
                      >
                        <label style="cursor: pointer;" @click="anModal(props.item)">{{props.item.item}}</label>
                      </div>
                    </template>
                    <template v-slot:item.getnotes="props">
                      <small v-if="props.item.getnotes.length === 0" style="cursor: pointer;" @click="anModal(props.item)">add a note</small><br/>
                      <label v-for="(item, index) in props.item.getnotes"
                      :key="index">
                        <!-- <label style="cursor: pointer;" @click="anModal(props.item)">{{item.note}}</label><br/> -->
                        <v-chip
                          style="cursor: pointer;"
                          @click="anModal(props.item)"
                        >
                          <v-icon left>mdi-note</v-icon>
                          {{item.note}}
                        </v-chip>
                      </label>
                    </template>
                        <template v-slot:item.action="props">
                          <!-- <v-icon
                            small
                            class="mr-2"
                            @click="editItem(item)"
                          >
                            mdi-pencil
                          </v-icon> -->
                          <v-icon
                            small
                            @click="DeleteItemDecision(props.item)"
                          >
                            mdi-delete
                          </v-icon>
                      </template>
                  </v-data-table>
              </v-list-group>
            </v-list>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <!-- note modal -->
    <v-row justify="center">
      <v-dialog
        v-model="noteModal"
        persistent
        max-width="390"
      >
        <v-card>
          <v-card-title class="text-h5">
            <small style="fontSize: 14px;">Add Notes</small>
          </v-card-title>
          <v-card-text>
            <label>Previous notes</label><br/>
            <v-chip
              v-for="(note, noteindex) in prev_notes"
              :key="noteindex"
              class="mb-3 mt-1 mr-2"
              close
              @click:close="removeItem(note)"
            >
              <v-icon left
                style="cursor: pointer;"
                @click="edit_note(note)"
              >
                mdi-pen
              </v-icon>
              {{ note.note }}
            </v-chip>
            <v-textarea
              solo
              v-model="note"
              name="input-7-4"
            ></v-textarea>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="red darken-1"
              text
              @click="noteModal = false; note = null"
            >
              Cancel
            </v-btn>
            <v-btn
              color="#062a82"
              dark
              @click="saveNotes"
              class="pl-8 pr-8 elevation-0"
            >
              <span class="material-icons-outlined mr-1">save</span> Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <!-- New Decision Dialog -->
    <v-dialog 
      max-width="500px"
      v-model="new_decision"
      persistent
    >
      <v-card>
        <v-card-title>
          Add Decision
        </v-card-title>
        <v-card-text>
           <v-combobox
                v-model="decision"
                :items="decision_items"
                :search-input.sync="decision_search"
                hide-selected
                label="Select a decision"
                persistent-hint
                small-chips
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        No results matching "<strong>{{ decision_search }}</strong>". Press <kbd>enter</kbd> to create a new one
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
            </v-combobox>
            <v-text-field
              label="Items"
              v-model="decision_item"
            ></v-text-field>
            <v-btn
              text
              @click="CloseDecision()"
              class="float-end"
            >
              <span class="material-icons-outlined">cancel</span>

            </v-btn>

            <v-btn
              text
              @click="AddUpdateDecision()"
              class="float-end"
            >
              <span class="material-icons-outlined">save</span>
            </v-btn>

        </v-card-text>
        <v-card-actions>
            <p></p>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- delete note modal -->
    <v-dialog 
      max-width="500px"
      v-model="delete_note_modal"
      persistent
    >
      <v-card>
        <v-card-title>
          Delete a note
        </v-card-title>
        <v-card-text>
          are you sure you want to delete note: <strong style="text-decoration: underline;">{{ to_delete_note.note }}</strong>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn
            class="pl-10 pr-10"
            text
            @click="delete_note_modal = false; to_delete_note = {}"
          >
            Cancel
          </v-btn>
          <v-btn
            color="red"
            class="pl-10 pr-10 white--text"
            @click="continue_delete_note"
          >
            <v-icon
              class="mr-2"
            >mdi-delete</v-icon>
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- edit note dialog -->
    <v-dialog 
      max-width="500px"
      v-model="edit_note_modal"
      persistent
    >
      <v-card>
        <v-card-title>
          Edit a note
        </v-card-title>
        <v-card-text>
          <v-text-field
            :value="to_edit_note.note"
            prepend-inner-icon="mdi-note"
            outlined
            dense
            v-model="to_edit_note.note"
          >
          </v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn
            class="pl-10 pr-10"
            text
            @click="refresh_notes()"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            class="pl-10 pr-10 white--text"
            @click="continue_edit_note"
          >
            <v-icon
              class="mr-2"
            >mdi-content-save</v-icon>
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-container>

</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {
  },
  props: [
  ],
  data () {
    return {
      decision_items: [],
      decision: "",
      decision_item: "",
      decision_search: null,
      url: null,
      urlParams: 'empty',
      userId: null,
      role: null,
      new_decision: false,
      decisionHeaders: [
        // {text: 'ID', value: 'index'},
        {text: '', value: 'is_done'},
        {text: 'Item', value: 'item'},
        {text: 'Notes', value: 'getnotes'},
        {text: '', value: 'action'},
      ],
      noteModal: false,
      note: null,
      toaddnotedata: null,
      parentdata: null,
      knowledge: 30,
      prev_notes: [],
      delete_note_modal: false,
      to_delete_note: {},
      edit_note_modal: false,
      to_edit_note: {},
    }
  },
  mounted () {
  },
  created () {
    if(localStorage.getItem("token") === null){
      this.$router.push({name: 'index'})
      return
    }
    if(localStorage.getItem('project_id') !== null){
      this.urlParams = localStorage.getItem('project_id')
    }
    this.userId = localStorage.getItem('id')
    this.role = localStorage.getItem('role')
    this.$store.dispatch('setDrawer', true)
    this.url = process.env.VUE_APP_URL
    this.fetchData()
  },
  watch: {
    reloadFn(data){
      if(data){
        this.urlParams = localStorage.getItem('project_id')
        this.$store.dispatch('setReload', false)
        return this.fetchData()
      }
    }
  },
  computed: {
    ...mapGetters({
      getdecision:          'getdecision',
      getdecisiondetails:   'getdecisiondetails'
    }),
    decisionsWIthIndex(){
      return this.getdecisiondetails.map(
      (items, index) => ({
        ...items,
        index: index + 1
      }))
    },
    reloadFn(){
      return this.$store.getters['getReload']
    },
  },
  methods: {
    anModal(data){
      this.prev_notes = data.getnotes
      this.toaddnotedata = data
      this.noteModal = true
    },
    async setdone(data){
      // console.log(data)
      await this.$axios.patch('/setDoneDec', {id: data.id, is_done: data.is_done})
      .then(({data}) => {
        console.log(data)
        this.fetchData()
      })
    },
    async saveNotes(){
      // console.log(this.toaddnotedata)
      await this.$axios.post('addNoteDecision', {data: this.toaddnotedata, note: this.note})
      .then(({data}) => {
        if(data.response){
          this.showSnackBar(data.message)
          this.noteModal = false
          this.searchDecision(this.parentdata)
          this.note = null
          this.fetchData()
        }else{
          this.showSnackBar(data.message)
        }
      })
    },
    showSnackBar(message){
      this.$store.commit("setMessage", 
        {show: true, message: message}, 
        {root: 1})
    },
    async fetchData(){
      await this.$axios.get(`/gDecisionL/${this.userId}/${this.urlParams}`)
      .then(({data}) => {
        // console.log(data)
        if(data.data.length == 0 && this.role == 1){
          this.showSnackBar('Please Wait.. Generating Template')
          this.generateDecisionsTemplate()
          return
        }
        this.$store.dispatch('setdecision', data.data)
        this.$store.dispatch('setReload', false)
      })
    },
    async generateDecisionsTemplate(){
      await this.$axios.get(`generateDecisionTemplate/${this.urlParams}`)
      .then(({data}) => {
        if(data.response){
          this.fetchData()
        }
      })
    },
    async searchDecision(item){
      this.parentdata = item
      await this.$axios.post(`sDecision/${this.urlParams}`, item)
      .then(({data}) => {
        // console.log(data)
        if(data.response){
          this.$store.dispatch('setdecisiondetails', data.data)
        }
      })
    },

    NewDecision(){
      this.new_decision = true;
    },

    CloseDecision(){
      this.new_decision = false;
    },

    async FetchDecisionParentInformation(){

      let payload = {
          project_id: this.urlParams,
          user_id: this.userId
      };

      await this.$axios.get(`decision/fetchparentinformation`, payload)
      .then(({data}) => {

        if(data.response){

          this.decision_items = [];

          data.decision.forEach(value => {
            this.decision_items.push(value.item);
          });

          this.NewDecision();

        }

        console.log(data);

      });

    },

    AddUpdateDecision(){

      let payload = {
        decision: this.decision,
        item: this.decision_item,
        project_id: this.urlParams,
        user_id: this.userId
      };

      this.$axios.post(`decision/addupdatedecision`, payload)
      .then(({data}) => {

        if(data.response){

          this.decision = "";
          this.decision_item = "";
          this.CloseDecision();
          this.fetchData();

        }

        console.log(data);

      });

    },

    DeleteItemDecision(data){

      let payload = {
        data: data
      };

      this.$axios.post(`decision/removedecision`, payload)
      .then(({data}) => {

        if(data.response){
          this.fetchData();
        }

        // console.log(data);

      });

    },
    removeItem(data){
      this.to_delete_note = data
      this.delete_note_modal = true
    },
    async continue_delete_note(){
      await this.$axios.delete(`/remove_decision_notes/${this.to_delete_note.id}`)
      this.fetchData()
      this.delete_note_modal = false
      this.noteModal = false
    },
    edit_note(data){
      this.edit_note_modal = true
      this.to_edit_note = data
    },
    refresh_notes(){
      this.fetchData()
      this.noteModal = false
      this.edit_note_modal = false
      this.to_edit_note = {}
    },
    async continue_edit_note(){
      await this.$axios.patch('edit_decision_note', {
        id:   this.to_edit_note.id,
        note: this.to_edit_note.note
      })
      .then(({data}) => {
        if(data.response){
          this.refresh_notes()
        }
      })
    }

  },
}
</script>

<style scoped lang="scss">
</style>