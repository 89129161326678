<template>
  <v-container>
    <v-card
      class="mx-auto my-5"
    >
      <v-card-title
        style="background: #e3e3e4 !important;"
      >
        <small v-if="task=='edit'"><span style="position: relative; top: 5px;" class="material-icons-outlined mr-1">ballot</span>Project</small>
        <small v-else><span style="position: relative; top: 5px;" class="material-icons-outlined mr-1">ballot</span>Project Creation</small>
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click="dialog = !dialog"
          v-if="task=='edit' && project_information.name != ''"
        >
          <v-icon>mdi-trash-can</v-icon>
        </v-btn>
      </v-card-title>
      <v-col
        cols="12"
        class="mt-3"
      >
          <label><strong>Project Information</strong></label>
          <v-text-field
            label="Project Name"
            outlined
            class="mt-4"
            v-model="project_information.name"
            id="start_step_0"
          ></v-text-field>
          <!-- <v-combobox
            v-model="project_information.project_type_id"
            :items="project_type"
            :search-input.sync="search"
            item-header="header"
            item-value="text"
            hide-selected
            label="Project Type"
            small-chips
            outlined
            @change="Show()"
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    No results matching "<strong>{{ search }}</strong>". Press <kbd>enter</kbd> to create a new one
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-combobox> -->
          <v-text-field
            label="Project Location"
            outlined
            v-model="project_information.location"
            id="start_step_1"
          ></v-text-field>
      </v-col>
      <v-col cols="12" style="margin-top: -35px;">
        <v-divider></v-divider>
      </v-col>
      <v-col cols="12">
        <label id="start_step_2"><strong>Project Schedule</strong></label>
        <v-menu
          v-model="project_start_menu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="project_information.start_date"
              label="Project Start Date"
              readonly
              v-bind="attrs"
              v-on="on"
              outlined
              class="mt-3"
              clearable
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="project_information.start_date"
            @input="project_start_menu = false"
          ></v-date-picker>
        </v-menu>
        <v-menu
          v-model="actual_start_menu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="project_information.actual_start_date"
              label="Actual Start Date"
              readonly
              v-bind="attrs"
              v-on="on"
              outlined
              clearable
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="project_information.actual_start_date"
            @input="actual_start_menu = false"
          ></v-date-picker>
        </v-menu>
        <v-menu
          v-model="project_completion_menu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="project_information.completion_date"
              label="Project Completion Date"
              readonly
              v-bind="attrs"
              v-on="on"
              outlined
              clearable
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="project_information.completion_date"
            @input="project_completion_menu = false"
          ></v-date-picker>
        </v-menu>
        <v-menu
          v-model="actual_completion_menu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="project_information.actual_completion_date"
              label="Actual Completion Date"
              readonly
              v-bind="attrs"
              v-on="on"
              outlined
              clearable
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="project_information.actual_completion_date"
            @input="actual_completion_menu = false"
          ></v-date-picker>
        </v-menu>
        <label><strong>Working Days</strong></label>
      </v-col>
      <div id="start_step_3">
        <v-row class="ml-1">
          <v-col cols="12" sm="3" md="3">
            <v-checkbox
              v-model="project_schedule.monday"
              label="Monday"
            ></v-checkbox>
          </v-col>
          <v-col cols="12" sm="3" md="3">
            <v-checkbox
              v-model="project_schedule.tuesday"
              label="Tuesday"
            ></v-checkbox>
          </v-col>
          <v-col cols="12" sm="3" md="3">
            <v-checkbox
              v-model="project_schedule.wednesday"
              label="Wednesday"
            ></v-checkbox>
          </v-col>
          <v-col cols="12" sm="3" md="3">
            <v-checkbox
              v-model="project_schedule.thursday"
              label="Thursday"
            ></v-checkbox>
          </v-col>
        </v-row>
        <v-row class="ml-1" style="margin-top: -35px;">
          <v-col cols="12" sm="3" md="3">
            <v-checkbox
              v-model="project_schedule.friday"
              label="Friday"
            ></v-checkbox>
          </v-col>
          <v-col cols="12" sm="3" md="3">
            <v-checkbox
              v-model="project_schedule.saturday"
              label="Saturday"
            ></v-checkbox>
          </v-col>
          <v-col cols="12" sm="3" md="3">
            <v-checkbox
              v-model="project_schedule.sunday"
              label="Sunday"
            ></v-checkbox>
          </v-col>
        </v-row>
      </div>
      <v-col cols="12" style="margin-top: -20px;">
        <v-divider></v-divider>
      </v-col>
      <v-col cols="12">
          <v-text-field
            label="Contract Price"
            outlined
            v-model="project_information.contract_price"
            id="start_step_4"
          ></v-text-field>
          <v-text-field
            label="Project Manager"
            outlined
            readonly
            v-model="project_manager"
            id="start_step_5"
          ></v-text-field>
          <v-text-field
            label="Total Floor Area"
            outlined
            v-model="project_information.total_floor_area"
            id="start_step_6"
          ></v-text-field>
          <v-btn
            block
            color="primary"
            elevation="2"
            v-if="task=='edit'"
            @click="UpdateTask()"
          >Update</v-btn>
          <v-btn
            block
            color="primary"
            elevation="2"
            @click="CreateTask()"
            id="start_step_7"
            v-else
          >Create</v-btn>
      </v-col>
      
    </v-card>
    <!-- <v-tour name="myTour" :steps="steps"></v-tour> -->
    <v-dialog
      v-model="dialog"
      persistent
      max-width="290"
    >
      <v-card>
        <v-card-title class="text-h5">
          Delete Project
        </v-card-title>
        <v-card-text>This will delete the project information and it cannot be undone do you want to proceed?.</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="dialog = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="red darken-1"
            text
            @click="removeProject()"
          >
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <SyncBOQDialog
      :evnt_open_dialog="evnt_open_syncboq_dialog"
      @evnt_close_dialog="evnt_close_syncfromboq_dialog()"></SyncBOQDialog>

      <TourView ref="tourguide"></TourView>
  </v-container>
</template>

<script>

// import { mapGetters } from 'vuex';
//import treeview from '../subcomponents/projectplanner.vue'
// import Gantt from './GanttChart.vue'

import SyncBOQDialog from '../modules/projectplanner/components/SyncBOQDialog.vue'
import { SyncFromBOQ } from '../modules/projectplanner/classes/syncfromboq'

export default {
  components: {
    //treeview
    // Gantt
    SyncBOQDialog
  },
  props: {
    task: {
      type: String,
      default () {
        return ""
      }
    }
  },
  data () {
    return {
      evnt_open_syncboq_dialog: false,
      user_id: 0,
      project_id: 0,
      schedule_id: 0,
      project_start_menu: false,
      actual_start_menu: false,
      project_completion_menu: false,
      actual_completion_menu: false,
      project_type: [],
      project_manager: '',
      search: '',
      project_information: {
        name: '',
        project_type_id: null,
        location: '',
        start_date: '',
        actual_start_date: '',
        completion_date: '',
        actual_completion_date: '',
        user_id: 0,
        contract_price: 0,
        total_floor_area: ''
      },
      project_schedule: {
        monday: 1,
        tuesday: 1,
        wednesday: 1,
        thursday: 1,
        friday: 1,
        saturday: 0,
        sunday: 0
      },
      steps: [
        {
          target: '#systemTemplatesButton',
          content: 'You can find our Typical "Construction Schedule" here, or create your own.'
        },
        {
          target: '#chooseATemplate',
          content: 'You can find your uploaded templates here.'
        },
      ],
      urlParams: 'empty',
      dialog: false
    }
  },
  mounted () {
    //if(localStorage.getItem('introPP') === null){
      //this.$tours['myTour'].start()      
      const introData = JSON.parse(localStorage.getItem('intro_data'))
      if ( this.task != 'edit' && introData.project_creation == false ) {
        this.$refs.tourguide.set_tour_steps('tour_createproject');
        this.$refs.tourguide.start_tour();
        let updatedIntroData = introData
        updatedIntroData.project_creation = true
        localStorage.setItem('intro_data', JSON.stringify(updatedIntroData))
      }
    //}
    
    const syncObject = new SyncFromBOQ(localStorage.getItem('project_id'), localStorage.getItem('id'))
    syncObject.check_syncfromboq_to_pp()
      .then( (data) => {
        if ( data == 0 ) {
          const chosen_template = localStorage.getItem('chosen_template')
          if ( chosen_template ) {
            this.evnt_open_syncboq_dialog = true;
            
            setTimeout( () => {
              // Set the cost estimation to true
              const introData = JSON.parse(localStorage.getItem('intro_data'))

              let updatedIntroData = introData;
              updatedIntroData.cost_estimation = true
              localStorage.setItem('intro_data', JSON.stringify(updatedIntroData))

              if ( introData.project_planner == false ) {
                this.$refs.tourguide.set_tour_steps('tour_projectplanner')
                this.$refs.tourguide.start_tour()
              }
            }, 500);
          }
        }
      })
    
    if ( this.evnt_open_syncboq_dialog == false && this.task == 'edit' ) {
      const introData = JSON.parse(localStorage.getItem('intro_data'))
      if ( introData.project_planner == false ) {
        this.$refs.tourguide.set_tour_steps('tour_projectplanner_part_1')
        this.$refs.tourguide.start_tour()
      }
    }
  },
  created () {
    if(localStorage.getItem("token") === null){
      this.$router.push({name: 'index'})
      return
    }

    //Set
    this.$store.dispatch('setDrawer', true);

    //Load
    //this.fetchProjectType();
    if(localStorage.getItem('project_id') !== null){
      this.urlParams = localStorage.getItem('project_id')
    }else{
      this.tab = 'tab-2'
    }

    if(this.task=="edit"){
      this.fetchProjectInformation();
    }
    else{
      this.project_manager = localStorage.getItem('name');
      this.user_id = localStorage.getItem('id');
      this.SetInformations();
    }

  },
  watch: {

  },
  computed: {

  },
  methods: {
    // Close Sync from BOQ Dialog
    evnt_close_syncfromboq_dialog() {
      this.evnt_open_syncboq_dialog = false
      setTimeout(() => location.reload(), 500);
    },
    //New Methods
    showSnackBar(message){
        this.$store.commit("setMessage", 
        {show: true, message: message}, 
        {root: 1})
    },
    checkDateisValid(date){
        if(date == '0000-00-00' || !date) return false;
        return true;
    },
    /*fetchProjectType(){
      this.$axios.get('taskmanager/fetch/projecttype')
      .then(({data}) => {
          console.log(data);
          data.project_type.forEach(value => {
              this.project_type.push({
                  header: value.name
              });
              value.get_child.forEach(child => {
                this.project_type.push({
                    text: child.name,
                    value: child.id
                });
              });
          });
      });
    },*/
    CreateTask(){

      let payload = {
          project_information: this.project_information,
          project_schedule: this.project_schedule
      };

      this.$axios.post('taskmanager/create/project', payload)
      .then(({data}) => {
          console.log(data);
          if(data.success){
            localStorage.setItem('project_id', this.project_information.name);
            localStorage.removeItem('chosen_template')
              this.SetInformations();
              //this.fetchProjectType();
              this.showSnackBar(data.message);
              //this.$router.push({name: 'profile'})
              if ( this.task != 'edit' ) {
                this.$router.push({name: 'budgetmanager'})
              }
              setTimeout(() => location.reload(), 500);
          }
          else{
              this.showSnackBar(data.message);
          }
      });

    },
    SetInformations(){
        this.project_information = {
          name: '',
          project_type_id: null,
          location: '',
          start_date: '',
          actual_start_date: '',
          completion_date: '',
          actual_completion_date: '',
          user_id: this.user_id,
          contract_price: 0,
          total_floor_area: ''
        };
        this.project_schedule = {
          monday: 1,
          tuesday: 1,
          wednesday: 1,
          thursday: 1,
          friday: 1,
          saturday: 0,
          sunday: 0
        };
    },
    Show(){
      console.log(this.project_information);
    },
    fetchProjectInformation(){

      let payload = {
          project_name: this.urlParams
      };

      this.$axios.get('taskmanager/fetch/projectinformation', payload)
      .then(({data}) => {
          // console.log(data.data);
          if(data.success){
              this.project_manager = data.data.project_manager_info.name;
              this.project_id = data.data.id;
              this.project_information = {
                name: data.data.name,
                project_type_id: null,
                location: data.data.location,
                start_date: this.checkDateisValid(data.data.start_date) ? data.data.start_date : '',
                actual_start_date: this.checkDateisValid(data.data.actual_start_date) ? data.data.actual_start_date : '',
                completion_date: this.checkDateisValid(data.data.completion_date) ? data.data.completion_date : '',
                actual_completion_date: this.checkDateisValid(data.data.actual_completion_date) ? data.data.actual_completion_date : '',
                user_id: data.data.user_id,
                contract_price: parseFloat(data.data.contract_price).toFixed(2),
                total_floor_area: data.data.total_floor_area
              };
              this.schedule_id = data.data.schedule_info.id;
              this.project_schedule = {
                monday: data.data.schedule_info.monday,
                tuesday: data.data.schedule_info.tuesday,
                wednesday: data.data.schedule_info.wednesday,
                thursday: data.data.schedule_info.thursday,
                friday: data.data.schedule_info.friday,
                saturday: data.data.schedule_info.saturday,
                sunday: data.data.schedule_info.sunday
              };

          }

      });
    },
    UpdateTask(){

      let payload = {
          project_id: this.project_id,
          project_information: this.project_information,
          schedule_id: this.schedule_id,
          project_schedule: this.project_schedule
      };

      this.$axios.post('taskmanager/update/project', payload)
      .then(({data}) => {
          if(data.success){
              this.showSnackBar(data.message);
          }
          else{
              this.showSnackBar(data.message);
          }
      });

    },
    removeProject(){

      let payload = {
        project_name: this.urlParams
      };

      this.$axios.post('taskmanager/remove/project', payload)
      .then(({data}) => {
          if(data.success){
              this.showSnackBar(data.message);
              location.reload();
          }
          else{
              this.showSnackBar(data.message);
          }
      });
      this.dialog = false;
    }
    // End New Methods
  }
}
</script>
<style lang="scss">
// css part para sa page na ito
.v-btn {
  min-width: 0;
}
tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, .02);
}
.v-data-table::v-deep td {
  font-size: 12px !important;
}
.v-select__selection,
.v-select__selection--comma,
.v-select.v-text-field input {
  color: #062a82 !important;
}
.v-data-table-header thead tr th{
  color: #062a82 !important;
  font-weight: 900;
}

.v-data-table-header {
  background-color: #e9e9e9 !important;
  
}

.v-treeview-node__level{
  display: none;
}

// .v-treeview-node__children{
//   display: inline-flex;
//   padding: 8px;
// }

// .elevation-1 mt-5{
//     background-color: #e9e9e9 !important;
// }

// .custom-container{
//     min-height: 700px !important;
// }

// .custom-header{
//     font-size: 30px !important;
//     font-weight: 700 !important;
// }

.v-slide-group__prev {
  display: none !important;
}

.v-slide-group__prev--disabled {
  display: none !important;
}

.custom-tabs {
    align-content: left;
    display: flex;
    width: 100%;
}

.custom-tab-1{
    background-color:#c6d5fc !important;
    border-radius: 12px 12px 0px 0px !important;
    // color: white !important;
    font-weight:900 !important;
}

table.v-table tbody td {
    height: 40px;
    border: none;
}
.theme--light.v-table tbody tr:not(:last-child) {
    border-bottom: none;
}

// .custom-search{
//     background-color: #f2f2f2 !important;
//     border-radius: 10px 10px 10px 10px !important;
//     padding-top: 4px !important;
//     border-color:#828282 !important;
//     border-width:1px !important;
//     border-style: solid !important;
// }

//  .custom-table{
//     border-color:#b1b1b1 !important;
//     border-width:1px !important;
//     border-style: solid !important;
//  }
 
//  .custom-task-name{
//     color: #2e4e9f !important;
//  }

//  .custom-task-name-text{
//     font-size: 14px !important;
//     font-weight: 700 !important;
//     margin-left: 3px !important;
//     margin-top: 4px !important;
//     color: #2e4e9f !important;
//  }
 

 
//  .custom-subtask-item {
//     margin-top: 14px !important;
//     margin-right: 20px !important;
//     margin-left: 70px !important;
//     margin-bottom: 20px !important;
//     padding-bottom: 6px !important;
//     border-color:#e5e5e5 !important;
//     border-width:1px !important;
//     border-style: solid !important;
//     border-radius: 10px 10px 10px 10px !important
//  }
 
//  .custom-subtask-name {
//     font-size: 14px !important;
//     color: #2e4e9f !important;
//     font-weight: 700 !important;
//     background-color: #eef0f4 !important;
//     padding: 10px 6px 6px 10px !important;
//     margin-bottom:4px !important;
//  }

.custom-status-pending {
    background-color: #b70a27 !important;
    padding: 4px 6px 6px 4px !important;
    border-radius: 4px 4px 4px 4px !important;
    color:#ffffff !important;
}

.custom-status-started {
    background-color: #32b1ec !important;
    padding: 4px 6px 6px 4px !important;
    border-radius: 4px 4px 4px 4px !important;
    color:#ffffff !important;
}

.custom-status-complete {
    background-color: #2ac11e !important;
    padding: 4px 6px 6px 4px !important;
    border-radius: 4px 4px 4px 4px !important;
    color:#ffffff !important;
}

.custom-status-on-hold {
    background-color: #f19430 !important;
    padding: 4px 6px 6px 4px !important;
    border-radius: 4px 4px 4px 4px !important;
    color:#ffffff !important;
}

// .custom-add-task-btn {
//     border-radius: 20px 20px 20px 20px !important;
// }

// .custom-add-subtask-btn {
//     margin-top:20px !important;
//     margin-left:10px !important;
//     border-radius: 20px 20px 20px 20px !important;
// }
html, body {
    height: 100%;
    margin: 0;
    padding: 0;
  }
.containergantt {
    height: 960px;
    width: 100%;
  }
  .left-container {
    overflow: hidden;
    position: relative;
    height: 100%;
  }
.right-container {
    border-right: 1px solid #cecece;
    float: right;
    height: 100%;
    width: 340px;
    box-shadow: 0 0 5px 2px #aaa;
    position: relative;
    z-index:2;
  }
  .gantt-messages {
    list-style-type: none;
    height: 50%;
    margin: 0;
    overflow-x: hidden;
    overflow-y: auto;
    padding-left: 5px;
  }
  .gantt-messages > .gantt-message {
    background-color: #f4f4f4;
    box-shadow:inset 5px 0 #d69000;
    font-family: Geneva, Arial, Helvetica, sans-serif;
    font-size: 14px;
    margin: 5px 0;
    padding: 8px 0 8px 10px;
  }
</style>