<template>
  <v-container>
    <v-card
      class="mx-auto my-3"
    >
      <v-card-title style="background: #e3e3e4 !important;">
        <small>
          <span style="position: relative; top: 5px;" class="material-icons-outlined mr-1">
            book
          </span>
            WORKER LOGS

        </small>
      </v-card-title>

      <v-card-text>
        <v-row dense>
            <v-col
                cols="12"
                md="6"
                class="mt-5"
            >
                <v-menu
                    v-model="menu2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                    <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="date_from"
                        label="From"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                    ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="date_from"
                        @input="menu2 = false"
                    ></v-date-picker>
                </v-menu>
            </v-col>
            <v-col
                cols="12"
                md="6"
                class="mt-5"
            >
                <v-menu
                    v-model="menu3"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                    <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="date_to"
                        label="To"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                    ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="date_to"
                        @input="menu3 = false"
                    ></v-date-picker>
                </v-menu>
            </v-col>
            <v-col cols="12">
                <v-btn
                    block
                    color="primary"
                    elevation="2"
                    class="mb-2"
                    @click="fetchWorkerLogsInformation()"
                >Search</v-btn>
            </v-col>

            <v-col cols="12">
                <v-data-table
                    :headers="headers"
                    :items="worker_logs"
                    disable-sort
                >
                <template v-slot:item.delete="props">
                    <v-btn
                        v-if="role == 1"
                        icon
                        @click="OpenDeleteLogDialog(props.item.id, props.item.item_no, props.index)"
                    >
                        <v-icon small>mdi-delete</v-icon>
                    </v-btn>
                </template>
                <template v-slot:item.date="props">
                    {{ props.item.created_at }}
                </template>
                <template v-slot:item.name="props">
                    {{ props.item.get_user_info.name }}
                </template>
                <template v-if="role == 1" v-slot:item.hours_completed="props">
                    <v-edit-dialog
                        :return-value.sync="props.item.hours_completed"
                        large
                        @save="UpdateDailyLogsInformation(props.item.id, 'hours_completed', props.item.hours_completed, props.item.item_no)"
                    >
                        <div>{{ props.item.hours_completed }}</div>
                        <template v-slot:input>
                            <div class="mt-4 text-h6">
                            Update Hours Completed
                            </div>
                            <v-text-field
                                v-model="props.item.hours_completed"
                                label="Edit"
                                single-line
                                autofocus
                            ></v-text-field>
                        </template>
                    </v-edit-dialog>
                </template>
                <template v-else v-slot:item.hours_completed="props">
                    {{ props.item.hours_completed }}
                </template>
                <template v-if="role == 1" v-slot:item.item_name="props">
                    <v-edit-dialog
                        large
                        @save="UpdateDailyLogsItem(props.item.id, 'item_name', props.item.item_name.name, props.item.item_name.item)"
                    >
                        <div v-if="props.item.item_name.name == null">{{ props.item.item_name }}</div>
                        <div v-else>{{ props.item.item_name.name }}</div>
                        <template v-slot:input>
                            <div class="mt-4 text-h6">
                            Update Item
                            </div>
                            <v-autocomplete
                                v-model="props.item.item_name"
                                :items="allItemDescription"
                                item-text="name"
                                item-value="item"
                                return-object
                                label="Type to search"
                                single-line
                                autofocus
                            >
                            </v-autocomplete>
                        </template>
                    </v-edit-dialog>
                </template>
                <template v-else v-slot:item.item_name="props">
                    <div>{{ props.item.item_name }}</div>
                </template>
                <template v-if="role == 1" v-slot:item.materials="props">
                    <div v-for="val in props.item.get_child" :key="val.id">
                        <v-edit-dialog
                            :return-value.sync="val.material_name"
                            large
                            @save="UpdateDailyLogsInformation(val.id, 'material_name', val.material_name, props.item.item_no)"

                        >
                            • {{ val.material_name }}:
                        <template v-slot:input>
                            <div class="mt-4 text-h6">
                            Update Material
                            </div>
                            <v-text-field
                            v-model="val.material_name"
                            label="edit"
                            single-line
                            counter
                            autofocus
                            ></v-text-field>
                        </template>
                        </v-edit-dialog>
                        <v-edit-dialog
                            :return-value.sync="val.material_price"
                            large
                            @save="UpdateDailyLogsInformation(val.id, 'material_price', val.material_price, props.item.item_no)"

                        >
                            €{{ val.material_price | formatNumber }}
                        <template v-slot:input>
                            <div class="mt-4 text-h6">
                            Update Price
                            </div>
                            <v-text-field
                            v-model="val.material_price"
                            label="edit"
                            single-line
                            counter
                            autofocus
                            ></v-text-field>
                        </template>
                        </v-edit-dialog>
                    </div>
                </template>
                <template v-else v-slot:item.materials="props">
                    <div v-for="val in props.item.get_child" :key="val.id">
                        • {{ val.material_name }}:
                        <br>
                        €{{ val.material_price | formatNumber }}
                    </div>
                </template>
                <template v-slot:item.images="props">
                    <v-dialog
                        v-model="props.item.show_img"
                        width="500"
                        scrollable
                        persistent
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                v-bind="attrs"
                                v-on="on"
                                text
                                color="primary"
                            >
                            Show
                            </v-btn>
                        </template>
                        <v-card>
                            <v-card-title class="text-h5 grey lighten-2">
                                Images
                            </v-card-title>
                            <v-divider></v-divider>
                            <v-card-text>
                                <div class="mb-5">
                                    <v-file-input
                                        v-if="role == 1"
                                        show-size
                                        multiple
                                        truncate-length="15"
                                        placeholder="Add Images"
                                        v-model="upload_images"
                                    ></v-file-input>
                                    <v-btn
                                        v-if="upload_images.length != 0"
                                        @click="AddImage(props.item.id, props.index)"
                                        depressed
                                        class="ms-2"
                                        color="grey lighten-2"
                                    >
                                        Save
                                    </v-btn>
                                </div>
                                <div v-for="(img, index) in props.item.images" :key="img.filepath">
                                    <v-card outlined class="mb-5">
                                        <v-btn
                                            v-if="role == 1"
                                            icon
                                            @click="OpenDeleteImageDialog(props.item.id, index, props.index)"
                                        >
                                            <v-icon>mdi-delete</v-icon>
                                        </v-btn>
                                        <v-img
                                            contain
                                            :src="`${url}/${img.filepath}`" 
                                        ></v-img>
                                    </v-card>
                                </div>
                            </v-card-text>

                            <v-divider></v-divider>

                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                    color="primary"
                                    text
                                    @click="props.item.show_img = false"
                                >
                                    Close
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </template>
                <template v-if="role == 1" v-slot:item.remarks="props">
                    <v-edit-dialog
                    :return-value.sync="props.item.remarks"
                    large
                    @save="UpdateDailyLogsInformation(props.item.id, 'remarks', props.item.remarks, props.item.item_no)"
                    >
                        <div>{{ props.item.remarks }}</div>
                        <template v-slot:input>
                            <div class="mt-4 text-h6">
                            Update Remarks
                            </div>
                            <v-text-field
                            v-model="props.item.remarks"
                            label="Edit"
                            single-line
                            counter
                            autofocus
                            ></v-text-field>
                        </template>
                    </v-edit-dialog>
                </template>
                <template v-else v-slot:item.remarks="props">
                    {{ props.item.remarks }}
                </template>
                </v-data-table>

            </v-col>
            <v-col cols="12">
                <v-btn
                    class="mx-2"
                    fab
                    dark
                    small
                    color="primary"
                    style="float: right;"
                    @click="ExportData()"
                >
                    <v-icon dark>
                        mdi-file-export
                    </v-icon>
                </v-btn>
                <!-- <a :href="`${api_url}workerlogs/export`" download="asds" target="_blank">
                        click me
                </a> -->
            </v-col>

        </v-row>
      </v-card-text>

    </v-card>

    <!-- <v-tour name="myTour" :steps="steps"></v-tour> -->

    <v-dialog v-model="delete_image_dialog" max-width="450px">
      <v-card>
        <v-card-title class="text-h6 text-center">Are you sure you want to delete this image?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="CloseDeleteImageDialog">Cancel</v-btn>
          <v-btn color="blue darken-1" text @click="DeleteImage(delete_image_info[0], delete_image_info[1], delete_image_info[2])">OK</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="delete_log_dialog" max-width="450px">
        <v-card>
        <v-card-title class="text-h6 text-center">Are you sure you want to delete this log?</v-card-title>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="CloseDeleteLogDialog">Cancel</v-btn>
            <v-btn color="blue darken-1" text @click="DeleteLog(log_to_delete[0], log_to_delete[1], log_to_delete[2])">OK</v-btn>
            <v-spacer></v-spacer>
        </v-card-actions>
        </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
// import { mapGetters } from 'vuex';
  export default {
    data: () => ({
        userId: null,
        role: 0,
        urlParams: 'empty',
        pagination: {},
        headers: [
        //   {
        //     text: 'Dessert (100g serving)',
        //     align: 'start',
        //     sortable: false,
        //     value: 'name',
        //   },
            { text: '', value: 'delete' },
            { text: 'Date', value: 'date' },
            { text: 'Name', value: 'name' },
            { text: 'Hours Completed', value: 'hours_completed' },
            { text: 'Item Completed', value: 'item_name' },
            { text: 'Materials', value: 'materials', width: '20%' },
            { text: 'Images', value: 'images' },
            { text: 'Remarks', value: 'remarks' },
        ],
        worker_logs: [],
        date_from: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        date_to: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        menu2: false,
        menu3: false,
        delete_image_dialog: false,
        delete_image_info: [],
        delete_log_dialog: false,
        log_to_delete: [],
        allItemNo: [],
        allItemDescription: null,
        upload_images: [],
        images: [],
        url: "",
        api_url: ""
    }),
    mounted () {
      // if(localStorage.getItem('introBudgetManager') === null){
      //   this.$tours['myTour'].start()
      //   localStorage.setItem('introBudgetManager', true)
      // }
    },
    created () {
        if(localStorage.getItem("token") === null){
            this.$router.push({name: 'index'});
            return
        }
        if(localStorage.getItem('project_id') !== null){
            this.urlParams = localStorage.getItem('project_id');
        }
        this.userId = localStorage.getItem('id');
        this.role = localStorage.getItem('role')
        this.$store.dispatch('setDrawer', true);
        this.fetchWorkerLogsInformation();
        this.fetchAllItems();
        this.url = process.env.VUE_APP_URL;
    },
    watch: {

    },
    computed: {

    },
    methods: {
        showSnackBar(message){
            this.$store.commit("setMessage", 
                {
                    show: true, 
                    message: message
                }, 
                {
                    root: 1
                }
            )
        },
        fetchAllItems(){
            let payload = {
                project_id: this.urlParams
            }
            this.$axios.get('/costestimation/allitems', payload)
            .then(({data}) => {
                this.allItemDescription = data
            })
        },
        fetchWorkerLogsInformation(){
            let payload = {
                project_id: this.urlParams,
                date_from: this.date_from,
                date_to: this.date_to
            }
            this.$axios.get('/workerlogs/projectworkerlogs', payload)
            .then(({data}) => {
                if(data.success){
                    console.log(data)
                    this.worker_logs = data.result
                }
            })
        },
        ExportData(){
            let payload = {
                project_id: this.urlParams,
                date_from: this.date_from,
                date_to: this.date_to
            };

            this.$axios.download('/workerlogs/export', payload)
            .then(({data}) => {
                const url = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'workerlogs.xlsx'); //or any other extension
                document.body.appendChild(link);
                link.click();
            });
        },
        UpdateDailyLogsInformation(id, name, value, item_no){
            let payload = {
                id: id,
                name: name,
                value: value,
                item_no: item_no,
                project_id: this.urlParams,
            };
            this.$axios.post('/workerlogs/updatedailylogsinformation', payload)
            .then(({data}) => {
                this.showSnackBar(data.message)
            });
        },
        UpdateDailyLogsItem(id, name, value, item_no){
            let payload = {
                id: id,
                name: name,
                value: value,
                item_no: item_no,
                project_id: this.urlParams,
            };
            this.$axios.post('/workerlogs/updatedailylogsinformation', payload)
            .then(({data}) => {
                this.fetchWorkerLogsInformation()
                this.showSnackBar(data.message)
            });
        },
        AddImage($id, $index){
            const formData = new FormData();
            formData.append('id', $id)
            for(let i=0;i<this.upload_images.length;i++){
                formData.append('images_' + i, this.upload_images[i]);
            }
            formData.append('images_count', this.upload_images.length)
            this.$axios.post('/workerlogs/update/images', formData)
            .then(({data}) => {
                //this.fetchWorkerLogsInformation()
                this.showSnackBar(data.message)
                if(data.success){
                    for(let i = 0; i < data.newimages.length; i++){
                        this.worker_logs[$index].images.push(data.newimages[i])
                    }
                    this.upload_images = []
                }
            })
        },
        OpenDeleteImageDialog($id, $imageIndex, $index){
            this.delete_image_info = [$id, $imageIndex, $index]
            this.delete_image_dialog = true
        },
        CloseDeleteImageDialog(){
            this.delete_image_dialog = false
            this.delete_image_info = []
        },
        DeleteImage($id, $imageIndex, $index){
            this.delete_image_dialog = false
            this.delete_image_info = []
            let payload = {
                id: $id,
                imageIndex: $imageIndex,
            }
            this.$axios.post('/workerlogs/delete/images', payload)
            .then(({data}) => {
                //this.fetchWorkerLogsInformation()
                this.worker_logs[$index].images.splice($imageIndex, 1)
                this.showSnackBar(data.message)
            })
        },
        OpenDeleteLogDialog($id, $item, $index){
            this.log_to_delete = [$id, $item, $index]
            this.delete_log_dialog = true
        },
        CloseDeleteLogDialog(){
            this.delete_log_dialog = false
            this.log_to_delete = []
        },
        DeleteLog(){
            let payload = {
                project_id: this.urlParams,
                id: this.log_to_delete[0],
                item_no: this.log_to_delete[1],
                index: this.log_to_delete[2],
            }
            this.$axios.delete('/workerlogs/delete/logs', {data: payload})
            .then(() =>{
                this.delete_log_dialog = false
                this.log_to_delete = []
                this.worker_logs.splice(payload.index, 1)
                //this.fetchWorkerLogsInformation()
            })
        },
    }
}
</script>
<style scoped lang="scss">
// css part para sa page na ito

</style>