<template>
  <v-container fluid>
    <v-card
      class="mx-auto my-12"
    >
      <v-card-title
        style="background: #e3e3e4 !important;"
      >
        <small><span style="position: relative; top: 5px;" class="material-icons-outlined mr-1">ballot</span>Project Planner</small>
      </v-card-title>
      <!-- <v-col
        cols="12"
        lg="12"
        md="12"
        sm="12"
        class="mt-3"
      >
        <v-select
          outlined
          dense
          :items="systemTemplates"
          label="Grab A Template"
          @change="getSystemTemplate"
          style="width: 300px;"
        ></v-select>
      </v-col> -->
      <v-card-text
        v-if="!showtemplate"
        class="elevation-1"
      >
        <!--<v-row
          no-gutters
        >
          <v-col
            cols="2"
            lg="2"
            md="2"
            sm="12"
            v-if="!showtemplate"
          >
            <v-btn
              color="#062a82"
              elevation="0"
              class="mb-1 pl-5 pr-5 rounded-lg white--text"
              @click="dialogAddParent = true"
            >
              <span class="material-icons-outlined mr-1">
                add
              </span> Add Section
            </v-btn>
          </v-col>
        </v-row>-->
        <div id="pPTable"></div>
        <v-row v-if="!showtemplate">
          <div class="containergantt my-8 px-2">
            <gantt class="left-container"></gantt>
          </div>
          <!-- TODO HERE -->
          <!--<treeview :urlParams="urlParams" />-->
          <!--<v-card
              class="mx-auto"
              tile
            >
              <v-list>
                <v-list-group
                  v-for="item in getParentData"
                  :key="item.title"
                  v-model="item.active"
                  no-action
                  @click="childData(item)"
                >
                  <template v-slot:activator>
                    <v-list-item-content>
                      <v-list-item-title style="color: #0275d8;">
                        <v-badge
                          color="#0924cb"
                          bordered
                        >
                          <span slot="badge">{{item.count}}</span>
                          <span slot="default">{{item.task_name}}</span>
                        </v-badge>
                      </v-list-item-title>
                    </v-list-item-content>
                  </template>
                  <v-sheet class="pa-4 lighten-1" color="#062a82">
                    <v-row>
                      <v-btn
                        block
                        class="custom-add-subtask-btn mt-5"
                        text
                        dark
                        @click="onParentRowClick(item)"
                      >
                        <span class="material-icons-outlined mr-1">edit</span> Edit Section
                      </v-btn>
                      <v-col
                        class="d-flex"
                        cols="12"
                        lg="12"
                        md="12"
                        sm="4"
                      >
                        <v-text-field
                          class="mt-3"
                          label="Add Task"
                          v-model="add_task_name"
                          placeholder="Add Task"
                          dark
                          standard
                        ></v-text-field>
                        <v-btn
                          class="custom-add-subtask-btn mt-5"
                          cols="6"
                          sm="2"
                          text
                          dark
                          @click="addSubTask(item)"
                        >
                          <span class="material-icons-outlined">save</span> Save
                        </v-btn>
                      </v-col>
                    </v-row>
                    <v-text-field
                      class="custom-search"
                      lg="12"
                      md="12"
                      sm="6"
                      dark
                      v-model="search"
                      clearable
                      label="Search In Task"
                      hide-details
                    ></v-text-field>
                  </v-sheet>
                  <v-data-table
                    :headers="headers"
                    :items="getChildData"
                    item-key="task_name"
                    class="elevation-1 custom-table"
                    style="font-weight:700;"
                    :search="search"
                    :hide-default-footer="true"
                    disable-pagination
                    mobile-breakpoint="0">
                    <template v-slot:item="{item}">
                      <tr>
                        <td style="cursor: pointer; white-space: pre-line;" @click="editChild(item)"><label>{{item['task_name']}}</label></td>
                        <td style="cursor: pointer;" @click="editChild(item)">
                          <label style="cursor: pointer;" v-if="item['duration'] === '' || item['duration'] === null"> 
                            Need duration
                          </label>
                          <label style="cursor: pointer;" v-else>
                            {{item['duration']}} Days
                          </label>
                        </td>
                        <td style="cursor: pointer;" @click="editChild(item)">
                          <label v-if="item['status'] === 'Pending'" style="color: red !important; cursor: pointer;">
                            <i>{{item['status']}}</i>
                          </label>
                          <label v-if="item['status'] === 'Started'" style="color: green !important; cursor: pointer;">
                            <i>{{item['status']}}</i>
                          </label>
                          <label v-if="item['status'] === 'On-Hold'" style="color: red !important; cursor: pointer;">
                            <i>{{item['status']}}</i>
                          </label>
                          <label v-if="item['status'] === 'Waiting-on Info'" style="color: orange !important; cursor: pointer;">
                            <i>{{item['status']}}</i>
                          </label>
                          <label v-if="item['status'] === 'Complete'" style="color: green !important; cursor: pointer;">
                            <i>{{item['status']}}</i>
                          </label>
                        </td>
                        <td style="cursor: pointer;" @click="editChild(item)">{{item['start'] | formatDate}}</td>
                        <td style="cursor: pointer;" @click="editChild(item)">{{item['finish'] | formatDate}}</td>
                        <td style="cursor: pointer;" @click="editChild(item)">{{item['notes']}}</td>
                      </tr>
                    </template>
                  </v-data-table>
                </v-list-group>
              </v-list>
            </v-card>-->
        </v-row>
      </v-card-text>
      <v-container
        v-if="showtemplate"
      >
        <v-btn
          v-if="showGrab"
          class="white--text mr-2"
          color="#062a82"
          cols="12"
          sm="12"
          @click="dialogGrabTemplate = true"
        >
          Grab Template
        </v-btn>
        <v-btn
          v-if="showGrab"
          cols="12"
          sm="12"
          text
          @click="showtemplate = false"
        >
          Cancel
        </v-btn>
        <v-card-text>
          <v-card>
            <v-list>
              <v-list-group
                v-for="item in getSysTemplate"
                :key="item.title"
                v-model="item.active"
                no-action
              >
                <template v-slot:activator>
                  <v-list-item-content>
                    <v-list-item-title @click="childData(item)" style="color: #0275d8;"><strong>{{item.task_name}}</strong></v-list-item-title>
                  </v-list-item-content>
                </template>
                <v-data-table
                  :headers="headers"
                  :items="getChildData"
                  item-key="task_name"
                  class="elevation-1 custom-table"
                  style="font-weight:700;"
                  :search="search"
                  :hide-default-footer="true"
                  disable-pagination
                  mobile-breakpoint="0">
                  <template v-slot:item="{item}">
                    <tr>
                      <td style="cursor: pointer; white-space: pre-line;"><label>{{item['task_name']}}</label></td>
                      <td style="cursor: pointer;">
                        <label v-if="item['duration'] === '' || item['duration'] === null"> 
                          Need duration
                        </label>
                        <label v-else>
                          {{item['duration']}} Days
                        </label>
                      </td>
                      <td style="cursor: pointer;">
                        <label v-if="item['status'] === 'Pending'" style="color: red !important;">
                          <i>{{item['status']}}</i>
                        </label>
                        <label v-if="item['status'] === 'Started'" style="color: green !important;">
                          <i>{{item['status']}}</i>
                        </label>
                        <label v-if="item['status'] === 'On-Hold'" style="color: red !important;">
                          <i>{{item['status']}}</i>
                        </label>
                        <label v-if="item['status'] === 'Waiting-on Info'" style="color: orange !important;">
                          <i>{{item['status']}}</i>
                        </label>
                        <label v-if="item['status'] === 'Complete'" style="color: green !important;">
                          <i>{{item['status']}}</i>
                        </label>
                      </td>
                      <td style="cursor: pointer;">{{item['start'] | formatDate}}</td>
                      <td style="cursor: pointer;">{{item['finish'] | formatDate}}</td>
                      <td style="cursor: pointer;">{{item['notes']}}</td>
                    </tr>
                  </template>
                </v-data-table>
              </v-list-group>
            </v-list>
          </v-card>
        </v-card-text>
      </v-container>
      <!-- </v-tabs-items> -->
      <!-- edit modal -->
      <v-dialog
        v-model="dialogEditParent"
        hide-overlay
        persistent
        transition="dialog-bottom-transition"
      >
        <v-card>
          <v-toolbar
            dark
            color="#062a82"
          >
            <v-btn
              icon
              dark
              @click="closeDialogEdit"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title><small>Edit Item</small></v-toolbar-title>
          </v-toolbar>
          <v-list
            three-line
            subheader
          >
            <v-list-item>
              <v-list-item-content>
                <v-text-field
                  filled
                  label="Task Name"
                  v-model="toEditParentData.task_name"
                  :placeholder="toEditParentData.task_name"
                  standard
                ></v-text-field>
              </v-list-item-content>
            </v-list-item>
            <!-- notes end -->
          </v-list>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              light
              text
              @click="saveEdited"
            >
              <span class="material-icons-outlined mr-1">save</span> Save
            </v-btn>
            <v-btn
              light
              text
              @click="dialogDelete = true"
            >
              <span class="material-icons-outlined mr-1">delete</span> Delete
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- end edit modal -->

      <!-- edit child modal -->
      <v-dialog
        v-model="dialogEditChild"
        hide-overlay
        persistent
        transition="dialog-bottom-transition"
      >
        <v-card>
          <v-toolbar
            dark
            color="#062a82"
          >
            <v-btn
              icon
              dark
              @click="closeDialogEdit"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title><small>Edit Item</small></v-toolbar-title>
          </v-toolbar>
          <v-list
            three-line
            subheader
          >
            <v-list-item>
              <v-list-item-content>
                <v-text-field
                  filled
                  label="Task Name"
                  v-model="toEditParentData.task_name"
                  :placeholder="toEditParentData.task_name"
                  standard
                ></v-text-field>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-text-field
                  filled
                  label="Duration"
                  v-model="toEditParentData.duration"
                  :placeholder="toEditParentData.duration"
                  standard
                ></v-text-field>
              </v-list-item-content>
            </v-list-item>
            <!-- start -->
            <v-list-item>
              <v-list-item-content>
                <v-menu
                  v-model="startMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      filled
                      v-model="toEditParentData.start"
                      label="Start Date"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="toEditParentData.start"
                    @input="startMenu = false"
                  ></v-date-picker>
                </v-menu>
              </v-list-item-content>
            </v-list-item>
            <!-- end start -->
            <!-- finish -->
            <v-list-item>
              <v-list-item-content>
                <v-menu
                  v-model="finishMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      filled
                      v-model="toEditParentData.finish"
                      label="Finish Date"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="toEditParentData.finish"
                    @input="finishMenu = false"
                  ></v-date-picker>
                </v-menu>
              </v-list-item-content>
            </v-list-item>
            <!-- end finish -->
            <!-- status dropdown -->
              <v-list-item>
                <v-list-item-content>
                  <v-select
                    filled
                    :items="statusUpdate"
                    label="Status"
                    v-model="toEditParentData.status"
                    @change="setStatus"
                    dense
                  ></v-select>
                </v-list-item-content>
              </v-list-item>
              <!-- status dropdown end -->
              <!-- who input -->
              <v-list-item>
              <v-list-item-content>
                <v-text-field
                  filled
                  label="Who"
                  v-model="toEditParentData.who"
                  :placeholder="toEditParentData.who"
                  standard
                ></v-text-field>
              </v-list-item-content>
            </v-list-item>
              <!-- end who input -->
              <!-- notes start -->
            <v-list-item>
              <v-list-item-content>
                  <v-textarea
                    filled
                    autocomplete="email"
                    label="Notes"
                    outlined
                    v-model="toEditParentData.notes"
                  ></v-textarea>
              </v-list-item-content>
            </v-list-item>
            <!-- notes end -->
          </v-list>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              light
              text
              @click="dialogDeleteChild = true"
            >
              <span class="material-icons-outlined">delete</span> Delete
            </v-btn>
            <v-btn
              dark
              @click="saveEditedChild"
              class="pl-8 pr-8"
            >
              <span class="material-icons-outlined mr-1">save</span> Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- end child edit modal -->

      <!-- delete child modal -->
      <v-dialog
        v-model="dialogDeleteChild"
        width="250"
        transition="dialog-bottom-transition"
      >
        <v-card>
          <v-toolbar
            dark
            color="#062a82"
          >
            <v-btn
              icon
              dark
              @click="dialogDeleteChild = false;"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Delete!</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn
                dark
                text
                @click="deleteChild"
              >
                <span class="material-icons-outlined">delete</span>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <v-row
            justify="center"
            align="center"
            no-gutters
          >
            <v-col
              class="text-center"
              cols="12"
              sm="12"
              md="12"
            >
              <v-card-text>Are you sure you want to delete this entry?<br/><strong>{{toEditParentData.task_name}}</strong></v-card-text>
            </v-col>
          </v-row>
        </v-card>
      </v-dialog>
      <!-- end child delete modal -->

      <!-- delete modal -->
      <v-dialog
        v-model="dialogDelete"
        width="250"
        transition="dialog-bottom-transition"
      >
        <v-card>
          <v-toolbar
            dark
            color="#062a82"
          >
            <v-btn
              icon
              dark
              @click="dialogDelete = false;"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Delete!</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn
                dark
                text
                @click="deleteParent"
              >
                <span class="material-icons-outlined">delete</span>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <v-row
            justify="center"
            align="center"
            no-gutters
          >
            <v-col
              class="text-center"
              cols="12"
              sm="12"
              md="12"
            >
              <v-card-text>Are you sure you want to delete this entry?<br/><strong>{{toEditParentData.task_name}}</strong></v-card-text>
            </v-col>
          </v-row>
        </v-card>
      </v-dialog>
      <!-- end delete modal -->
      <!-- add parent task modal -->
      <v-dialog
        v-model="dialogAddParent"
        hide-overlay
        transition="dialog-bottom-transition"
      >
        <v-card>
          <v-toolbar
            dark
            color="#062a82"
          >
            <v-btn
              icon
              dark
              @click="dialogAddParent = false;"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title><small>Add Section</small></v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-list
            three-line
            subheader
          >
            <v-list-item>
              <v-list-item-content>
                <v-text-field
                  filled
                  label="Section"
                  v-model="addParentTask.task_name"
                  placeholder="Section"
                  standard
                ></v-text-field>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              dark
              class="pl-8 pr-8"
              @click="saveParentItem"
            >
              <span class="material-icons-outlined mr-1">save</span> Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- end parent task modal -->
      <!-- grab template modal -->
      <v-dialog
        v-model="dialogGrabTemplate"
        transition="dialog-bottom-transition"
      >
        <v-card>
          <v-toolbar
            dark
            color="#062a82"
          >
            <v-btn
              icon
              dark
              @click="dialogGrabTemplate = false;"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Grab Template</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn
                dark
                text
                @click="grabTemplate"
              >
                <span class="material-icons-outlined">save</span>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <v-list
            three-line
            subheader
          >
            <v-list-item>
              <v-list-item-content>
                <v-text-field
                  name="input-7-1"
                  label="Template Name"
                  v-model="templateName"
                  placeholder="Template Name"
                ></v-text-field>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-dialog>
      <!-- end grab template modal -->
    </v-card>
    <!--<v-tour name="myTour" :steps="steps"></v-tour>-->
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
//import treeview from '../subcomponents/projectplanner.vue'
import Gantt from './GanttChart.vue'
export default {
  components: {
    //treeview
    Gantt
  },
  props: [
  ],
  data () {
    return {
      tasks: {
        data: [
          //{id: 1, text: 'Task #1', start_date: '2022-02-17', duration: 3, progress: 0.6},
          //{id:  3, text: "test 2", parent: 1, progress: 0, start_date: '2022-02-17', end_date: '2022-02-18', duration: 1},
          //{id: 2, text: 'Task #2', start_date: '2022-02-20', duration: 3, progress: 0.4}
        ],
        links: [
          //{id: 1, source: 1, target: 2, type: '0'},
          //{id: 2, source: 3, target: 1, type: '0'}
        ]
      },
      messages: [],
      showtemplate: false,
      tab: null,
      userId: null,
      showAdd: false,
      showGrab: false,
      expanded: [],
      search: '',
      searchSection: '',
      hover: false,
      headers: [
        { text: 'Task Name', filterable: true, value: 'task_name', class: 'headerColorToBlue '},
        { text: 'Duration', filterable: true, value: 'duration'},
        { text: 'Status', filterable: true, value: 'status'},
        { text: 'Start', filterable: true, value: 'start'},
        { text: 'Finish', filterable: true, value: 'finish'},
        { text: 'Notes', filterable: true, value: 'notes'},
        // { text: 'Media', width: '15%', filterable: false}
      ],
      headersChild: [
        { text: '', width: '5%'},
        { text: 'Task Name', width: '38%', value: 'task_name'},
        { text: 'Status', width: '29%', value: 'status'},
        { text: 'Notes', width: '30%', value: 'notes'},
      ],
      dialogEditParent: false,
      dialogEditChild: false,
      dialogDeleteChild: false,
      houseName: '',
      toEditParentData: {},
      dateStart: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      dateFinish: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      startMenu: false,
      finishMenu: false,
      startMenuAdd: false,
      finishMenuAdd: false,
      statusUpdate: ["Pending", "Started", "On-Hold", "Waiting-on Info", "Complete"],
      statusEdit: '',
      notesEdit: '',
      // delete start
      dialogDelete: false,
      // end delete
      // add parent task
      dialogAddParent: false,
      // end add parent task
      // add item
      addParentTask: {
        task_name: '',
        duration: '',
        start: '',
        finish: '',
        who: '',
        notes: '',
        status: ''
      },
      sChild: false,
      systemTemp: '',
      templateName: '',
      dialogGrabTemplate: false,
      add_task_name: '',
      searchChildText: '',
      childIdForCancel: null,
      steps: [
        {
          target: '#systemTemplatesButton',
          content: 'You can find our Typical "Construction Schedule" here, or create your own.'
        },
        {
          target: '#chooseATemplate',
          content: 'You can find your uploaded templates here.'
        },
      ],
      urlParams: 'empty',
    }
  },
  mounted () {
    /*if(localStorage.getItem('introPP') === null){
      this.$tours['myTour'].start()
      localStorage.setItem('introPP', true)
    }*/
    this.getMyTemplate();
  },
  created () {
    if(localStorage.getItem("token") === null){
      this.$router.push({name: 'index'})
      return
    }
    if(localStorage.getItem('project_id') !== null){
      this.urlParams = localStorage.getItem('project_id')
    }else{
      this.tab = 'tab-2'
    }
    this.userId = localStorage.getItem('id')
  },
  watch: {
    reloadFn(data){
      if(data){
        this.urlParams = localStorage.getItem('project_id')
        return this.getMyTemplate()
      }
    }
  },
  computed: {
    getChildData(){
      return this.$store.getters['getChildData']
    },
    getParentData(){
      return this.$store.getters['getpp']
    },
    ...mapGetters({
      dropDownInfo:         'getDropDown',
      systemTemplates:      'getSystemTemplates',
      getSysTemplate:       'getParentSysTemp',
      //getprojectcalendar:   'getprojectcalendar'
      //projectplanner:       'getpp'
    }),
    reloadFn(){
      return this.$store.getters['getReload']
    }
  },
  methods: {
    addMessage (message) {
      this.messages.unshift(message)
      if (this.messages.length > 40) {
        this.messages.pop()
      }
    },
 
    logTaskUpdate (id, mode, task) {
      let text = (task && task.text ? ` (${task.text})`: '')
      let message = `Task ${mode}: ${id} ${text}`
      this.addMessage(message)
    },
 
    logLinkUpdate (id, mode, link) {
      let message = `Link ${mode}: ${id}`
      if (link) {
        message += ` ( source: ${link.source}, target: ${link.target} )`
      }
      this.addMessage(message)
    },
    setStatus(data){
      this.statusEdit = data
    },
    async saveEdited(){
      this.toEditParentData.status = this.statusEdit
      this.toEditParentData.house = this.houseName
      const data = this.toEditParentData
      await this.$axios.patch(`editTemplate/${this.urlParams}`, data)
      .then(({data}) => {
        if(data.response){
          this.$store.commit("setMessage", 
          {show: true, message: data.message}, 
          {root: 1})
          this.dialogEditParent = false
          // this.$store.dispatch('setChildData', data.house)
          this.$store.dispatch('setMyParentProjectPlan', data.data)
          return
        }
      });
    },
    async saveEditedChild(){
      this.toEditParentData.status = this.statusEdit
      this.toEditParentData.house = this.houseName
      const data = this.toEditParentData
      await this.$axios.patch(`editChild/${this.urlParams}`, data)
      .then(({data}) => {
        if(data.response){
          this.$store.commit("setMessage", 
          {show: true, message: data.message}, 
          {root: 1})
          this.dialogEditChild = false
          this.$store.dispatch('setChildData', data.house)
          return
        }
      });
    },
    async getMyTemplate(){
    // async getMyTemplate(data){
      // this.houseName = data
      // await this.$axios.get(`getThisTemplate/${data}`)
      await this.$axios.get(`getThisTemplate/${this.urlParams}`)
      .then(({data}) => {
        this.showAdd = true
        //this.tasks.data.length = 0
        //data.data.forEach(q => {
        //  this.tasks.data.push(q)
        //})
        //console.log(this.tasks)
        this.$store.dispatch('setpp', data.data)
        this.$store.dispatch('setlinks', data.links)
        //this.$store.dispatch('setprojectcalendar', data.calendar)
        this.$store.dispatch('setDrawer', true)
        this.$store.dispatch('setReload', false)
      }).catch(e => {
        console.log(e)
      });
      await this.$axios.get('getSystemTemplates')
      .then(({data}) => {
        this.$store.dispatch('setSystemTemplates', data.data)
      })
    },
    async getSystemTemplate(data){
      this.showtemplate = true
      this.systemTemp = data
      await this.$axios.get(`gtsystemp/${data}`)
      .then(({data}) => {
        this.showGrab = true
        this.$store.dispatch('setParentSysTemp', data.data)
      })
    },
    onParentRowClick(data){
      this.childIdForCancel = data.predecessor
      this.toEditParentData = data
      this.dialogEditParent = true
    },
    editChild(data){
      // console.log(data)
      this.childIdForCancel = data.predecessor
      this.toEditParentData = data
      this.dialogEditChild = true
    },
    async deleteParent(){
      const data = {
        id: this.toEditParentData.id,
        house: this.houseName
      }
      await this.$axios.delete(`deleteTask/${this.urlParams}`, {data: data})
      .then(({ data }) => {
        this.$store.dispatch('setMyParentProjectPlan', data.data)
        this.$store.dispatch('setMyParentProjectPlan', data.parent)
        this.dialogDelete = false
        this.dialogEditParent = false
      });
    },
    async deleteChild(){
      await this.$axios.delete(`deleteChildTask/${this.urlParams}/${this.toEditParentData.id}`)
      .then(({data}) => {
        this.dialogDeleteChild = false
        this.dialogEditChild = false
        this.$store.dispatch('setChildData', data.data)
      })
    },
    showChild(){
      this.sChild = true
    },
    async saveParentItem(){
      // console.log(this.addParentTask)
      this.addParentTask.house = this.houseName
      await this.$axios.post(`addParentItem/${this.userId}/${this.urlParams}`, this.addParentTask)
      .then(({data}) => {
        if(data.response){
          this.$store.commit("setMessage", 
          {show: true, message: data.message}, 
          {root: 1})
          this.addParentTask = {
            task_name: '',
              duration: '',
              start: '',
              finish: '',
              who: '',
              notes: '',
              status: ''
            }
          this.dialogAddParent = false
          //this.$store.dispatch('setMyParentProjectPlan', data.data)
          this.getMyTemplate()
        }else{
          this.$store.commit("setMessage", 
          {show: true, message: data.message}, 
          {root: 1})
        }
      });
    },
    async childData(child, fromCancel){
      if(fromCancel){
        await this.$axios.get(`getChildData/${child}`)
        .then(({data}) => {
          this.$store.dispatch('setChildData', data.data)
        })
      }else{
        await this.$axios.get(`getChildData/${child.id}`)
        .then(({data}) => {
          if(data.data.length === 0){
            this.$store.dispatch('setChildData', [])
            return
          }
          this.$store.dispatch('setChildData', data.data)
        })
      }
    },
    async grabTemplate(){
      const data = {
        systemTemp: this.systemTemp,
        template_name: this.templateName
      }
      await this.$axios.post('/grabThisTemplate', {data: data})
      .then(({data}) => {
        if(data.response){
          this.dialogGrabTemplate = false
          this.$store.commit('setGrabProjects', true)
        }
      })
    },
    async addSubTask(pData){
      const dataToPass = {
        task: this.add_task_name,
        id: pData.id,
        template_name: pData.template_name
      }
      await this.$axios.post('addSubTask', dataToPass)
      .then(({data}) => {
        if(data.response){
          this.$store.dispatch('setChildData', data.data)
          this.$store.dispatch('setMyParentProjectPlan', data.parent)
          this.add_task_name = ''
          this.$store.commit("setMessage", 
          {show: true, message: data.message}, 
          {root: 1})
        }else{
          this.$store.commit("setMessage", 
          {show: true, message: data.message}, 
          {root: 1})
        }
      })
    },
    closeDialogEdit(){
      // console.log(this.toEditParentData);
      this.dialogEditParent = false;
      this.dialogEditChild = false
      // if(this.childIdForCancel === null){
      //   this.getMyTemplate(this.toEditParentData.template_name)
      // }else{
      //   this.childData(this.childIdForCancel, true)
      // }
    },
  }
}
</script>
<style lang="scss">
// css part para sa page na ito
.v-btn {
  min-width: 0;
}
tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, .02);
}
.v-data-table::v-deep td {
  font-size: 12px !important;
}
.v-select__selection,
.v-select__selection--comma,
.v-select.v-text-field input {
  color: #062a82 !important;
}
.v-data-table-header thead tr th{
  color: #062a82 !important;
  font-weight: 900;
}

.v-data-table-header {
  background-color: #e9e9e9 !important;
  
}

.v-treeview-node__level{
  display: none;
}

// .v-treeview-node__children{
//   display: inline-flex;
//   padding: 8px;
// }

// .elevation-1 mt-5{
//     background-color: #e9e9e9 !important;
// }

// .custom-container{
//     min-height: 700px !important;
// }

// .custom-header{
//     font-size: 30px !important;
//     font-weight: 700 !important;
// }

.v-slide-group__prev {
  display: none !important;
}

.v-slide-group__prev--disabled {
  display: none !important;
}

.custom-tabs {
    align-content: left;
    display: flex;
    width: 100%;
}

.custom-tab-1{
    background-color:#c6d5fc !important;
    border-radius: 12px 12px 0px 0px !important;
    // color: white !important;
    font-weight:900 !important;
}

table.v-table tbody td {
    height: 40px;
    border: none;
}
.theme--light.v-table tbody tr:not(:last-child) {
    border-bottom: none;
}

// .custom-search{
//     background-color: #f2f2f2 !important;
//     border-radius: 10px 10px 10px 10px !important;
//     padding-top: 4px !important;
//     border-color:#828282 !important;
//     border-width:1px !important;
//     border-style: solid !important;
// }

//  .custom-table{
//     border-color:#b1b1b1 !important;
//     border-width:1px !important;
//     border-style: solid !important;
//  }
 
//  .custom-task-name{
//     color: #2e4e9f !important;
//  }

//  .custom-task-name-text{
//     font-size: 14px !important;
//     font-weight: 700 !important;
//     margin-left: 3px !important;
//     margin-top: 4px !important;
//     color: #2e4e9f !important;
//  }
 

 
//  .custom-subtask-item {
//     margin-top: 14px !important;
//     margin-right: 20px !important;
//     margin-left: 70px !important;
//     margin-bottom: 20px !important;
//     padding-bottom: 6px !important;
//     border-color:#e5e5e5 !important;
//     border-width:1px !important;
//     border-style: solid !important;
//     border-radius: 10px 10px 10px 10px !important
//  }
 
//  .custom-subtask-name {
//     font-size: 14px !important;
//     color: #2e4e9f !important;
//     font-weight: 700 !important;
//     background-color: #eef0f4 !important;
//     padding: 10px 6px 6px 10px !important;
//     margin-bottom:4px !important;
//  }

.custom-status-pending {
    background-color: #b70a27 !important;
    padding: 4px 6px 6px 4px !important;
    border-radius: 4px 4px 4px 4px !important;
    color:#ffffff !important;
}

.custom-status-started {
    background-color: #32b1ec !important;
    padding: 4px 6px 6px 4px !important;
    border-radius: 4px 4px 4px 4px !important;
    color:#ffffff !important;
}

.custom-status-complete {
    background-color: #2ac11e !important;
    padding: 4px 6px 6px 4px !important;
    border-radius: 4px 4px 4px 4px !important;
    color:#ffffff !important;
}

.custom-status-on-hold {
    background-color: #f19430 !important;
    padding: 4px 6px 6px 4px !important;
    border-radius: 4px 4px 4px 4px !important;
    color:#ffffff !important;
}

// .custom-add-task-btn {
//     border-radius: 20px 20px 20px 20px !important;
// }

// .custom-add-subtask-btn {
//     margin-top:20px !important;
//     margin-left:10px !important;
//     border-radius: 20px 20px 20px 20px !important;
// }
html, body {
    height: 100%;
    margin: 0;
    padding: 0;
  }
.containergantt {
    height: 960px;
    width: 100%;
  }
  .left-container {
    overflow: hidden;
    position: relative;
    height: 100%;
  }
.right-container {
    border-right: 1px solid #cecece;
    float: right;
    height: 100%;
    width: 340px;
    box-shadow: 0 0 5px 2px #aaa;
    position: relative;
    z-index:2;
  }
  .gantt-messages {
    list-style-type: none;
    height: 50%;
    margin: 0;
    overflow-x: hidden;
    overflow-y: auto;
    padding-left: 5px;
  }
  .gantt-messages > .gantt-message {
    background-color: #f4f4f4;
    box-shadow:inset 5px 0 #d69000;
    font-family: Geneva, Arial, Helvetica, sans-serif;
    font-size: 14px;
    margin: 5px 0;
    padding: 8px 0 8px 10px;
  }
</style>