import Vue from 'vue'
import Vuex from 'vuex'

// import ProjectPlan from './projectplan/projectplan.js'

Vue.use(Vuex)

const store = new Vuex.Store({
    namespaced: true,
    state: {
        snackbar: {
            show: false,
            message: ""
        },
        dropDown: [],
        parentProjectPlan: [],
        pp: {
            data: [],
            links: []
        },
        projectcalendar: [],
        childData: [],
        systemTemplates: [],
        parentSystemTemplate: [],
        budgetManagerData: [],
        budgetTransaction: [],
        siteDiary: [],
        siteDiaryTags: [],
        reminders: [],
        fileManager: [],
        fileManagerImages: [],
        showDrawer: false,
        doneReminders: [],
        costEstimation: [],
        snags: [],
        snagsWho: [],
        doneSnags: [],
        requestData: [],
        quotesData: [],
        project: '',
        reload: false,
        grabProjects: false,
        pName: '',
        notifications: [],
        supplierList: [],
        supplierProfile: [],
        chats: [],
        convo: [],
        grabconvo: false,
        fff: [],
        appliances: [],
        flooringCalculation: [],
        childFFFData: [],
        childApplianceData: [],
        childCalcData: [],
        decision: [],
        decisiondetails: [],
        supplierdesigntracker: [],
        chasetrades: [],
        servicetype: [],
        // builder states
        oteam: {},
        tmembers: [],
        btg: [],
        broles: [],
        client: [],
        previousclients: [],
        r_message: false,
    },
    mutations: {
        SET_DROPDOWN(state, payload){
            state.dropDown = [...payload]
        },
        SET_PARENT_PLAN(state, payload){
            state.parentProjectPlan = [...payload]
        },
        setprojectcalendar(state, v){
            state.projectcalendar = [...v]
        },
        setMessage(state, v) {
            state.snackbar.show = v.show
            state.snackbar.message = v.message
        },
        setDrawer(state, v){
            state.showDrawer = v
        },
        setReload(state, v){
            state.reload = v
        },
        setGrabConvo(state, v){
            state.grabconvo = v
        },
        setGrabProjects(state, v){
            state.grabProjects = v
        },
        setPName(state, v){
            state.pName = v
        },
        setChildData(state, payload){
            state.childData = [...payload]
        },
        setSystemTemplates(state, payload){
            state.systemTemplates = [...payload]
        },
        setParentSystemTemplate(state, payload){
            state.parentSystemTemplate = [...payload]
        },
        setBudgetManagerData(state, payload){
            state.budgetManagerData = [...payload]
        },
        setBudgetTransaction(state, payload){
            state.budgetTransaction = [...payload]
        },
        setSiteDiary(state, payload){
            state.siteDiary = [...payload]
        },
        setSiteDiaryTags(state, payload){
            state.siteDiaryTags = [...payload]
        },
        setReminders(state, payload){
            state.reminders = [...payload]
        },
        setFileManager(state, payload){
            state.fileManager = [...payload]
        },
        setFIleManagerImages(state, payload){
            state.fileManagerImages = [...payload]
        },
        setDoneReminders(state, payload){
            state.doneReminders = [...payload]
        },
        setCostEstimation(state, payload){
            state.costEstimation = [...payload]
        },
        setSnags(state, payload){
            state.snags = [...payload]
        },
        setSnagsWho(state, payload){
            state.snagsWho = [...payload]
        },
        setDoneSnags(state, payload){
            state.doneSnags = [...payload]
        },
        setRequestData(state, payload){
            state.requestData = [...payload]
        },
        setNotifications(state, payload){
            state.notifications = [...payload]
        },
        setSupplierList(state, v){
            state.supplierList = [...v]
        },
        setSupplierProfile(state, v){
            state.supplierProfile = [...v]
        },
        setQuotesData(state, v){
            state.quotesData = [...v]
        },
        setChats(state, v){
            state.chats = [...v]
        },
        setConvo(state, v){
            state.convo = [...v]
        },
        setFFF(state, v){
            state.fff = [...v]
        },
        setAppliances(state, v){
            state.appliances = [...v]
        },
        setFlooringCalculation(state, v){
            state.flooringCalculation = [...v]
        },
        setchildFFFData(state, v){
            state.childFFFData = [...v]
        },
        setchildApplianceData(state, v){
            state.childApplianceData = [...v]
        },
        setchildCalcData(state, v){
            state.childCalcData = [...v]
        },
        setdecision(state, v){
            state.decision = [...v]
        },
        setdecisiondetails(state, v){
            state.decisiondetails = [...v]
        },
        setsupplierdesigntracker(state, v){
            state.supplierdesigntracker = [...v]
        },
        setchasethetrade(state, v){
            state.chasetrades = [...v]
        },
        setservicetype(state, v){
            state.servicetype = [...v]
        },
        // builder mutations
        setbroles(state, v){
            state.broles = [...v]
        },
        settmembers(state, v){
            state.tmembers = [...v]
        },
        setoteam(state, v){
            state.oteam = v
        },
        setclient(state, v){
            state.client = [...v]
        },
        setpreviousclients(state, v){
            state.previousclients = [...v]
        },
        setRMessage(state, v){
            return state.r_message = v
        },
        setpp(state, v){
            state.pp.data = [...v]
        },
        setlinks(state, v){
            state.pp.links = [...v]
        },
        setbtg(state, v){
            state.btg = [...v]
        }
    },
    getters: {
        mSnackbar(state){
            return state.snackbar
        },
        getDrawer(state){
            return state.showDrawer
        },
        getReload(state){
            return state.reload
        },
        getGrabConvo(state){
            return state.grabconvo
        },
        getGrabProjects(state){
            return state.grabProjects
        },
        getPName(state){
            return state.pName
        },
        getDropDown(state){
            return state.dropDown
        },
        getParentProjectPlan(state){
            return state.parentProjectPlan
        },
        getprojectcalendar(state){
            return state.projectcalendar
        },
        // getParentProjectPlan: state => value => {
        //     return state.parentProjectPlan.filter(q => q.task_name.toLowerCase().includes(value))
        // },
        // getChildData: state => value => {
        //     return state.childData.filter(q => q.task_name.toLowerCase().includes(value))
        // },
        getChildData: state => {
            return state.childData
        },
        getSystemTemplates(state){
            return state.systemTemplates
        },
        getParentSysTemp(state){
            return state.parentSystemTemplate
        },
        getBudgetManagerData(state){
            return state.budgetManagerData
        },
        getBudgetTransaction(state){
            return state.budgetTransaction
        },
        getSiteDiary(state){
            return state.siteDiary
        },
        getSiteDiaryTags(state){
            return state.siteDiaryTags
        },
        getReminders(state){
            return state.reminders
        },
        getFileManager(state){
            return state.fileManager
        },
        getFileManagerImages(state){
            return state.fileManagerImages
        },
        getDoneReminders(state){
            return state.doneReminders
        },
        getCostEstimation(state){
            return state.costEstimation
        },
        getSnags(state){
            return state.snags
        },
        getSnagsWho(state){
            return state.snagsWho
        },
        getDoneSnags(state){
            return state.doneSnags
        },
        getRequestData(state){
            return state.requestData
        },
        // getParentProjectPlan: state => value => {
        //     return state.parentProjectPlan.filter(q => q.task_name.toLowerCase().includes(value))
        // },
        getNotifications:state => value => {
            return state.notifications.filter(q => q.text.toLowerCase().includes(value))
        },
        getSupplierList: state => value => {
            if(value === null || value === ''){
                return state.supplierList
            }else{
                return state.supplierList.filter(
                    q => q.company_name.toLowerCase().includes(value)
                    || q.phone_number.toLowerCase().includes(value)
                    || q.operation_country.toLowerCase().includes(value)
                    || q.website.toLowerCase().includes(value)
                )
            }
        },
        getSupplierProfile(state){
            return state.supplierProfile
        },
        getQuotesData:state => value => {
            if(value === null || value === ''){
                return state.quotesData
            }else{
                return state.quotesData.filter(
                    q => q.quote_price <= value
                    || q.get_company.company_name.toLowerCase().includes(value)
                    || q.get_reviews_count >= value
                    || q.description.toLowerCase().includes(value)
                )
            }
        },
        getChats : state => value => {
            if(value === null || value === ''){
                return state.chats
            }else{
                return state.chats.filter(q => {
                    return q.getsupplier.name.toLowerCase().includes(value)
                })
            }
        },
        getbtg : state => value => {
            // return state.btg
            if(value === null || value === ''){
                return state.btg
            }else{
                return state.btg.filter(q => {
                    return q.id === value
                })
            }
        },
        getConvo(state){
            return state.convo
        },
        getFFF(state){
            return state.fff
        },
        getAppliances(state){
            return state.appliances
        },
        getFlooringCalculation(state){
            return state.flooringCalculation
        },
        getchildFFFData(state){
            return state.childFFFData
        },
        getchildApplianceData(state){
            return state.childApplianceData
        },
        getchildCalcData(state){
            return state.childCalcData
        },
        getdecision(state){
            return state.decision
        },
        getdecisiondetails(state){
            return state.decisiondetails
        },
        getsupplierdesigntracker(state){
            return state.supplierdesigntracker
        },
        getchasetrades(state){
            return state.chasetrades
        },
        getservicetype(state){
            return state.servicetype
        },
        // builder get property
        getbroles(state){
            return state.broles
        },
        gettmembers(state){
            return state.tmembers
        },
        getoteam(state){
            return state.oteam
        },
        getclient(state){
            return state.client
        },
        getpreviousclients(state){
            return state.previousclients
        },
        getRMessage(state){
            return state.r_message
        },
        getpp(state){
            return state.pp
        }
    },
    actions: {
        setMyTemplate({ commit }, payload){
            commit('SET_DROPDOWN', payload)
        },
        setMyParentProjectPlan({ commit }, payload){
            commit('SET_PARENT_PLAN', payload)
        },
        setprojectcalendar({commit}, payload){
            commit('setprojectcalendar', payload)
        },
        setChildData({commit}, payload){
            commit('setChildData', payload)
        },
        setSystemTemplates({commit}, payload){
            commit('setSystemTemplates', payload)
        },
        setParentSysTemp({commit}, payload){
            commit('setParentSystemTemplate', payload)
        },
        setBudgetManagerData({commit}, payload){
            commit('setBudgetManagerData', payload)
        },
        setBudgetTransaction({commit}, payload){
            commit('setBudgetTransaction', payload)
        },
        setSiteDiary({commit}, payload){
            commit('setSiteDiary', payload)
        },
        setSiteDiaryTags({commit}, payload){
            commit('setSiteDiaryTags', payload)
        },
        setReminders({commit}, payload){
            commit('setReminders', payload)
        },
        setFileManager({commit}, payload){
            commit('setFileManager', payload)
        },
        setFileManagerImages({commit}, payload){
            commit('setFIleManagerImages', payload)
        },
        setDrawer({commit}, v){
            commit('setDrawer', v)
        },
        setReload({commit}, v){
            commit('setReload', v)
        },
        setGrabConvo({commit}, v){
            commit('setGrabConvo', v)
        },
        setGrabProjects({commit}, v){
            commit('setGrabProjects', v)
        },
        setPName({commit}, v){
            commit('setPName', v)
        },
        setDoneReminders({commit}, payload){
            commit('setDoneReminders', payload)
        },
        setCostEstimation({commit}, payload){
            commit('setCostEstimation', payload)
        },
        setSnags({commit}, p){
            commit('setSnags', p)
        },
        setSnagsWho({commit}, p){
            commit('setSnagsWho', p)
        },
        setDoneSnags({commit}, p){
            commit('setDoneSnags', p)
        },
        setRequestData({commit}, p){
            commit('setRequestData', p)
        },
        setNotifications({commit}, p){
            commit('setNotifications', p)
        },
        setSupplierList({commit}, v){
            commit('setSupplierList', v)
        },
        setSupplierProfile({commit}, v){
            commit('setSupplierProfile', v)
        },
        setQuotesData({commit}, v){
            commit('setQuotesData', v)
        },
        setChats({commit}, v){
            commit('setChats', v)
        },
        setConvo({commit}, v){
            commit('setConvo', v)
        },
        setFFF({commit}, v){
            commit('setFFF', v)
        },
        setAppliances({commit}, v){
            commit('setAppliances', v)
        },
        setFlooringCalculation({commit}, v){
            commit('setFlooringCalculation', v)
        },
        setchildFFFData({commit}, v){
            commit('setchildFFFData', v)
        },
        setchildApplianceData({commit}, v){
            commit('setchildApplianceData', v)
        },
        setchildCalcData({commit}, v){
            commit('setchildCalcData', v)
        },
        setdecision({commit}, v){
            commit('setdecision', v)
        },
        setdecisiondetails({commit}, v){
            commit('setdecisiondetails', v)
        },
        setsupplierdesigntracker({commit}, v){
            commit('setsupplierdesigntracker', v)
        },
        setchasethetrade({commit}, v){
            commit('setchasethetrade', v)
        },
        setservicetype({commit}, v){
            commit('setservicetype', v)
        },
        // builder action property
        setbroles({commit}, v){
            commit('setbroles', v)
        },
        settmembers({commit}, v){
            commit('settmembers', v)
        },
        setoteam({commit}, v){
            commit('setoteam', v)
        },
        setclient({commit}, v){
            commit('setclient', v)
        },
        setpreviousclients({commit}, v){
            commit('setpreviousclients', v)
        },
        setRMessage({commit}, v){
            commit('setRMessage', v)
        },
        setpp({commit}, v){
            commit('setpp', v)
        },
        setlinks({commit}, v){
            commit('setlinks', v)
        },
        setbtg({commit}, v){
            commit('setbtg', v)
        }
    }
})

// const store = new Vuex.Store({
//     modules:{
//         pp: ProjectPlan
//     }
// })

export default store