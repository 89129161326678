<template>
    <v-container>
        <!-- <v-card>
            <v-card-title><small>Activity Logs</small></v-card-title>
            <v-card-text>
                <v-card>
                    
                </v-card>
            </v-card-text>
        </v-card> -->
        <v-btn
            elevation="2"
            class="ml-3 mt-5"
            color="primary"
            @click="ProjectPlan()"
        >Project</v-btn>
        <v-btn
            elevation="2"
            class="ml-5  mt-5"
            color="primary"
            @click="ProjectPlanChart()"
            id="start_step_15"
        >Task</v-btn>
        <div v-bind:style="projectplan ? '' : 'display: none;'">
          <ProjectPlan task="edit" />
        </div>
        <div v-bind:style="projectplanchart ? '' : 'display: none;'">
          <ProjecPlanChart />
        </div>        
</v-container>
</template>

<script>

import ProjectPlan from '../components/ProjectPlan';
import ProjecPlanChart from '../components/ProjectPlanChart';

export default {
  components: {
    ProjectPlan,
    ProjecPlanChart
  },
  props: [
  ],
  data () {
    return {
      projectplan: true,
      projectplanchart: false
    }
  },
  mounted () {
  },
  created () {
    if(localStorage.getItem("token") === null){
        this.$router.push({name: 'index'})
        return
    }
    this.$store.dispatch('setDrawer', true)
    this.url = process.env.VUE_APP_URL
  },
  computed: {
  },
  methods: {
      ProjectPlan(){
        this.projectplan = true;
        this.projectplanchart = false;
      },
      ProjectPlanChart(){
        this.projectplan = false;
        this.projectplanchart = true;
        const introData = JSON.parse(localStorage.getItem('intro_data'))
        if ( introData.project_planner == false ) {
          this.$tours.tourGuide.nextStep()
          let updatedIntroData = JSON.parse(localStorage.getItem('intro_data'))
          updatedIntroData.project_planner = true
          localStorage.setItem('intro_data', JSON.stringify(updatedIntroData))
        }
      }
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
</style>