<template>
    <v-container>
        <v-row
            class="mt-15"
        >
            <v-col
                cols="12"
            >
                <!-- <h5>People on this Project</h5>
                <small style="cursor: pointer;" class="d-flex justify-end" @click="addmembermodal = true">
                    <span class="material-icons-outlined" style="position: relative; top: 3px; fontSize: 14px;">
                        add
                    </span>
                    add team member
                </small> -->
            </v-col>
        </v-row>
        <v-card>
            <v-card-title>
                <h5>People on this Project</h5>
                <v-spacer/>
                <small style="cursor: pointer;" class="d-flex justify-end" @click="addmembermodal = true">
                    <v-btn
                        outlined
                        rounded
                    >
                        <span class="material-icons-outlined">
                            add
                        </span>
                        add team member
                    </v-btn>
                </small>
            </v-card-title>
            <v-list
                subheader
                two-line
            >
                <v-list-item>
                    <v-list-item-avatar>
                    <v-icon>perm_identity</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-list-item-title>{{getoteam.name}}</v-list-item-title>

                        <v-list-item-subtitle>{{getoteam.email}}</v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-action>
                        <small>Builder Pro</small>
                    </v-list-item-action>
                </v-list-item>
                <v-list-item
                    v-for="team in gettmembers"
                    :key="team.id"
                >
                    <v-list-item-avatar>
                    <v-icon>perm_identity</v-icon>
                    </v-list-item-avatar>

                    <v-list-item-content>
                        <v-list-item-title>
                            {{team.name}}
                        </v-list-item-title>

                        <v-list-item-subtitle>
                            {{team.email}}
                        </v-list-item-subtitle>
                        <small v-if="team.name === null">not yet joined</small>
                    </v-list-item-content>

                    <v-list-item-action>
                        <v-row
                            align="center"
                            justify="center"
                        >
                            <small class="mr-2" style="color: green">{{team.getbroles.role}}</small>
                            <v-btn-toggle
                                rounded
                                dense
                                small
                            >
                                <v-btn
                                    small
                                    v-if="team.name === null"
                                    @click="resendinvitation(team)"
                                >
                                    resend invitation
                                </v-btn>
                                <v-btn
                                    small
                                    @click="revokemember(team)"
                                >
                                    remove member
                                </v-btn>
                            </v-btn-toggle>
                        </v-row>
                    </v-list-item-action>
                </v-list-item>
            </v-list>
        </v-card>
        <v-row justify="center">
            <v-dialog
                v-model="addmembermodal"
                persistent
                max-width="650"
            >
            <v-card>
                <v-card-title>
                    <small>invite team member</small>
                </v-card-title>
                <v-card-text>
                    <v-row
                        no-gutters
                    >
                        <v-col
                            cols="12"
                        >
                            <v-autocomplete
                                v-model="ibtg"
                                :items="getbtg"
                                item-value="id"
                                item-text="name"
                                filled
                                label="Worker"
                            ></v-autocomplete>
                        </v-col>
                        <v-col
                            cols="12"
                        >
                            <v-text-field
                                dense
                                filled
                                v-model="m.email"
                                label="enter email address"
                                :disabled="isEmail"
                            ></v-text-field>
                        </v-col>
                        <v-col
                            cols="12"
                        >
                            <v-autocomplete
                                v-model="m.roleId"
                                :items="getbroles"
                                item-text="role"
                                item-value="id"
                                dense
                                filled
                                label="Role"
                                :disabled="isRoleId"
                            >
                                <template v-slot:item="data">
                                    <template>
                                        <v-list-item-content>
                                            <v-list-item-title v-html="data.item.role"></v-list-item-title>
                                            <v-list-item-subtitle v-html="data.item.description"></v-list-item-subtitle>
                                        </v-list-item-content>
                                    </template>
                                </template>
                            </v-autocomplete>
                        </v-col>
                        <v-col
                            cols="12"
                        >
                            <v-autocomplete
                                v-model="m.access"
                                :items="access"
                                item-value="id"
                                item-text="text"
                                dense
                                filled
                                multiple
                                label="Access Level"
                                :disabled="isAccess"
                            >
                            </v-autocomplete>
                        </v-col>
                        <v-col
                            cols="12"
                        >
                            <v-text-field
                                dense
                                filled
                                v-model="m.rate_per_hour"
                                label="enter rate per hour"
                                @keypress.enter="sinvite(m)"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="green darken-1"
                    text
                    @click="addmembermodal = false; m.email = null; m.roleId = null; m.access = []"
                >
                    Cancel
                </v-btn>
                <v-btn
                    color="#062a82"
                    dark
                    @click="sinvite(m)"
                    class="pl-8 pr-8"
                >
                    Send Invitation
                </v-btn>
                </v-card-actions>
            </v-card>
            </v-dialog>
        </v-row>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {
  },
  props: [
  ],
  data () {
    return {
      addmembermodal: false,
      ibtg: null,
      isEmail: false,
      isRoleId: false,
      isAccess: false,
      m: {
        user_id: '',
        email: null,
        roleId: null,
        access: [],
        rate_per_hour: ""
      },
      urlParams: 'empty',
      access: [{
            id: "profile",
            text: "Add Reminders"
        },{
            id: "sitediary",
            text: "Site Diary"
        },{
            id: "budgetmanager",
            text: "Budget Manager"
        },{
            id: "filemanager",
            text: "File Manager"
        },{
            id: "projectplanner",
            text: "Project Planner"
        },{
            id: "designtracker",
            text: "Design Tracker"
        },{
            id: "decision",
            text: "Decision"
        },{
            id: "snags",
            text: "Snags"
        },{
            id: "project",
            text: "Project Setup"
        },{
            id: "messages",
            text: "Inbox"
        },{
            id: "teams",
            text: "Teams Setup"
        }
    ]
    }
  },
  mounted () {
  },
  created () {
    if(localStorage.getItem("token") === null){
      this.$router.push({name: 'index'})
      return
    }
    if(localStorage.getItem('project_id') !== null){
      this.urlParams = localStorage.getItem('project_id')
    }
    this.userId = localStorage.getItem('id')
    this.$store.dispatch('setDrawer', true)
    this.fetchDataTeam()
    this.fetchRoles()
  },
  computed: {
    ...mapGetters(['getbroles', 'getoteam', 'gettmembers']),
    getbtg(){
        return this.$store.getters['getbtg'](this.ibtg)
    },
    reloadFn(){
      return this.$store.getters['getReload']
    }
  },
  methods: {
    showSnackBar(message){
      this.$store.commit("setMessage", 
      {show: true, message: message}, 
      {root: 1})
    },
    async fetchDataTeam(){
      await this.$axios.get(`fDataTeam/${this.urlParams}`)
      .then(({data}) => {
        this.$store.dispatch('setoteam', data.me)
        this.$store.dispatch('settmembers', data.team)
        this.$store.dispatch('setbtg', data.btg)
        this.$store.dispatch('setReload', false)
      })
    },
    async fetchRoles(){
      await this.$axios.get('fBRoles')
      .then(({data}) => {
        this.$store.dispatch('setbroles', data.data)
      })
    },
    async sinvite(m){
      const project = localStorage.getItem('project_id')
      if(project === 'empty' || localStorage.getItem('project_id') === null){
        this.showSnackBar('Project is empty, please select a project first!')
        return
      }
      if(this.m.rate_per_hour == ""){
        this.showSnackBar("Rate per hour should not be empty!")
        return
      }
      this.showSnackBar('Sending invitation...')
      this.addmembermodal = false
      await this.$axios.post(`imem/${this.urlParams}`, m)
      .then(({data}) => {
        console.log(data)
        if(data.response){
          this.m = {
            email: null,
            roleId: null
          }
          this.fetchDataTeam()
          this.showSnackBar('Success')
        }
      })
    },
    async resendinvitation(data){
        this.showSnackBar('Please wait...')
        await this.$axios.post('resendinvite', data)
        .then(({data}) => {
            if(data.response){
                this.showSnackBar(data.message)
            }
        })
    },
    async revokemember(d){
        this.showSnackBar('Please wait...')
        await this.$axios.delete(`revokemem/${d.id}/${localStorage.getItem('project_id')}`)
        .then(({data}) => {
            if(data.response){
                this.showSnackBar(data.message)
                this.fetchDataTeam()
            }
        })
    }
  },
  watch: {
    reloadFn(data){
        if(data){
            this.urlParams = localStorage.getItem('project_id')
            return this.fetchDataTeam()
        }
    },
    async ibtg(data){
        await this.$axios.get(`getinfo/${localStorage.getItem('project_id')}/${data}`)
        .then(({data}) => {
            if(!data.response){
                this.showSnackBar(`This user already belongs to this project ${localStorage.getItem('project_id')}`)
                this.ibtg = null
                return
            }
            this.isEmail = true
            this.isAccess = true
            this.isRoleId = true
            this.m = {
                email: data.data.email,
                roleId: data.data.role,
                access: data.data.getaccesslevels.access_level
            }
            return
        })
    }
  }
}
</script>

<style scoped lang="scss">
</style>