import { render, staticRenderFns } from "./BudgetManager.vue?vue&type=template&id=02a85768&scoped=true&"
import script from "./BudgetManager.vue?vue&type=script&lang=js&"
export * from "./BudgetManager.vue?vue&type=script&lang=js&"
import style0 from "./BudgetManager.vue?vue&type=style&index=0&id=02a85768&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "02a85768",
  null
  
)

export default component.exports