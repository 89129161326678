<template>
    <v-container>
      <v-row
        class="mt-15"
      >
        <v-col
          cols="12"
        >
          <!-- <h5>Client on this Project</h5>
          <small style="cursor: pointer;" class="d-flex justify-end" @click="addaclientmodal = true">
              <v-btn>
                <span class="material-icons-outlined">
                  add
                </span>
                add a client
              </v-btn>
          </small> -->
        </v-col>
      </v-row>
      <v-card>
        <v-card-title>
          <h5>
            Client on this Project
          </h5>
          <v-spacer/>
          <small style="cursor: pointer;" class="d-flex justify-end" @click="addaclientmodal = true">
              <v-btn
                outlined
                rounded
              >
                <span class="material-icons-outlined">
                  add
                </span>
                add a client
              </v-btn>
          </small>
        </v-card-title>
          <v-list
              subheader
              two-line
          >
              <v-list-item
                  v-for="team in getclient"
                  :key="team.id"
              >
                  <v-list-item-avatar>
                  <v-icon>perm_identity</v-icon>
                  </v-list-item-avatar>

                  <v-list-item-content>
                      <v-list-item-title>
                          {{team.name}}
                      </v-list-item-title>

                      <v-list-item-subtitle>
                          {{team.email}}
                      </v-list-item-subtitle>
                      <small v-if="team.name === null">not yet joined</small>
                  </v-list-item-content>

                  <v-list-item-action>
                      <v-row
                          align="center"
                          justify="center"
                      >
                          <small class="mr-2" style="color: green">client</small>
                          <v-btn-toggle
                              rounded
                              dense
                              small
                          >
                              <v-btn
                                  small
                                  v-if="team.name === null"
                                  @click="resendinvitation(team)"
                              >
                                  resend invitation
                              </v-btn>
                              <v-btn
                                  small
                                  @click="revokemember(team)"
                              >
                                  remove member
                              </v-btn>
                          </v-btn-toggle>
                      </v-row>
                  </v-list-item-action>
              </v-list-item>
              <v-list-item v-if="getclient.length == 0">
                No Clients
              </v-list-item>
          </v-list>
      </v-card>
      <v-row justify="center">
            <v-dialog
                v-model="addaclientmodal"
                persistent
                max-width="650"
            >
              <v-card>
                <v-card-title>
                    <small>invite previous client</small>
                </v-card-title>
                <v-card-text>
                    <v-select
                      v-model="chosen_client"
                      :items="getpreviousclients"
                      @change="add_previous_client"
                      item-text="email"
                      item-value="email"
                      label="Previous clients"
                    ></v-select>
                </v-card-text>
                <v-card-title>
                    <small>invite new client</small>
                </v-card-title>
                <v-card-text>
                    <v-text-field
                      v-model="i.email"
                      label="Email"
                      filled
                      @keypress.enter="sinvite(i)"
                    >

                    </v-text-field>
                </v-card-text>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="green darken-1"
                    text
                    @click="addaclientmodal = false; i.email = null"
                >
                    Cancel
                </v-btn>
                <v-btn
                    color="#062a82"
                    dark
                    @click="sinvite(i)"
                    class="pl-8 pr-8"
                >
                    Send Invitation
                </v-btn>
                </v-card-actions>
            </v-card>
            </v-dialog>
        </v-row>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {
  },
  props: [
  ],
  data () {
    return {
      addaclientmodal: false,
      previous_clients: [],
      chosen_client: null,
      urlParams: 'empty',
      i: {
        email: null
      }
    }
  },
  mounted () {
  },
  created () {
    if(localStorage.getItem("token") === null){
      this.$router.push({name: 'index'})
      return
    }
    if(localStorage.getItem('project_id') !== null){
      this.urlParams = localStorage.getItem('project_id')
    }
    this.$store.dispatch('setDrawer', true)
    this.fdataclient()
    this.fpreviousclients()
  },
  computed: {
    ...mapGetters(['getclient', 'getpreviousclients'])
  },
  methods: {
    showSnackBar(message){
      this.$store.commit("setMessage", 
      {show: true, message: message}, 
      {root: 1})
    },
    async fpreviousclients(){
      await this.$axios.get(`fpreviousclients/${this.urlParams}`)
        .then(({data}) => {
          if(data.response){
            this.$store.dispatch('setpreviousclients', data.data)
            console.log(data.data)
          }
      })
    },
    async fdataclient(){
      await this.$axios.get(`fdataclient/${this.urlParams}`)
      .then(({data}) => {
        if(data.response){
          this.$store.dispatch('setclient', data.data)
        }
      })
    },
    async add_previous_client() {
      if (this.urlParams === 'empty') {
        this.showSnackBar('Please select a project first!')
      }
      this.showSnackBar('Please wait...')
      this.addaclientmodal = false
      let payload = {
        email: this.chosen_client
      }
      await this.$axios.post(`addclient/${this.urlParams}`, payload)
        .then(({ data }) => {
          console.log(data)
          if (data.response) {
            this.showSnackBar(data.message)
            this.getpreviousclients.splice(this.getpreviousclients.findIndex((element) => element.email == this.chosen_client), 1);
            this.fdataclient()
          } else {
            this.showSnackBar(data.message)
          }
        })
    },
    async sinvite(d){
      if(this.urlParams === 'empty'){
        this.showSnackBar('Please select a project first!')
      }
      this.showSnackBar('Sending invitation...')
      this.addaclientmodal = false
      await this.$axios.post(`addclient/${this.urlParams}`, d)
      .then(({data}) => {
        if(data.response){
          this.showSnackBar(data.message)
          this.fdataclient()
        }else{
          this.showSnackBar(data.message)
        }
      })
    },
    async resendinvitation(data) {
      this.showSnackBar('Please wait...')
      await this.$axios.post('resendinvite', data)
        .then(({ data }) => {
          if (data.response) {
            this.showSnackBar(data.message)
          }
        })
    },
    revokemember(info){
      let payload = {
        id: info.id,
        pid: this.urlParams
      };
      this.$axios.post('removeclient', payload)
      .then(({data}) => {
          if(data.response){
            this.getpreviousclients.push(info);
            this.showSnackBar(data.message)
            this.fdataclient()
          }else{
            this.showSnackBar(data.message)
          }
      });
    },
    AddClient(){
      this.addaclientmodal = true;
      this.chosen_client = null;
    }
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
</style>