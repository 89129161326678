<template>
  <div id="div">
    <div id="gantt" ref="gantt" style="height: 100%;">

    </div>
  </div>
</template>
 
<script>
import { gantt } from 'dhtmlx-gantt';

export default {
  name: 'gantt',
  props: {
    tasks: {
      type: Object,
      default () {
        return {data: [], links: []}
      }
    }
  },
  data() {
    return {
        urlParams: 'empty',
        month: new Date().getMonth(),
        year: new Date().getFullYear(),
        task: {
            data: [],
            links: []
        },
        role: null,
        newtask: []
    }
  },
  computed: {
  },
  mounted: function () {
    this.InitChart();
  },
  created() {
  },
  methods: {
    InitChart(){
        if(localStorage.getItem('project_id') !== null) this.urlParams = localStorage.getItem('project_id');
        this.role = localStorage.getItem('role');
        let sched = this.fetchProjectInformation();
        sched.then((data) => {

            gantt.config.xml_date = "%Y-%m-%d";

            gantt.init(this.$refs.gantt);

            gantt.config.columns=[
                {name:"text",       label:"Task name",  tree:true, width:'*' },
                {name:"start_date", label:"Start time", align: "center" },
                {name:"actual_duration",   label:"Duration",   align: "center" },
                {name:"add", label:"", }
            ];

            gantt.config.lightbox.sections = [
                {name: "description", height: 70, map_to: "text", type: "textarea", focus: true},       
                {name: "time", type: "duration", map_to: "auto"},
                {name:"status", height: 35, map_to:"status", type:"select", options: [ 
                    {key:1, label: "Pending"},                                               
                    {key:2, label: "Waiting On Info"},                                             
                    {key:3, label: "Done"}                                                 
                ]},
                // {name: "done", height: 30, type:"checkbox", map_to: "is_done", options:  [
                //     {key:"1", label:"Done"},
                //     {key:"2", label:"Pending"},                                       
                //     {key:"3", label:"Waiting On Info"}                                       
                // ]},    
            ];
            gantt.locale.labels.section_status = "Status";

            gantt.templates.progress_text = function (start, end, task) {
                return "<span style='text-align:left; color: white;'>" + Math.round(task.progress * 100) + "% </span>";
            };

            gantt.templates.scale_cell_class = function(date) {
                if(gantt.checkRestDayForCellClass(date)){
                    return "restdaycol";
                }
            }

            gantt.templates.timeline_cell_class = function(task, date) {
                if(gantt.checkRestDayForCellClass(date)){
                    return "restdaycol";
                }
            }

            // Create A Custom Function
            gantt.checkRestDayForCellClass = function(date) {
                if(date.getDay()==0){
                    if(data.sunday==0){
                        return true;
                    }
                }
                if(date.getDay()==1){
                    if(data.monday==0){
                        return true;
                    }
                }
                if(date.getDay()==2){
                    if(data.tuesday==0){
                        return true;
                    }
                }
                if(date.getDay()==3){
                    if(data.wednesday==0){
                        return true;
                    }
                }
                if(date.getDay()==4){
                    if(data.thursday==0){
                        return true;
                    }
                }
                if(date.getDay()==5){
                    if(data.friday==0){
                        return true;
                    }
                }
                if(date.getDay()==6){
                    if(data.saturday==0){
                        return true;
                    }
                }
            }

            gantt.checkRestDayForTaskRow = function(date) {
                if(date==0){
                    if(data.monday==0){
                        return true;
                    }
                }
                if(date==1){
                    if(data.tuesday==0){
                        return true;
                    }
                }
                if(date==2){
                    if(data.wednesday==0){
                        return true;
                    }
                }
                if(date==3){
                    if(data.thursday==0){
                        return true;
                    }
                }
                if(date==4){
                    if(data.friday==0){
                        return true;
                    }
                }
                if(date==5){
                    if(data.sunday==0){
                        return true;
                    }
                }
                if(date==6){
                    if(data.saturday==0){
                        return true;
                    }
                }
            }

            gantt.templates.grid_row_class = function( start, end, task ){
                if ( task.$level > 1 ){
                    return "nested_task"
                }
                return "";
            };

            gantt.createDataProcessor((entity, action, data) => {
                // if(this.processing) return
                this.processing = true;
                console.log('DATA HERE: ',  data);
                if(action == 'create'){
                    console.log('INSERTED');
                    let parent = data.parent != 0 ? this.newtask.find((element) => element.index == data.parent) : 0;
                    if(parent) data.parent = parent.data.id;
                    let create = this.$axios.post(`insertdata/${localStorage.getItem('project_id')}`, data)
                    return create.then((response) => {
                        console.log('RESPONSE: ', response);
                        this.RefreshTemplate(data.id, response.data.data, action);
                        this.processing = false;
                    });
                }
                if(action == 'update'){
                    console.log('UPDATED');
                    let info = this.newtask.find((element) => element.index == data.id);
                    if(info) { info.data.text = data.text; info.data.start_date = data.start_date; info.data.end_date = data.end_date; info.data.duration = data.duration, info.data.progress = data.progress }
                    let update = this.$axios.post(`updateitem/${localStorage.getItem('project_id')}`, info ? info.data : data)
                    return update.then((response) => {
                        console.log('RESPONSE: ', response);
                        this.RefreshTemplate(data.id, response.data.data, action);
                        this.processing = false;
                    });
                }   
                if(action == 'delete'){
                    console.log('DELETED');
                    let info = this.newtask.find((element) => element.index == data.id);
                    return this.$axios.delete(`deleteitem/${ info ? info.data.id : data.id }`)
                }
            });

            if(this.role != 1) gantt.config.readonly = true;

            this.LoadTemplate();
            
        });
    },
    fetchProjectInformation(){

        let payload = {
            project_name: this.urlParams
        };

        return this.$axios.get('taskmanager/fetch/projectinformation', payload).then(({data}) => {
              return data.data.schedule_info;
        });

    },
    LoadTemplate(){

        this.SetEmptyTask();
        this.$axios.get(`getThisTemplate/${this.urlParams}`).then((template) => {
            this.task = {
                data: template.data.data,
                links: template.data.links
            };
            gantt.parse(this.task);
        }).catch(e => {
            console.log(e)
        });

    },
    RefreshTemplate(index, data, action){
        let gantt_data = gantt.getTask(index);
        // if(action == 'create' && is_child) gantt_data.$source;
        gantt_data.actual_duration = data.actual_duration;
        // console.log('GANT DATA: ', gantt_data.$source);
        gantt.refreshData();
        if(action == 'create') this.newtask.push({ index: index, data: { id: data.id, text: data.task_name, start_date: data.start, end_date: data.finish, duration: data.duration, status: data.status, progress: 0, parent: data.predecessor  } });
    },
    SetEmptyTask(){
        this.task = {
            data: [],
            links: []
        };
    }
  },
  destroyed() {
    console.log("This Will Remove The Gantt Initialize");
    location.reload();
  }
}
</script>
 
<style>
  @import "~dhtmlx-gantt/codebase/dhtmlxgantt.css";
  .restdaycol {
      background-color: #BFBFBF !important;
  }
  .nested_task .gantt_add{
      display: none !important;
  }
</style>