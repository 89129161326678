<template>
    <v-container fill-height fluid>
        <v-row align="center" justify="center">
            <v-card
                class="mt-15"
                outlined
                style="width: 500px;"
            >
                <v-toolbar dark color="#062a82">
                    <v-toolbar-title>Login Form</v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                    <v-text-field
                        prepend-icon="person"
                        v-model="credentials.username"
                        name="Email or Username"
                        label="Email or Username"
                        type="text"
                    ></v-text-field>
                    <!-- <v-text-field
                        id="password"
                        prepend-icon="lock"
                        v-model="credentials.password"
                        name="password"
                        label="Password"
                        type="password"
                    ></v-text-field> -->
                    <v-text-field
                        prepend-icon="lock"
                        :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="show2 ? 'text' : 'password'"
                        name="Password"
                        label="Password"
                        v-model="credentials.password"
                        class="input-group--focused"
                        @click:append="show2 = !show2"
                        @keypress.enter="login"
                    ></v-text-field>
                </v-card-text>
                <v-card-actions>
                    <small @click="$router.push({name: 'forgotpassword'})" style="cursor: pointer;" class="ml-4">Forgot Password?</small>
                    <v-spacer></v-spacer>
                    <v-btn color="#062a82" class="white--text" @click="login">Login</v-btn>
                    <v-btn color="#062a82" class="white--text" @click="register">Register</v-btn>
                </v-card-actions>
            </v-card>
        </v-row>
        <v-dialog
            transition="dialog-bottom-transition"
            max-width="600"
            persistent
            v-model="OTPdialog"
        >
            <v-card>
                <v-toolbar
                    color="primary"
                    dark
                >An OTP code has been sent to your email: <br/>{{register.email}}</v-toolbar>
                <v-card-text>
                    <v-text-field
                        standard
                        v-model="otpInput"
                        label="OTP Code"
                    >
                    </v-text-field>
                </v-card-text>
                <v-card-actions class="justify-end">
                <v-btn
                    text
                    @click="verifyOtp"
                >Verify</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
  export default {
    data: () => ({
        credentials: {
            username: '',
            password: ''
        },
        show2: false,
        OTPdialog: false,
        otpInput: '',
        userId: '',
    }),
    mounted () {
    },
    created () {
        if(localStorage.getItem("token") !== null){
            this.$router.push({name: 'profile'})
        }
    },
    computed: {
    },
    methods: {
        async getIdForUser(){
            await this.$axios.get(`getIdForUser/${this.credentials.username}`)
            .then(({data}) => {
                this.userId = data.id
            })
        },
        showOtp(){
            this.OTPdialog = true
        },
        async verifyOtp(){
            const toPass = {
                id: this.userId,
                otp: this.otpInput
            };
            await this.$axios.post('verifyOtp', toPass)
            .then(({data}) => {
                if(data.response){
                    this.OTPdialog = false
                    this.showSnackBar(data.message)
                    this.$router.push({name: 'index'})
                }else{
                    this.showSnackBar(data.message)
                }
            })
        },
        showSnackBar(message){
            this.$store.commit("setMessage", 
            {show: true, message: message}, 
            {root: 1})
        },
        async login(){
            const dataToPass = {
                username: this.credentials.username,
                password: this.credentials.password
            }
            await this.$axios.get('/csrf-cookie').then(response => {
                // console.log(response)
                if(response){
                    this.$axios.post('login', dataToPass)
                    .then(({data}) => {
                        if(data.response){
                            //localStorage.clear();
                            localStorage.setItem('access', JSON.stringify(data.access[0].access_level))
                            localStorage.setItem('token', JSON.stringify(data.data))
                            localStorage.setItem('id', data.id)
                            localStorage.setItem('name', data.info.name)
                            localStorage.setItem('username', data.info.username)
                            localStorage.setItem('email', data.info.email)
                            localStorage.setItem('phone', data.info.phone)
                            localStorage.setItem('cs', data.csrf)
                            localStorage.setItem('role', data.role)
                            localStorage.setItem('is_new', data.is_new)
                            this.$store.dispatch('setDrawer', true)
                            localStorage.setItem('project_id', 'empty')

                            if ( !localStorage.getItem('intro_data') ) {
                                const intro_data = {
                                    after_login: false,
                                    profile: false,
                                    project_creation: false,
                                    cost_estimation: false,
                                    project_planner: false
                                }

                                localStorage.setItem('intro_data', JSON.stringify(intro_data))
                            }

                            if ( data.role == 1 && data.is_new == true ) {
                                this.$router.push({ name: 'projectnew'})
                            } else {                     
                                this.$router.push({ name: 'profile' })
                            }
                            location.reload()                            
                        }else{
                            this.showSnackBar(data.message)
                            if(data.requireOtp){
                                this.getIdForUser()
                                this.showOtp()
                            }
                            return
                        }
                    })
                }
            })
        },
        register(){
            this.$router.push({ name: 'register' })
        }
    },
    watch: {

    }
  }
</script>
<style lang="scss" scoped>
    tbody tr:nth-of-type(odd) {
        background-color: rgba(0, 0, 0, .05);
    }
</style>