<template>
  <v-container>
    <v-card
      class="mx-auto my-12"
    >
      <v-card-title style="background: #e3e3e4 !important;">
        <small>
          <span style="position: relative; top: 5px;" class="material-icons-outlined mr-1">
            payments
          </span>
          {{ tab }}
        </small>
      </v-card-title>
      
      <v-card-text v-if="role == 1">
        <v-row dense>
          <v-col cols="12">
            <v-btn
              depressed
              color="primary"
              class="mr-5 mt-5"
              @click="Tab('COST ESTIMATION')"
            >
              Cost Estimation
            </v-btn>
            <v-btn
              depressed
              color="primary"
              class="mr-5 mt-5"
              @click="Tab('ACTUAL COST')"
            >
              Actual Cost
            </v-btn>
            <v-btn
              depressed
              color="primary"
              class="mt-5"
              @click="Tab('BUDGET MANAGER')"
            >
              Budget Manager
            </v-btn>
          </v-col>  
        </v-row>
      </v-card-text>
      
      <!-- COST ESTIMATION -->
      
      <v-card-text
       v-if="tab == 'COST ESTIMATION'"
       id="start_step_0"
      >
        <v-row dense>
          <v-col cols="12">
              <v-combobox
                v-if="showSelect && role == 1"
                v-model="preview_template"
                :items="templates"
                label="Select a template"
                class="mt-5"
                @change="PreviewTemplate()"
                id="start_step_1"
              ></v-combobox>
          </v-col>
          
          <v-col
              cols="1"
              align="center"
              v-if="showAddCostEstimationEntryButton && role == 1"
          >
            <v-btn
              cols="12"
              width="100%;"
              dark
              class="mt-5"
              color="indigo"
              @click="OpenNewSectionDialog()"
              id="start_step_3"
            >
              <span class="material-icons-outlined mr-1">add</span> Add Section
            </v-btn>
          </v-col>
          
          <v-col
              cols="12"
              v-else-if="!showSelect && role != 3"
          >
            Please select a project
          </v-col>
          
          <v-col
              cols="1"
              align="center"
              v-else-if="showSaveCostEstimationEntryButton && selected_template !== null"
          >
            <v-btn
              cols="12"
              width="100%;"
              dark
              color="indigo"
              @click="saveChosenTemplate()"
              id="start_step_2"
            >
              Use Template
            </v-btn>
          </v-col>
          
          <v-col cols="12">
            <v-data-table
              v-if="role == 1 && chosen_template.id"
              :headers="headerCostEstimation"
              hide-default-footer
              :items="list_data"
              :expanded.sync="expanded"
              item-key="item"
              disable-sort
              disable-pagination
              class="elevation-1 mt-5"
              id="start_step_10"
            >
              <template v-slot:item="{ headers, item, expand, isExpanded, index }">
                <tr :colspan="headers.length" style="background-color:#f0f0f0">
                  <td class="pe-1"><v-btn icon @click="OpenDeleteRowDialog(item, true)"><v-icon small>mdi-delete</v-icon></v-btn></td>
                  <td>{{ item.item }}</td>
                  <td>
                    <v-edit-dialog
                      style="cursor: pointer;"
                      :return-value.sync="item.name"
                      large
                      @save="UpdateItem('name', item.name, item.id, item)"
                    >
                      <div style="cursor: pointer;">{{ item.name }}</div>
                      <template v-slot:input>
                        <div class="mt-4 text-h6">
                          Update Name
                        </div>
                        <v-text-field
                          v-model="item.name"
                          label="Edit"
                          single-line
                          counter
                          autofocus
                        ></v-text-field>
                      </template>
                    </v-edit-dialog>
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>€{{ cost_estimation_subtotals[index] | formatNumber }}</td>
                  <td class="ps-0 pe-0"  id="start_step_6">
                    <v-btn icon @click="OpenNewItemDialog(item)">
                      <v-icon strong>mdi-plus</v-icon>
                    </v-btn>
                  </td>
                  <td class="ps-0 pe-2" id="start_step_9">
                    <v-btn v-if="cost_estimation_child_data[index].length !== 0" icon @click="expand(!isExpanded)" >
                      <v-icon>{{ isExpanded ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </template>
              
              <template v-slot:expanded-item="{ headers, item, index }">
                <tr
                  v-for="(i,_index) in cost_estimation_child_data[index]"
                  :key="i.id"
                  :colspan="headers.length"
                  style="background-color:rgb(255, 255, 255)"
                >
                  <td class="pe-1"><v-btn icon @click="OpenDeleteRowDialog(cost_estimation_child_data[index][_index], false)"><v-icon small>mdi-delete</v-icon></v-btn></td>
                  <td>{{ cost_estimation_child_data[index][_index].item }}</td>
                  <td>{{ cost_estimation_child_data[index][_index].name }}</td>
                  <td>
                    <v-edit-dialog
                      style="cursor: pointer;"
                      :return-value.sync="cost_estimation_child_data[index][_index].unit"
                      large
                      @save="UpdateItem('unit', cost_estimation_child_data[index][_index].unit, cost_estimation_child_data[index][_index].id, cost_estimation_child_data[index][_index])"
                    >
                      <div style="cursor: pointer;">{{ cost_estimation_child_data[index][_index].unit }}</div>
                      <template v-slot:input>
                        <div class="mt-4 text-h6">
                          Update Unit
                        </div>
                        <v-text-field
                          v-model="cost_estimation_child_data[index][_index].unit"
                          label="Edit"
                          single-line
                          counter
                          autofocus
                        ></v-text-field>
                      </template>
                    </v-edit-dialog>
                  </td>
                  <td>
                    <v-edit-dialog
                      style="cursor: pointer;"
                      :return-value.sync="cost_estimation_child_data[index][_index].quantity"
                      large
                      @save="UpdateItem('quantity', cost_estimation_child_data[index][_index].quantity, cost_estimation_child_data[index][_index].id, cost_estimation_child_data[index][_index])"
                    >
                      <div style="cursor: pointer;">{{ cost_estimation_child_data[index][_index].quantity }}</div>
                      <template v-slot:input>
                        <div class="mt-4 text-h6">
                          Update Quantity
                        </div>
                        <v-text-field
                          v-model="cost_estimation_child_data[index][_index].quantity"
                          label="Edit"
                          single-line
                          counter
                          autofocus
                        ></v-text-field>
                      </template>
                    </v-edit-dialog>
                  </td>
                  <td>
                    <v-edit-dialog
                      style="cursor: pointer;"
                      :return-value.sync="cost_estimation_child_data[index][_index].rate"
                      large
                      @save="UpdateItem('rate', cost_estimation_child_data[index][_index].rate, cost_estimation_child_data[index][_index].id, cost_estimation_child_data[index][_index])"
                    >
                      <div style="cursor: pointer;" @click="Comma('click',index,_index)">€{{ cost_estimation_child_data[index][_index].rate | formatNumber }}</div>
                      <template v-slot:input>
                        <div class="mt-4 text-h6">
                          Update Rate
                        </div>
                        <v-text-field
                          v-model="cost_estimation_child_data[index][_index].rate"
                          v-on:keyup="Comma('update',index,_index)"
                          label="Edit"
                          single-line
                          counter
                        ></v-text-field>
                      </template>
                    </v-edit-dialog>
                  </td>
                  <td>€{{ cost_estimation_child_data[index][_index].amount | formatNumber }}</td>
                  <td></td>
                  <td></td>
                </tr>
              </template>
            </v-data-table>
            <v-data-table
              v-else
              :headers="headerCostEstimation"
              hide-default-footer
              :items="list_data"
              :expanded.sync="expanded"
              item-key="item"
              disable-sort
              disable-pagination
              class="elevation-1 mt-5"
            >
              <template v-slot:item="{ headers, item, expand, isExpanded, index }">
                <tr :colspan="headers.length" style="background-color:#f0f0f0">
                  <td></td>
                  <td>{{ item.item }}</td>
                  <td>{{ item.name }}</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>€{{ cost_estimation_subtotals[index] | formatNumber }}</td>
                  <td></td>
                  <td>
                    <v-btn v-if="cost_estimation_child_data[index].length !== 0" icon @click="expand(!isExpanded)" >
                      <v-icon>{{ isExpanded ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </template>
              
              <template v-slot:expanded-item="{ headers, item, index }">
                <tr
                  v-for="(i,_index) in cost_estimation_child_data[index]"
                  :key="i.id"
                  :colspan="headers.length"
                  style="background-color:rgb(255, 255, 255)"
                >
                  <td></td>
                  <td>{{ cost_estimation_child_data[index][_index].item }}</td>
                  <td>{{ cost_estimation_child_data[index][_index].name }}</td>
                  <td>{{ cost_estimation_child_data[index][_index].unit }}</td>
                  <td>{{ cost_estimation_child_data[index][_index].quantity }}</td>
                  <td>€{{ cost_estimation_child_data[index][_index].rate | formatNumber }}</td>
                  <td>€{{ cost_estimation_child_data[index][_index].amount | formatNumber }}</td>
                  <td></td>
                  <td></td>
                </tr>
              </template>
            </v-data-table>
          </v-col>
          
          <v-col cols="12">
            <v-data-table
              v-if="chosen_template.id"
              hide-default-footer
              :headers="headerCostEstimationTotal"
              :items="cost_estimation_total"
              disable-sort
              class="elevation-1 mt-5"
            >
              <template v-slot:item="{ headers, item }">
                <tr :colspan="headers.length" style="background-color:rgb(255, 255, 255)">
                  <td>€{{ item.grand_total | formatNumber }}</td>
                  <td>
                    <v-edit-dialog
                      style="cursor: pointer;"
                      :return-value.sync="item.profit_percent"
                      large
                      @save="UpdatePercentages('profit_percent', item.profit_percent, item.id, item)"
                    >
                      <div style="cursor: pointer;">{{ item.profit_percent }}%</div>
                      <template v-slot:input>
                        <div class="mt-4 text-h6">
                          Update Profit Percentage
                        </div>
                        <v-text-field
                          v-model="item.profit_percent"
                          label="Edit"
                          single-line
                          counter
                          autofocus
                        ></v-text-field>
                      </template>
                    </v-edit-dialog>
                  </td>
                  <td>€{{ item.profit | formatNumber }}</td>
                  <td>€{{ item.total_quote_no_vat | formatNumber }}</td>
                  <td>
                    <v-edit-dialog
                      style="cursor: pointer;"
                      :return-value.sync="item.vat_percent"
                      large
                      @save="UpdatePercentages('vat_percent', item.vat_percent, item.id, item)"
                    >
                      <div style="cursor: pointer;">{{ item.vat_percent }}%</div>
                      <template v-slot:input>
                        <div class="mt-4 text-h6">
                          Update VAT Percentage
                        </div>
                        <v-text-field
                          v-model="item.vat_percent"
                          label="Edit"
                          single-line
                          counter
                          autofocus
                        ></v-text-field>
                      </template>
                    </v-edit-dialog>
                  </td>
                  <td>€{{ item.vat | formatNumber }}</td>
                  <td>€{{ item.total_quote_with_vat | formatNumber }}</td>
                </tr>
              </template>
            </v-data-table>
            <v-data-table
              v-else
              hide-default-footer
              :headers="headerCostEstimationTotal"
              :items="cost_estimation_total"
              disable-sort
              class="elevation-1 mt-5"
            >
              <template v-slot:item="{ headers, item }">
                <tr :colspan="headers.length" style="background-color:rgb(255, 255, 255)">
                  <td>€{{ item.grand_total | formatNumber }}</td>
                  <td>{{ item.profit_percent }}%</td>
                  <td>€{{ item.profit | formatNumber }}</td>
                  <td>€{{ item.total_quote_no_vat | formatNumber }}</td>
                  <td>{{ item.vat_percent }}%</td>
                  <td>€{{ item.vat | formatNumber }}</td>
                  <td>€{{ item.total_quote_with_vat | formatNumber }}</td>
                </tr>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>

      <!-- ACTUAL COST -->

      <v-card-text
       v-if="tab == 'ACTUAL COST'"
      >
        <v-row dense>
          <v-col cols="12">
            <v-data-table
              :headers="headerWorkerCost"
              :items="list_data"
              :expanded.sync="expanded"
              :options.sync="pagination"
              item-key="item"
              disable-sort
              class="elevation-1 mt-5"
            >
              <template v-slot:item="{ headers, item, expand, isExpanded, index }">
                <tr :colspan="headers.length" style="background-color:#f0f0f0">
                  <td>{{ item.item }}</td>
                  <td>{{ item.name }}</td>
                  <td>€{{ worker_logs_materials_subtotal[(pagination.page-1)*pagination.itemsPerPage + index] | formatNumber }}</td>
                  <td>€{{ worker_logs_labour_subtotal[(pagination.page-1)*pagination.itemsPerPage + index] | formatNumber }}</td>
                  <td>
                    <v-btn v-if="cost_estimation_child_data[(pagination.page-1)*pagination.itemsPerPage + index].length !== 0" icon @click="expand(!isExpanded)" >
                      <v-icon>{{ isExpanded ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </template>
              
              <template v-slot:expanded-item="{ headers, item, index }">
                <tr
                  v-for="(i,_index) in cost_estimation_child_data[(pagination.page-1)*pagination.itemsPerPage + index]"
                  :key="i.id"
                  :colspan="headers.length"
                  style="background-color:rgb(255, 255, 255)"
                >
                  <td>{{ cost_estimation_child_data[(pagination.page-1)*pagination.itemsPerPage + index][_index].item }}</td>
                  <td>{{ cost_estimation_child_data[(pagination.page-1)*pagination.itemsPerPage + index][_index].name }}</td>
                  <td>
                    <v-edit-dialog
                      style="cursor: pointer;"
                      :return-value.sync="cost_estimation_child_data[(pagination.page-1)*pagination.itemsPerPage + index][_index].materials"
                      large
                      @save="update_materials(i)"
                    >
                      <div style="cursor: pointer;">€{{ cost_estimation_child_data[(pagination.page-1)*pagination.itemsPerPage + index][_index].materials | formatNumber }}</div>
                      <template v-slot:input>
                        <div class="mt-4 text-h6">
                          Update Materials
                        </div>
                        <v-text-field
                          v-model="cost_estimation_child_data[(pagination.page-1)*pagination.itemsPerPage + index][_index].materials"
                          label="Edit"
                          single-line
                          counter
                          autofocus
                        ></v-text-field>
                      </template>
                    </v-edit-dialog>
                  </td>
                  <td>
                    <v-edit-dialog
                      style="cursor: pointer;"
                      :return-value.sync="cost_estimation_child_data[(pagination.page-1)*pagination.itemsPerPage + index][_index].labour"
                      large
                      @save="update_labour(i)"
                    >
                      <div style="cursor: pointer;">€{{ cost_estimation_child_data[(pagination.page-1)*pagination.itemsPerPage + index][_index].labour | formatNumber }}</div>
                      <template v-slot:input>
                        <div class="mt-4 text-h6">
                          Update Labour
                        </div>
                        <v-text-field
                          v-model="cost_estimation_child_data[(pagination.page-1)*pagination.itemsPerPage + index][_index].labour"
                          label="Edit"
                          single-line
                          counter
                          autofocus
                        ></v-text-field>
                      </template>
                    </v-edit-dialog>
                  </td>
                  <td></td>
                </tr>
              </template>
            </v-data-table>
          </v-col>
          
          <v-col cols="6"></v-col>
          <v-col cols="6">
            <v-data-table
              hide-default-footer
              :headers="headerWorkerCostTotalMaterialsAndLabour"
              :items="worker_cost_total"
              disable-sort
              class="elevation-1 mt-5"
            >
              <template v-slot:item="{ headers, item }">
                <tr :colspan="headers.length" style="background-color:rgb(255, 255, 255)">
                  <td>€{{ item.total_materials | formatNumber }}</td>
                  <td>€{{ item.total_labour | formatNumber }}</td>
                </tr>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
      
      <!-- BUDGET MANAGER -->

      <v-card-text
        v-if="tab == 'BUDGET MANAGER'"
      >
        <v-row dense>
          <v-col cols="12">
            <v-data-table
              :headers="headersBudgetManager"
              :items="list_data"
              :expanded.sync="expanded"
              :options.sync="pagination"
              item-key="item"
              disable-sort
              class="elevation-1 mt-5"
            >
              <template v-slot:header="{ }">
                <thead>
                  <tr>
                    <th
                      colspan="3"
                      class="elevation-1 text-center"
                      style="background-color:rgb(224,224,224)"
                    >
                      Item
                    </th>
                    <th
                      colspan="3"
                      class="elevation-1 text-center"
                      style="background-color:rgb(224,224,224)"
                    >
                      Cost Estimation
                    </th>
                    <th
                      colspan="3"
                      class="elevation-1 text-center"
                      style="background-color:rgb(224,224,224)"
                    >
                      Actual Cost
                    </th>
                    <th
                      colspan="3"
                      class="elevation-1 text-center"
                      style="background-color:rgb(224,224,224)"
                    >
                      Variance
                    </th>
                  </tr>
                </thead>
              </template>
              
              <template v-slot:item="{ headers, item, expand, isExpanded, index }">
                <tr :colspan="headers.length" style="background-color:#f0f0f0">
                  <td>{{ item.item }}</td>
                  <td>{{ item.name }}</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>€{{ cost_estimation_subtotals[(pagination.page-1)*pagination.itemsPerPage + index] | formatNumber }}</td>
                  <!--<td>€{{ worker_logs_materials_subtotal[(pagination.page-1)*pagination.itemsPerPage + index] | formatNumber }}</td>
                  <td>€{{ worker_logs_labour_subtotal[(pagination.page-1)*pagination.itemsPerPage + index] | formatNumber }}</td>-->
                  <td></td>
                  <td></td>
                  <td>€{{ worker_logs_amount_subtotal[(pagination.page-1)*pagination.itemsPerPage + index] | formatNumber }}</td>
                  <!--<td>€{{ variance[(pagination.page-1)*pagination.itemsPerPage + index] | formatNumber }}</td>
                  <td>{{ variance_percent[(pagination.page-1)*pagination.itemsPerPage + index] | formatNumber}}%</td>-->
                  <td></td>
                  <td></td>
                  <td>
                    <v-btn v-if="cost_estimation_child_data[(pagination.page-1)*pagination.itemsPerPage + index].length !== 0" icon @click="expand(!isExpanded)" >
                      <v-icon>{{ isExpanded ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </template>
              
              <template v-slot:expanded-item="{ headers, item, index }">
                <tr
                  v-for="(i,_index) in cost_estimation_child_data[(pagination.page-1)*pagination.itemsPerPage + index]"
                  :key="i.id"
                  :colspan="headers.length"
                  style="background-color:rgb(255, 255, 255)"
                >
                  <td>{{ cost_estimation_child_data[(pagination.page-1)*pagination.itemsPerPage + index][_index].item }}</td>
                  <td>{{ cost_estimation_child_data[(pagination.page-1)*pagination.itemsPerPage + index][_index].name }}</td>
                  <td>{{ cost_estimation_child_data[(pagination.page-1)*pagination.itemsPerPage + index][_index].unit }}</td>
                  <td>{{ cost_estimation_child_data[(pagination.page-1)*pagination.itemsPerPage + index][_index].quantity }}</td>
                  <td>€{{ cost_estimation_child_data[(pagination.page-1)*pagination.itemsPerPage + index][_index].rate | formatNumber }}</td>
                  <td>€{{ cost_estimation_child_data[(pagination.page-1)*pagination.itemsPerPage + index][_index].amount | formatNumber }}</td>
                  <td>€{{ cost_estimation_child_data[(pagination.page-1)*pagination.itemsPerPage + index][_index].materials | formatNumber }}</td>
                  <td>€{{ cost_estimation_child_data[(pagination.page-1)*pagination.itemsPerPage + index][_index].labour | formatNumber }}</td>
                  <td>€{{ cost_estimation_child_data[(pagination.page-1)*pagination.itemsPerPage + index][_index].total_amount | formatNumber }}</td>
                  <td>€{{ cost_estimation_child_data[(pagination.page-1)*pagination.itemsPerPage + index][_index].variance | formatNumber }}</td>
                  <td>{{ cost_estimation_child_data[(pagination.page-1)*pagination.itemsPerPage + index][_index].variance_percent | formatNumber }}%</td>
                  <td></td>
                </tr>
              </template>
            </v-data-table>
          </v-col>
          
          <v-col cols="7">
            <v-data-table
              hide-default-footer
              :headers="headerCostEstimationTotal"
              :items="cost_estimation_total"
              disable-sort
              class="elevation-1 mt-5"
            >
              <template v-slot:header="{ }">
                <thead>
                  <tr>
                    <th
                      colspan="7"
                      class="elevation-1 text-center"
                      style="background-color:rgb(224,224,224)"
                    >
                      Cost Estimation Total
                    </th>
                  </tr>
                </thead>
              </template>
              
              <template v-slot:item="{ headers, item }">
                <tr :colspan="headers.length" style="background-color:rgb(255, 255, 255)">
                  <td>€{{ item.grand_total | formatNumber }}</td>
                  <td>{{ item.profit_percent }}%</td>
                  <td>€{{ item.profit | formatNumber }}</td>
                  <td>€{{ item.total_quote_no_vat | formatNumber }}</td>
                  <td>{{ item.vat_percent }}%</td>
                  <td>€{{ item.vat | formatNumber }}</td>
                  <td>€{{ item.total_quote_with_vat | formatNumber }}</td>
                </tr>
              </template>
            </v-data-table>
          </v-col>
          
          <v-col cols="5">
            <v-data-table
              hide-default-footer
              :headers="headerWorkerCostTotal"
              :items="actual_cost_total"
              disable-sort
              class="elevation-1 mt-5"
            >
              <template v-slot:header="{ }">
                <thead>
                  <tr>
                    <th
                      colspan="7"
                      class="elevation-1 text-center"
                      style="background-color:rgb(224,224,224)"
                    >
                      Actual Cost Total
                    </th>
                  </tr>
                </thead>
              </template>
              
              <template v-slot:item="{ headers, item }">
                <tr :colspan="headers.length" style="background-color:rgb(255, 255, 255)">
                  <td>€{{ item.grand_total | formatNumber }}</td>
                  <td>{{ item.vat_percent }}%</td>
                  <td>€{{ item.vat | formatNumber }}</td>
                  <td>€{{ item.total_quote_with_vat | formatNumber }}</td>
                </tr>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    
    <!-- New Section Dialog -->
    
    <v-dialog
      max-width="500px"
      v-model="new_section_dialog_open"
      persistent
    >
      <v-card>
        <v-card-title>
          New Section
        </v-card-title>
        <v-card-text id="start_step_4">
          <v-text-field
            v-model="new_section_data.item"
            name="input-7-1"
            label="Item No."
            placeholder="Item No."
            autocomplete="off"
          ></v-text-field>
          <v-text-field
            v-model="new_section_data.description"
            name="input-7-1"
            label="Description"
            placeholder="Description"
            autocomplete="off"
          ></v-text-field>
          <v-btn
            text
            class="float-end"
            @click="CloseNewSectionDialog()"
          >
            <span class="material-icons-outlined">cancel</span>
          </v-btn>

          <v-btn
            text
            class="float-end"
            @click="SaveNewSection()"
            id="start_step_5"
          >
            <span class="material-icons-outlined">save</span>
          </v-btn>
        </v-card-text>
        <v-card-actions>
            <p></p>
        </v-card-actions>
      </v-card>
    </v-dialog>
    
    <!-- New Item Dialog -->
    
    <v-dialog
      max-width="500px"
      v-model="new_item_dialog_open"
      persistent
    >
      <v-card>
        <v-card-title>
          New Item
        </v-card-title>
        <v-card-text id="start_step_7">
          <v-text-field
            v-model="new_item_data.item"
            name="input-7-1"
            label="Item No."
            placeholder="Item No."
            autocomplete="off"
          ></v-text-field>
          <v-text-field
            v-model="new_item_data.description"
            name="input-7-1"
            label="Description"
            placeholder="Description"
            autocomplete="off"
          ></v-text-field>
          <v-text-field
            v-model="new_item_data.unit"
            name="input-7-1"
            label="Unit"
            placeholder="Unit"
            autocomplete="off"
          ></v-text-field>
          <v-text-field
            v-model="new_item_data.quantity"
            name="input-7-1"
            label="Quantity"
            placeholder="Quantity"
            autocomplete="off"
          ></v-text-field>
          <v-text-field
            v-model="new_item_data.rate"
            name="input-7-1"
            label="Rate"
            placeholder="Rate"
            autocomplete="off"
            v-on:keyup="Comma('new',0,0)"
          ></v-text-field>
            <v-btn
              text
              class="float-end"
              @click="CloseNewItemDialog()"
            >
              <span class="material-icons-outlined">cancel</span>

            </v-btn>

            <v-btn
              text
              class="float-end"
              @click="SaveNewEntry()"
              id="start_step_8"
            >
              <span class="material-icons-outlined">save</span>
            </v-btn>

        </v-card-text>
        <v-card-actions>
            <p></p>
        </v-card-actions>
      </v-card>
    </v-dialog>
    
    <!-- Delete Row Dialog -->
    <v-dialog v-model="delete_row_dialog_open" max-width="450px">
      <v-card>
        <v-card-title class="text-h6 text-center">Are you sure you want to delete this item?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="CloseDeleteRowDialog">Cancel</v-btn>
          <v-btn color="blue darken-1" text @click="DeleteRow">OK</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <TourView ref="tourguide"></TourView>
  </v-container>
</template>

<script>
// import { mapGetters } from 'vuex';
  export default {
    data: () => ({
      userId: null,
      urlParams: 'empty',
      role: 0,
      tab: 'COST ESTIMATION',
      templates: ['Blank Template', 'Standard Template'],
      selected_template: [],
      selected_template_info: [],
      preview_template: null,
      chosen_template: [],
      pagination: {},
      expanded: [],
      showSelect: false,
      new_section_dialog_open: false,
      new_section_data: {},
      new_item_dialog_open: false,
      new_item_data: {},
      new_item_parent_section: null,
      new_cost_estimation_child_section_name: [],
      delete_row_dialog_open: false,
      row_to_delete: [],
      row_is_parent: false,
      showAddCostEstimationEntryButton: false,
      showSaveCostEstimationEntryButton: false,
      cost_estimation_child_data: [],
      cost_estimation_subtotals: [],
      worker_logs_materials_subtotal: [],
      worker_logs_labour_subtotal: [],
      worker_logs_amount_subtotal: [],
      variance: [],
      variance_percent: [],
      list_data: [],
      cost_estimation_total: [{
        grand_total: 0, profit_percent: 0, profit: 0, total_quote_no_vat: 0, vat_percent: 0, vat: 0, total_quote_with_vat: 0 
      }],
      actual_cost_total: [{
        grand_total: 0, vat_percent: 0, vat: 0, total_quote_with_vat: 0 
      }],
      worker_cost_total: [{
        total_materials: 0, total_labour: 0,
      }],
      url: '',
      headerCostEstimation: [
        {text: '', value: 'delete', width: '5%', class: "grey lighten-2"},
        {text: 'Item No.', value: 'item', width: '8%', class: "grey lighten-2"},
        {text: 'Description', value: 'name', class: "grey lighten-2"},
        {text: 'Unit', value: 'unit', width: '5%', class: "grey lighten-2"},
        {text: 'Quantity', value: 'quantity', width: '5%', class: "grey lighten-2"},
        {text: 'Rate', value: 'rate', width: '12%', class: "grey lighten-2"},
        {text: 'Amount', value: 'amount', width: '12%', class: "grey lighten-2"},
        {text: '', value: 'add', width: '1%', class: "grey lighten-2"},
        {text: '', value: 'dropdown', width: '1%', class: "grey lighten-2"}
      ],
      headerCostEstimationTotal: [
        {text: 'Grand Total', value: 'grand_total', class: "grey lighten-2"},
        {text: 'Profit%', value: 'profit_percent', class: "grey lighten-2"},
        {text: 'Profit', value: 'profit', class: "grey lighten-2"},
        {text: 'Total Quote (Excl. VAT)', value: 'total_quote_no_vat', class: "grey lighten-2"},
        {text: 'VAT%', value: 'vat_percent', class: "grey lighten-2"},
        {text: 'VAT', value: 'vat', class: "grey lighten-2"},
        {text: 'Total Quote (Incl. VAT)', value: 'total_quote_with_vat', class: "grey lighten-2"},
      ],
      
      headerWorkerCost: [
        {text: 'Item No.', value: 'item', width: '6%', class: "grey lighten-2"},
        {text: 'Description', value: 'name', width: '30%', class: "grey lighten-2"},
        {text: 'Materials', value: 'materials', width: '5%', class: "grey lighten-2"},
        {text: 'Labour', value: 'labour', width: '5%', class: "grey lighten-2"},
        {text: '', value: 'dropdown', width: '1%', class: "grey lighten-2"},
      ],
      headerWorkerCostTotalMaterialsAndLabour: [
        {text: 'Total Materials', value: 'total_materials', width: '50%'},
        {text: 'Total Labour', value: 'total_labour', width: '50%'},
      ],
      
      headersBudgetManager: [
        {text: 'Item No.', value: 'item', width: '10%', class: "grey lighten-2"},
        {text: 'Description', value: 'name', width: '30%', class: "grey lighten-2"},
        {text: 'Unit', value: 'unit', width: '5%', class: "grey lighten-2"},
        {text: 'Quantity', value: 'quantity', width: '5%', class: "grey lighten-2"},
        {text: 'Rate', value: 'rate', width: '9%', class: "grey lighten-2"},
        {text: 'Amount', value: 'amount', width: '9%', class: "grey lighten-2"},
        {text: 'Materials', value: 'materials', width: '9%', class: "grey lighten-2"},
        {text: 'Labour', value: 'labour', width: '5%', class: "grey lighten-2"},
        {text: 'Total Amount', value: 'total_amount', class: "grey lighten-2"},
        {text: 'Variance', value: 'variance', class: "grey lighten-2"},
        {text: 'Variance%', value: 'variance_percent', class: "grey lighten-2"},
        {text: '', value: 'dropdown', width: '1%', class: "grey lighten-2"},
      ],
      headerWorkerCostTotal: [
        {text: 'Grand Total', value: 'grand_total', class: "grey lighten-2"},
        {text: 'VAT%', value: 'vat_percent', class: "grey lighten-2"},
        {text: 'VAT', value: 'vat', class: "grey lighten-2"},
        {text: 'Total Quote (Incl. VAT)', value: 'total_quote_with_vat', class: "grey lighten-2"},
      ],
    }),
    mounted () {
      const introData = JSON.parse(localStorage.getItem('intro_data'))
      if ( introData.cost_estimation == false) {
        if ( !localStorage.getItem('chosen_template') ) {
          this.$refs.tourguide.set_tour_steps('tour_costestimation_part1')
          this.$refs.tourguide.start_tour()
        } else {
          this.$refs.tourguide.set_tour_steps('tour_costestimation_part2');
          this.$refs.tourguide.start_tour()
        }
      }
    },
    created () {
      if(localStorage.getItem("token") === null){
        this.$router.push({name: 'index'});
        return
      }
      if(localStorage.getItem('project_id') !== null){
        this.urlParams = localStorage.getItem('project_id');
      }
      this.userId = localStorage.getItem('id');
      this.$store.dispatch('setDrawer', true);
      
      //this.FetchSelectionTemplates();
      this.role = localStorage.getItem('role')
      if (this.role != 3) {
        if (localStorage.getItem("chosen_template") == null && this.urlParams !== "empty") {
          this.showSelect = true
        }
        else if (this.urlParams !== "empty") {
          this.FetchData()
          this.showAddCostEstimationEntryButton = true
        }
      }
      else{
        this.fetchChosenTemplate()
        console.log('meow')
      }
      this.url = process.env.VUE_APP_URL;
    },
    watch: {
      // reloadFn(data){
      //   if(data){
      //     this.urlParams = localStorage.getItem('project_id')
      //     this.fetchCost()
      //     return this.fetchData()
      //   }
      // }      
    },
    computed: {
      // reloadFn(){
      //   return this.$store.getters['getReload']
      // },
    },
    methods: {
      showSnackBar(message){
        this.$store.commit("setMessage", { show: true, message: message }, { root: 1 } );
      },
      Tab(tab){ // TAB SELECTION
        this.tab = tab;
      },
      OpenNewSectionDialog(){
        this.new_section_dialog_open = true;
        const introData = JSON.parse(localStorage.getItem('intro_data'))
        if ( introData.cost_estimation == false ) {
          setTimeout( () => {  
              this.$refs.tourguide.set_tour_steps('tour_costestimation_part2_1')
              this.$refs.tourguide.start_tour()
          }, 500);
        }
      },
      CloseNewSectionDialog(){
        this.new_section_dialog_open = false;
      },
      OpenNewItemDialog(item){
        this.new_item_parent_section = item.item;
        this.new_cost_estimation_child_section_name = item;
        this.new_item_dialog_open = true;

        const introData = JSON.parse(localStorage.getItem('intro_data'))
        if ( introData.cost_estimation == false ) {
          setTimeout( () => {
            this.$refs.tourguide.set_tour_steps('tour_costestimation_part4')
            this.$refs.tourguide.start_tour()
          }, 500);
        }
      },
      CloseNewItemDialog(){
        this.new_item_parent_section = null;
        this.new_cost_estimation_child_section_name = [];
        this.new_item_dialog_open = false;
      },
      ExpandSection(section){
        this.expanded.push(section)
      },
      
      PreviewTemplate() {
        let payload = { preview_template: this.preview_template }
        this.$axios.get('/costestimation/fetch/previewtemplate', payload)
        .then(({data}) => {
          console.log(data)
          this.list_data = data.list_data
          this.cost_estimation_child_data = data.children
          this.cost_estimation_subtotals = data.subtotals
          this.showSaveCostEstimationEntryButton = true

          // Show Tour Guide 
          const introData = JSON.parse(localStorage.getItem('intro_data'))
          if ( introData.cost_estimation == false ) {     
            this.$refs.tourguide.set_tour_steps('tour_costestimation_part1_1')
            this.$refs.tourguide.start_tour()
          }
        })
      },
      
      fetchChosenTemplate(){
        let payload = {
          user_id: this.userId,
          project_id: this.urlParams,
          role: this.role,
        }
        this.showSnackBar("Loading template...")
        this.$axios.get('/budgetmanager/fetch/chosentemplateworker', payload)
        .then(({data}) => {
          console.log(data)
          this.showSelect = false
          localStorage.setItem('chosen_template', [data.template.template_name, data.template.id])
          this.selected_template = data.template;
          this.FetchData();
        });
      },
      FetchSelectionTemplates(){
          let payload = {
              user_id: this.userId,
              project_id: this.urlParams
          };
          this.$axios.get('/budgetmanager/fetch/templates', payload)
          .then(({data}) => {
            if(data.template.length !== 0){
              this.templates = []
              data.template.forEach(value => {
                this.templates.push({
                  text: value.template_name,
                  value: value.id
                });
              });
            }
            else{
              //this.setTemplateNames()
              this.templates
            }
        });
      },
      async setTemplateNames(){
        let payload = {
          user_id: this.userId,
          project_id: this.urlParams,
          blank_template: "Blank Template",
          standard_template: "Standard Template"
        }
        this.$axios.post('/budgetmanager/save/template', payload)
        .then(({data}) => {
          if (data.success){
            this.FetchSelectionTemplates()
          }
        })
      },
      async saveChosenTemplate(){
        let payload = {
          users_id: this.userId,
          project_id: this.urlParams,
          template_name: this.preview_template,
        }
        await this.$axios.post('/budgetmanager/save/chosentemplate', payload)
          .then(({ data }) => {
            console.log(data)
            localStorage.setItem('chosen_template', [ this.preview_template, data.template.id ])
            this.showSelect = false
            this.chosen_template = data.template
            this.selected_template = data.template
            this.showAddCostEstimationEntryButton = true
            this.FetchData()
          });
      },
      async FetchTemplateInformation(){
        let payload = this.selected_template_info;
        await this.$axios.get('/budgetmanager/fetch/templateinformation', payload)
        .then(({data}) => {
          if(data.success){
            this.selected_template = data.template;
            this.FetchData();
          }
        });
      },
      FetchData(){ // GET DATA
        let payload = {}
        if(localStorage.getItem("chosen_template") != null){
          payload = {
            user_id: this.userId,
            project_id: this.urlParams,
            template_id: localStorage.getItem("chosen_template").split(',')[1],
            template_name: localStorage.getItem("chosen_template").split(',')[0],
          }
          this.$axios.get('/costestimation/data', payload)
            .then(({ data }) => {
              if (data.template != null) {
                this.chosen_template = data.template
                this.selected_template = data.template
              }
              this.showSaveCostEstimationEntryButton = false
              if (data.list_data) {
                this.list_data = data.list_data
                this.cost_estimation_child_data = data.children
                // COST ESTIMATION TOTALS
                this.cost_estimation_subtotals = data.subtotals
                this.cost_estimation_total[0].grand_total = data.grand_total
                this.cost_estimation_total[0].profit_percent = data.profit_percent
                this.cost_estimation_total[0].profit = data.profit
                this.cost_estimation_total[0].total_quote_no_vat = data.total_quote_no_vat
                this.cost_estimation_total[0].vat_percent = data.vat_percent
                this.cost_estimation_total[0].vat = data.vat
                this.cost_estimation_total[0].total_quote_with_vat = data.total_quote_with_vat
                // ACTUAL COST TOTALS
                this.worker_cost_total[0].total_materials = data.total_materials
                this.worker_cost_total[0].total_labour = data.total_labour
                // BUDGET MANAGER TOTALS
                this.worker_logs_materials_subtotal = data.materials_subtotal
                this.worker_logs_labour_subtotal = data.labour_subtotal
                this.worker_logs_amount_subtotal = data.worker_amount_subtotal
                this.variance = data.variance_subtotal
                this.variance_percent = data.variance_percent_subtotal
                this.actual_cost_total[0].grand_total = data.actual_amount_total
                this.actual_cost_total[0].vat_percent = data.vat_percent
                this.actual_cost_total[0].vat = data.actual_amount_vat
                this.actual_cost_total[0].total_quote_with_vat = data.actual_amount_total_quote_with_vat
              }
              else {
                this.list_data = []
              }
            });
        }
      },
      async SaveNewSection(){
        let payload = {
          project_id: this.urlParams,
          user_id: this.userId,
          template_id: this.selected_template.id,
          template_name: this.selected_template.template_name,
          template_info: this.selected_template_info.value,
          item: this.new_section_data.item,
          description: this.new_section_data.description,
          predecessor: null,
        };

        await this.$axios.post('/costestimation/save/item', payload)
        .then(({data}) => {
          if(data.success){
            this.list_data = data.list_data
            this.cost_estimation_child_data = data.children
            this.cost_estimation_subtotals = data.subtotals
            this.CloseNewSectionDialog()
            this.cost_estimation_total[0].grand_total = data.grand_total
            this.cost_estimation_total[0].profit = data.profit
            this.cost_estimation_total[0].total_quote_no_vat = data.total_quote_no_vat
            this.cost_estimation_total[0].vat = data.vat
            this.cost_estimation_total[0].total_quote_with_vat = data.total_quote_with_vat
            
            this.new_section_data.item = undefined
            this.new_section_data.description = undefined
            
            this.showSnackBar(data.message)

            // Show tour add more sections
            const introData = JSON.parse(localStorage.getItem('intro_data'))
            if ( introData.cost_estimation == false ) {
              this.$refs.tourguide.set_tour_steps('tour_costestimation_part3')
              this.$refs.tourguide.start_tour()
            }
          }
          else{
            this.showSnackBar(data.message)
          }
        });
      },
      async SaveNewEntry(){
        let payload = {
          child: true,
          project_id: this.urlParams,
          user_id: this.userId,
          template_id: this.selected_template.id,
          template_name: this.selected_template.template_name,
          template_info: this.selected_template_info.value,
          item: this.new_item_data.item,
          description: this.new_item_data.description,
          unit: this.new_item_data.unit,
          quantity: this.new_item_data.quantity,
          rate: this.new_item_data.rate.replaceAll(',', ''),
          predecessor: this.new_item_parent_section,
        };
        await this.$axios.post('/costestimation/save/item', payload)
        .then(({data}) => {
          if(data.success){
            this.expanded.push(this.new_cost_estimation_child_section_name)
            this.list_data = data.list_data
            this.cost_estimation_child_data = data.children
            this.cost_estimation_subtotals = data.subtotals
            this.CloseNewItemDialog()
            this.cost_estimation_total[0].grand_total = data.grand_total
            this.cost_estimation_total[0].profit = data.profit
            this.cost_estimation_total[0].total_quote_no_vat = data.total_quote_no_vat
            this.cost_estimation_total[0].vat = data.vat
            this.cost_estimation_total[0].total_quote_with_vat = data.total_quote_with_vat
            
            this.new_item_data.item = undefined
            this.new_item_data.description = undefined
            this.new_item_data.unit = undefined
            this.new_item_data.quantity = undefined
            this.new_item_data.rate = undefined
            this.showSnackBar(data.message)

            const introData = JSON.parse(localStorage.getItem('intro_data'))
            if ( introData.cost_estimation == false ) {
              this.$refs.tourguide.set_tour_steps('tour_costestimation_part5')
              this.$refs.tourguide.start_tour()
            }
          }
          else{
            this.showSnackBar(data.message)
          }
        })
      },
      UpdateItem(label, value, id, props) { // UPDATE ITEM
        let new_value = null
        if (label == "rate") {
          new_value = value.replaceAll(',', '')
        }
        else {
          new_value = value
        }
        let payload = {
          label: label,
          value: new_value,
          id: id,
          user_id: this.userId,
          project_id: this.urlParams,
          template_id: this.selected_template.id,
          template_name: this.selected_template.template_name,
          template_info: this.selected_template_info.value
        };

        this.$axios.post('/costestimation/update/item', payload)
        .then(({data}) => {
            if(data.success){
              props.amount = data['data']['amount'];
              props.variance = data['data']['variance'];
              props.variance_percent = data['data']['variance_percent'];
              
              this.cost_estimation_subtotals = data.subtotals
              this.cost_estimation_total[0].grand_total = data.grand_total
              this.cost_estimation_total[0].profit = data.profit
              this.cost_estimation_total[0].total_quote_no_vat = data.total_quote_no_vat
              this.cost_estimation_total[0].vat = data.vat
              this.cost_estimation_total[0].total_quote_with_vat = data.total_quote_with_vat
              this.variance = data.variance_subtotal
              this.variance_percent = data.variance_percent_subtotal
            }
            else{
                this.showSnackBar(data.message);
            }
        });
      },
      UpdatePercentages(label, value){ // UPDATE PROFIT AND VAT
          let payload = {
            label: label,
            value: value,
            user_id: this.userId,
            project_id: this.urlParams,
            template_id: this.selected_template.id,
            template_name: this.selected_template.template_name,
            template_info: this.selected_template_info.value
          };

          this.$axios.post('/costestimation/update/percentages', payload)
          .then(({data}) => {
              if(data.success){
                this.cost_estimation_total[0].grand_total = data.grand_total
                this.cost_estimation_total[0].profit = data.profit
                this.cost_estimation_total[0].total_quote_no_vat = data.total_quote_no_vat
                this.cost_estimation_total[0].vat = data.vat
                this.cost_estimation_total[0].total_quote_with_vat = data.total_quote_with_vat
                this.actual_cost_total[0].grand_total = data.actual_amount_total
                this.actual_cost_total[0].vat_percent = data.vat_percent
                this.actual_cost_total[0].vat = data.actual_amount_vat
                this.actual_cost_total[0].total_quote_with_vat = data.actual_amount_total_quote_with_vat
              }
              else{
                  this.showSnackBar(data.message);
              }
          });
      },
      OpenDeleteRowDialog(item, parent){
        this.row_to_delete = item
        this.row_is_parent = parent
        this.delete_row_dialog_open = true
      },
      CloseDeleteRowDialog(){
        this.delete_row_dialog_open = false
        this.row_to_delete = []
      },
      async DeleteRow(){
        let payload = {
          user_id: this.userId,
          item_id: this.row_to_delete.id,
          project_id: this.urlParams,
          template_id: this.selected_template.id,
          template_name: this.selected_template.template_name,
          template_info: this.selected_template_info.value,
          is_parent: this.row_is_parent
        }
        this.CloseDeleteRowDialog()
        await this.$axios.delete('/costestimation/delete/row', {data: payload})
        .then(({data}) => {
          this.list_data = data.list_data
          this.cost_estimation_child_data = data.children
          this.cost_estimation_subtotals = data.subtotals
          this.cost_estimation_total[0].grand_total = data.grand_total
          this.cost_estimation_total[0].profit = data.profit
          this.cost_estimation_total[0].total_quote_no_vat = data.total_quote_no_vat
          this.cost_estimation_total[0].vat = data.vat
          this.cost_estimation_total[0].total_quote_with_vat = data.total_quote_with_vat
        
          this.worker_cost_total[0].total_materials = data.total_materials
          this.worker_cost_total[0].total_labour = data.total_labour
          
          this.worker_logs_materials_subtotal = data.materials_subtotal
          this.worker_logs_labour_subtotal = data.labour_subtotal
          this.worker_logs_amount_subtotal = data.worker_amount_subtotal
          this.variance = data.variance_subtotal
          this.variance_percent = data.variance_percent_subtotal
          
          this.actual_cost_total[0].grand_total = data.actual_amount_total
          this.actual_cost_total[0].vat_percent = data.vat_percent
          this.actual_cost_total[0].vat = data.actual_amount_vat
          this.actual_cost_total[0].total_quote_with_vat = data.actual_amount_total_quote_with_vat
        });
      },
      Comma(type,index,_index){
        if (type == 'new') {
          this.new_item_data.rate = this.new_item_data.rate.replaceAll(',', '');
          let num_parts = this.new_item_data.rate.toString().split(".");
          num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          this.new_item_data.rate = num_parts.join(".");
        }
        else if (type == 'update'){
          this.cost_estimation_child_data[index][_index].rate = this.cost_estimation_child_data[index][_index].rate.replaceAll(',', '');
          let num_parts = this.cost_estimation_child_data[index][_index].rate.toString().split(".");
          num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          this.cost_estimation_child_data[index][_index].rate = num_parts.join(".");
        }
        else if (type == 'click') {
          let num_parts = this.cost_estimation_child_data[index][_index].rate.toString().split(".");
          num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          this.cost_estimation_child_data[index][_index].rate = num_parts.join(".");
        }
      },
      update_materials(item){
        this.$axios.patch('/actual_cost/material_update', item)
        this.FetchData()
      },
      update_labour(item){
        this.$axios.patch('/actual_cost/labour_update', item)
        this.FetchData()
      }
    }
  }
</script>
<style scoped lang="scss">
// css part para sa page na ito
.costEstimationSection {
  background-color:#f0f0f0
}
.childData {
  background-color:rgb(255, 255, 255)!important
}
</style>