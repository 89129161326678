<template>
    <v-container>
        <v-card
            class="mx-auto my-12"
        >
        <v-card-title style="background: #e3e3e4 !important;"><small><span style="position: relative; top: 5px;" class="material-icons-outlined mr-1">splitscreen</span>Snags</small></v-card-title>
        <v-tabs
            v-model="tab"
            class="pl-3 custom-tabs mt-3"
            lg="12"
            sm="12"
            cols="12"
        >
            <v-tab href="#tab-1" class="custom-tab-1 mr-3">
                <div id="snagsBtn">
                    Snags
                </div>
            </v-tab>
            
            <v-tab href="#tab-2" class="custom-tab-1 mr-3">
                <div id="snagsCompletedBtn">
                    Completed Snags
                </div>
            </v-tab>
        </v-tabs>
        <v-tabs-items v-if="tab === 'tab-1'">
            <v-row
                no-gutters
            >
                <div id="addSnagsBtn">
                    <div id="addItem">
                        <v-btn
                            class="mt-5 ml-3 mb-5"
                            dark
                            color="#062a82"
                            @click="fiModSnag();"
                        >
                            <span class="material-icons-outlined mr-1">add</span> Add Snag Entry
                        </v-btn>
                    </div>
                </div>
            </v-row>
            <div id="snagsTable"></div>
            <v-row
                no-gutters
            >
                <v-col>
                    <v-text-field
                        lg="12"
                        md="12"
                        sm="6"
                        v-model="search"
                        class="mb-5"
                        clearable
                        label="Search..."
                        hide-details
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-data-table
                :headers="headerSnag"
                :items="snagsData"
                class="elevation-1"
                style="width: 100%;"
                :search="search"
                v-model="selected"
                show-select
                @input="triggerComplete"
                :single-select="singleSelect"
                mobile-breakpoint="0">
                <template v-slot:item.description="props">
                    <v-edit-dialog
                        :return-value.sync="props.item.description"
                        large
                        persistent
                        @save="saveEditedDescription(props)"
                        @cancel="cancel"
                        @open="open"
                        @close="close"
                    >
                    <div>{{ props.item.description }}</div>
                        <template v-slot:input>
                            <div class="mt-4 text-h6">
                                Update Description
                            </div>
                            <v-text-field
                                v-model="props.item.description"
                                label="Edit"
                                single-line
                                autofocus
                            ></v-text-field>
                        </template>
                    </v-edit-dialog>
                </template>
                <template v-slot:item.who="props">
                    <v-edit-dialog
                        :return-value.sync="props.item.who"
                        large
                        persistent
                        @save="saveEditedWho(props)"
                        @cancel="cancel"
                        @open="open"
                        @close="close"
                    >
                    <div v-for="(item, iIndex) in props.item.who" :key="iIndex">{{ item }}</div>
                        <template v-slot:input>
                            <div class="mt-4 text-h6">
                                Update Who
                            </div>
                            <v-combobox
                                v-model="props.item.who"
                                :search-input.sync="whoSearch"
                                hide-selected
                                label="Add some people"
                                multiple
                                persistent-hint
                                small-chips
                            >
                                <template v-slot:no-data>
                                    <v-list-item>
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                No results matching "<strong>{{ whoSearch }}</strong>". Press <kbd>enter</kbd> to create a new one
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </template>
                            </v-combobox>
                        </template>
                    </v-edit-dialog>
                </template>
                <template v-slot:item.deadline="props">
                    <v-edit-dialog
                        :return-value.sync="props.item.deadline"
                        large
                        persistent
                        @save="saveEditedDeadline(props)"
                        @cancel="cancel"
                        @open="open"
                        @close="close"
                    >
                    <div>{{ props.item.deadline_at | formatDate }}</div>
                        <template v-slot:input>
                            <div class="mt-4 text-h6">
                                Update deadline
                            </div>
                            <v-menu
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="props.item.deadline_at"    
                                        readonly
                                        v-on="on"
                                        v-bind="attrs"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="props.item.deadline_at"
                                    @input="editCalModal = false"
                                ></v-date-picker>
                            </v-menu>
                        </template>
                    </v-edit-dialog>
                </template>
                <template v-slot:item.image="props">
                    <v-edit-dialog
                        :return-value.sync="props.item.image"
                        large
                        persistent
                        @save="saveUpdatedImage(props)"
                        @cancel="cancel"
                        @open="open"
                        @close="close"
                    >
                        <v-img
                            :lazy-src="`${url}frontend/images/${props.item.image}`"
                            max-height="500"
                            max-width="300"
                            :src="`${url}/frontend/images/${props.item.image}`"
                        >
                        </v-img>
                        <template v-slot:input>
                            <div class="mt-4 text-h6">
                                Update image
                            </div>
                            <v-file-input
                                v-model="image"
                            ></v-file-input>
                        </template>
                    </v-edit-dialog>
                </template>
            </v-data-table>
        </v-tabs-items>
        <v-tabs-items v-if="tab === 'tab-2'">
            <v-card-text>
                <v-row
                    no-gutters
                >
                    <v-col>
                        <v-text-field
                            lg="12"
                            md="12"
                            sm="6"
                            v-model="searchDone"
                            class="mb-5"
                            clearable
                            label="Search..."
                            hide-details
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-data-table
                    :headers="headerSnag"
                    :items="getComSnags"
                    class="elevation-1"
                    style="width: 100%;"
                    :search="searchDone"
                    :single-select="singleSelect"
                    v-model="selectedDone"
                    mobile-breakpoint="0">
                    <template v-slot:item.description="props">
                        <div style="text-decoration: line-through;">{{ props.item.description }}</div>
                    </template>
                    <template v-slot:item.who="props">
                        <div v-for="(item, iIndex) in props.item.who" :key="iIndex" style="text-decoration: line-through;">{{ item }}</div>
                    </template>
                    <template v-slot:item.deadline="props">
                        <div style="text-decoration: line-through;">{{ props.item.created_at | formatDate }}</div>
                    </template>
                    <template v-slot:item.image="props">
                        <v-img
                            :lazy-src="`${url}frontend/images/${props.item.image}`"
                            max-height="500"
                            max-width="300"
                            :src="`${url}/frontend/images/${props.item.image}`"
                        >
                        </v-img>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-tabs-items>
        </v-card>
        <v-dialog
            v-model="snagModal"
            hide-overlay
            persistent
            scrollable
            max-width="600px"
            transition="dialog-bottom-transition"
        >
            <v-card>
            <v-toolbar
                dark
                color="#062a82"
            >
                <v-btn
                    icon
                    dark
                    @click="snagModal = false; fetchData();"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title><small>Add Snag Entry</small></v-toolbar-title>
            </v-toolbar>
            <v-divider></v-divider>
            <v-card-text style="height: 900px;">
                <v-list
                    three-line
                    subheader
                >
                    <v-subheader>Add A Snag</v-subheader>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title>Description</v-list-item-title>
                                <v-list-item-subtitle>
                                    <v-text-field
                                        filled
                                        name="input-7-1"
                                        v-model="snags.description"
                                        placeholder="Description"
                                    ></v-text-field>
                                </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title>Who</v-list-item-title>
                            <v-list-item-subtitle>
                                <v-combobox
                                    filled
                                    v-model="snags.who"
                                    :items="item_worker"
                                    :search-input.sync="whoSearch"
                                    hide-selected
                                    label="Add some people"
                                    multiple
                                    persistent-hint
                                    small-chips
                                >
                                    <template v-slot:no-data>
                                        <v-list-item>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    No results matching "<strong>{{ whoSearch }}</strong>". Press <kbd>enter</kbd> to create a new one
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </template>
                                </v-combobox>
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title>Deadline</v-list-item-title>
                            <v-list-item-subtitle>
                                <v-menu
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            filled
                                            v-model="snags.deadline"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="snags.deadline"
                                        @input="snagsAddCalModal = false"
                                    ></v-date-picker>
                                </v-menu>
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-subtitle>
                                <v-file-input
                                    filled
                                    label="Add Image"
                                    v-model="snags.image"
                                ></v-file-input>
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    light
                    text
                    @click="snagModal = false"
                >
                    <span class="material-icons-outlined">cancel</span> Cancel
                </v-btn>
                <v-btn
                    class="pl-8 pr-8"
                    color="#062a82"
                    dark
                    @click="saveSnags"
                >
                    <span class="material-icons-outlined">save</span> Save
                </v-btn>
            </v-card-actions>
            </v-card>
        </v-dialog>
        <v-tour name="myTour" :steps="steps"></v-tour>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  components: {
  },
  props: [
  ],
  data () {
    return {
        userId: null,
        item_worker: [],
        tab: '',
        role: null,
        search: '',
        searchDone: '',
        url: null,
        selected: [],
        selectedDone: [],
        singleSelect: true,
        snagModal: false,
        whoSearch: null,
        snagsAddCalModal: false,
        editCalModal: false,
        image: [],
        snags: {
            description: '',
            who: '',
            deadline: '',
            image: []
        },
        headerSnag: [
            { text: 'Description', value: 'description' },
            { text: 'Who', value: 'who' },
            { text: 'Deadline', value: 'deadline' },
            { text: 'Image', value: 'image' }
        ],
        steps: [
            {
                target: '#addItem',
                content: 'You can add new Snag Entry here'
            },
            {
                target: '#snagsCompletedBtn',
                content: 'You can find your Completed Snags here.'
            },
            {
                target: '#snagsTable',
                content: "Keep a record of any Snags that may need to be addressed here"
            }
        ],
        urlParams: 'empty'
    }
  },
  mounted () {
    if(localStorage.getItem('introTour') === null){
        this.$tours['myTour'].start()
        localStorage.setItem('introTour', true)
    }
  },
  created () {
    if(localStorage.getItem("token") === null){
        this.$router.push({name: 'index'})
        return
    }
    if(localStorage.getItem('project_id') !== null){
        this.urlParams = localStorage.getItem('project_id')
    }
    this.userId = localStorage.getItem('id')
    this.role = localStorage.getItem('role')
    this.url = process.env.VUE_APP_URL
    this.fetchData()
    this.fetchDoneData()
    this.fetchWho()
  },
  watch: {
    reloadFn(data){
        if(data){
            this.urlParams = localStorage.getItem('project_id')
            this.fetchDoneData()
            return this.fetchData()
        }
    }
  },
  computed: {
    ...mapGetters({
        snagsData:          'getSnags',
        // snagsWho:           'getSnagsWho',
        getComSnags:        'getDoneSnags'
    }),
    reloadFn(){
        return this.$store.getters['getReload']
    }
  },
  methods: {
    showSnackBar(message){
        this.$store.commit("setMessage", 
        {show: true, message: message}, 
        {root: 1})
    },
    async fiModSnag(){

        this.snagModal = true
        this.fetchProjectWorker();

    },
    async saveSnags(){
        const formData = new FormData()
        formData.append('description', this.snags.description)
        formData.append('who', this.snags.who)
        formData.append('deadline', this.snags.deadline)
        formData.append('file', this.snags.image)
        await this.$axios.post(`aSnag/${this.urlParams}`, formData)
        .then(({data}) => {
            if(data.response){
                this.snags = {
                    description: '',
                    who: '',
                    deadline: '',
                    image: []
                }
                this.snagModal = false
                this.$store.dispatch('setSnags', data.data)
                this.fetchWho()
            }else{
                this.showSnackBar(data.message)
            }
        })
    },
    async fetchData(){
        await this.$axios.get(`gSnags/${this.urlParams}`)
        .then(({data}) => {
            /*if(data.data.length === 0 && this.role == 1){
                this.generateTemplate()
                return
            }*/
            if(data.response){
                this.$store.dispatch('setSnags', data.data)
                this.$store.dispatch('setDrawer', true)
                this.$store.dispatch('setReload', false)
            }
        })
    },
    async generateTemplate(){
        this.showSnackBar('Generating Template, please wait...')
        await this.$axios.get(`gtSnags/${this.urlParams}`)
        .then(({data}) => {
            if(data.response){
                this.showSnackBar(data.message)
                this.fetchData()
            }
        })
    },
    async fetchDoneData(){
        await this.$axios.get(`gCSnags/${this.urlParams}`)
        .then(({data}) => {
            this.$store.dispatch('setDoneSnags', data.data)
        })
    },
    async fetchWho(){
        await this.$axios.get('gSnagsWho')
        .then(({data}) => {
            const toWho = []
            data.data.forEach(q => {
              toWho.push(q.who)
            })
            this.$store.dispatch('setSnagsWho', toWho)
        })
    },
    cancel(){},
    open(){},
    close(){},
    async saveEditedDescription(data){
        await this.$axios.patch(`uSnagsDescription/${this.urlParams}`, {data: data.item})
        .then(({data}) => {
            if(data.response){
                this.showSnackBar(data.message)
                this.$store.dispatch('setSnags', data.data)
            }else{
                this.showSnackBar(data.message)
            }
        })
    },
    async saveEditedWho(data){
        await this.$axios.patch(`uSnagWho/${this.urlParams}`, {data: data.item})
        .then(({data}) => {
            if(data.response){
                this.showSnackBar(data.message)
                this.$store.dispatch('setSnags', data.data)
            }else{
                this.showSnackBar(data.message)
            }
        })
    },
    async saveEditedDeadline(data){
        await this.$axios.patch(`uSnagDeadline/${this.urlParams}`, {data: data.item})
        .then(({data}) => {
            if(data.response){
                this.showSnackBar(data.message)
                this.$store.dispatch('setSnags', data.data)
            }else{
                this.showSnackBar(data.message)
            }
        })
    },
    async saveUpdatedImage(data){
        const formData = new FormData()
        formData.append('id', data.item.id)
        formData.append('file', this.image)
        await this.$axios.post(`uSnagImage/${this.urlParams}`, formData)
        .then(({data}) => {
            if(data.response){
                this.showSnackBar(data.message)
                this.$store.dispatch('setSnags', data.data)
            }else{
                this.showSnackBar(data.message)
            }
        })
    },
    async triggerComplete(){
        await this.$axios.delete(`markDoneRecord/${this.selected[0].id}/${this.urlParams}`)
        .then(({data}) => {
            if(data.response){
                this.showSnackBar(data.message)
                this.$store.dispatch('setSnags', data.data)
                this.$store.dispatch('setDoneSnags', data.done)
                this.selected = []
            }else{
                this.showSnackBar(data.message)
            }
        })
    },
    async deleteDone(){
        await this.$axios.delete(`fDelete/${this.selectedDone[0].id}`)
        .then(({data}) => {
            if(data.response){
                this.showSnackBar(data.message)
                this.$store.dispatch('setSnags', data.data)
                this.$store.dispatch('setDoneSnags', data.done)
                this.selectedDone = []
            }else{
                this.showSnackBar(data.message)
            }
        })
    },

    async fetchProjectWorker(){

        let payload = {
          user_id: this.userId,
          project_id: this.urlParams
        };

        await this.$axios.get('/snag/fetch/projectworker', payload).then(({data}) => {
                    
          console.log(data);

          if(data.success){

            this.item_worker = [];
            data.worker.forEach(value => {
              this.item_worker.push(value.email);
            });

          }

        });

    }

  },
}
</script>

<style scoped lang="scss">
</style>