
export const tour_guide = () => {

    const no_select_project_tour_guide = () => {
        const steps = [
            {
                target: '#start_step_0',
                title: `Let's Get Started!`,
                content: `Welcome to our project management software, designed for maximum project profitability.`
            },
            {
                target: '#start_step_1',
                title: `Choose A Project`,
                content: `Begin by selecting an existing project or creating a new one.`               
            },
            {
                target: '#start_step_2',
                title: `Add a New Project`,
                content: `Click here to create a new project.`,               
                params: {
                    hightlight: true
                }
            }
        ]

        return steps
    }

    const create_project_tour_guide = () => {
        const steps = [
            {
                target: '#start_step_0',
                title: `Fill in the initial project details:`,
                content: `Project Name`,
                params: {
                    enableScrolling: false
                }
            },
            {
                target: '#start_step_1',
                title: `Fill in the initial project details:`,
                content: `Project Location`,
                params: {
                    enableScrolling: false
                }
            },
            {
                target: '#start_step_2',
                title: `Fill in the initial project details:`,
                content: `Project Schedule (Start Date & Completion Date)`,
                params: {
                    placement: 'top',
                    enableScrolling: false
                }
            },
            {
                target: '#start_step_3',
                title: `Fill in the initial project details:`,
                content: `Working Days (Business Days)`
            },            
            {
                target: '#start_step_4',
                title: `Fill in the initial project details:`,
                content: `Contract Price (VAT Included)`
            },
            {
                target: '#start_step_5',
                title: `Fill in the initial project details:`,
                content: `Project Manager`
            },
            {
                target: '#start_step_6',
                title: `Fill in the initial project details:`,
                content: `Total Floor Area (Construction Area)`
            },
            {
                target: '#start_step_7',
                title: `Fill in the initial project details:`,
                content: `Click "Create" to save the project details.`
            },
        ]

        return steps
    }
    
    const cost_estimation_part1_tour_guide = () => {
        const steps = [
            {
                target: '#start_step_0',
                title: `Cost Estimation`,
                content: `Add your cost estimation or quotation here.`,
            },
            {
                target: '#start_step_1',
                title: `Cost Estimation`,
                content: `Select a template or create your own using the Blank Template.`
            },
            /*{
                target: '#start_step_2',
                title: `Cost Estimation`,
                content: `Click "Use Template" if you're satisfied with your selection.`
            },*/
            
            /**
             *  Continue filling out? Yes or No?
                If Yes, leave the tour to resume once done.
                If No, continue with the tour.
             */
        ]

        return steps
    }

    const cost_estimation_part1_1_tour_guide = () => {
        return [
            {
                target: '#start_step_2',
                title: `Cost Estimation`,
                content: `Click "Use Template" if you're satisfied with your selection.`
            }
        ]
    }
    const cost_estimation_part2_tour_guide = () => {
        return [
            {
                target: '#start_step_3',
                title: `Fill in Cost Estimates`,
                content: `Click "Add Section."`
            },
            {
                target: '#start_step_6',
                title: `Fill in Cost Estimates`,
                content: `Click "+" to add more items.`,
                params: {
                    enableScrolling: false
                }
            },
            {
                target: '#start_step_9',
                title: `Fill in Cost Estimates`,
                content: `Click here to expand and click the trash icon from the list below to delete if needed.`,
                params: {
                    enableScrolling: false
                }
            },
            {
                target: '#start_step_10',
                title: 'Fill in Cost Estimates',
                content: ``,
                is_decision: true,
                params: {                    
                    enableScrolling: false
                }
            },
            {
                target: '#start_step_11',
                title: 'Project Planner',
                content: 'Click on the Nav Bar.',
                params: {                    
                    enableScrolling: false
                }
            },
            {
                target: '#start_step_12',
                title: `Project Planner`,
                content: `Click on Project Planner to synchronize Bill of Quantities.`
            },
        ]
    }

    const cost_estimation_part2_1_tour_guide = () => {
        return [
            {
                target: '#start_step_4',
                title: `Fill in Cost Estimates`,
                content: `Add Item No. and Description.`
            },
            {
                target: '#start_step_5',
                title: `Fill in Cost Estimates`,
                content: `Click the Save Icon.`
            },
        ]
    }

    const cost_estimation_part3_tour_guide = () => {
        return [
            {
                target: '#start_step_6',
                title: `Fill in Cost Estimates`,
                content: `Click "+" to add more items.`,
                params: {
                    enableScrolling: false
                }
            },
        ]
    }

    const cost_estimation_part4_tour_guide = () => {
        return [
            {
                target: '#start_step_7',
                title: `Fill in Cost Estimates`,
                content: `Add Item No., Description, Unit, Quantity, and Rate.`
            },
            {
                target: '#start_step_8',
                title: `Fill in Cost Estimates`,
                content: `Click the Save Icon.`
            },
        ]
    }

    const cost_estimation_part5_tour_guide = () => {
        return [
            {
                target: '#start_step_9',
                title: `Fill in Cost Estimates`,
                content: `Click here to expand and click the trash icon from the list below to delete if needed.`,
                params: {
                    enableScrolling: false
                }
            },
            {
                target: '#start_step_10',
                title: 'Fill in Cost Estimates',
                content: ``,
                is_decision: true,
                params: {                    
                    enableScrolling: false
                }
            },
            {
                target: '#start_step_11',
                title: 'Project Planner',
                content: 'Click on the Nav Bar.',
                params: {                    
                    enableScrolling: false
                }
            },
            {
                target: '#start_step_12',
                title: `Project Planner`,
                content: `Click on Project Planner to synchronize Bill of Quantities.`
            },
        ]
    }

    const project_planner_tour_guide = () => {
        const steps = [
            {
                target: '#start_step_13',
                title: `Project Planner`,
                content: `Click Yes to synchronize item descriptions.`
            },
            {
                target: '#start_step_14',
                title: `Project Planner`,
                content: `Click No if you don't want to synchronize item descriptions.`
            },
            /**
             *  Continue filling Project Planner? Yes or No?
             *  If Yes, leave the tour to resume once done.
             *  If No, continue with the tour.
             */
        ]

        return steps
    }

    const project_planner_part_1_tour_guide = () => {
        return [
            {
                target: '#start_step_15',
                title: `Project Planner Tasks`,
                content: `Click "Task" to view the Gantt Chart.`,
            },
        ]
    }

    const project_planner_part_2_tour_guide = () => {
        return [
            {
                target: '#add',
                title: `Project Planner Tasks`,
                content: `Click "+" to add more tasks.`
            },
            {
                target: '#start_step_17',
                title: `Project Planner Tasks`,
                content: `Click here to edit task information`
            },
            {
                target: '#start_step_19',
                title: `Project Planner Tasks`,
                content: `Add description.`
            },
            {
                target: '#start_step_19',
                title: `Project Planner Tasks`,
                content: `Set time period.`
            },
            {
                target: '#start_step_20',
                title: `Project Planner Tasks`,
                content: `Specify task duration.`
            },
            {
                target: '#start_step_21',
                title: `Project Planner Tasks`,
                content: `Set task status.`
            },
            {
                target: '#start_step_22',
                title: `Project Planner Tasks`,
                content: `Save, cancel, or delete as needed.`
            },
        ]
    }

    const profile_tour = () => {
        const steps = [
            {
                target: '#addReminderIntro',
                content: 'You can add your Reminders here.',
                title: 'Profile'
            },
            {
                target: '#reminders',
                content: 'Your Reminders are displayed on your home screen, to ensure they are never overlooked.',
                title: 'Profile'
            },
            {
                target: '#completedReminderBtn',
                content: 'You can find your completed reminders here.',
                title: 'Profile'
            },
            {
                target: '#siteDiaryButton',
                content: 'Keep a log of your site visits here.',
                title: 'Profile'
            },
            {
                target: '#budgetManagerButton',
                content: 'Keep a record of your entire Build\'s Budget here.',
                title: 'Profile'
            },
            {
                target: '#fileManagerButton',
                content: 'Access all your most important Self Build documents here.',
                title: 'Profile'
            },
            {
                target: '#projectPlannerButton',
                content: 'Here you will find the Project Planner, with a built in typical "Construction Schedule" template, to help keep your Self Build on track.',
                title: 'Profile'
            },
            {
                target: '#snagsButton',
                content: 'Keep a record of any Snags that may need to be addressed here.',
                title: 'Profile'
            },
            {
                target: '#profileEditBtn',
                content: 'You can edit your details here.',
                title: 'Profile'
            },
        ]

        return steps
    }

    return { 
        tour_start: no_select_project_tour_guide,
        tour_profile: profile_tour,  
        tour_createproject: create_project_tour_guide,
        tour_costestimation_part1: cost_estimation_part1_tour_guide,
        tour_costestimation_part1_1: cost_estimation_part1_1_tour_guide,
        tour_costestimation_part2: cost_estimation_part2_tour_guide,
        tour_costestimation_part2_1: cost_estimation_part2_1_tour_guide,
        tour_costestimation_part3: cost_estimation_part3_tour_guide,
        tour_costestimation_part4: cost_estimation_part4_tour_guide,
        tour_costestimation_part5: cost_estimation_part5_tour_guide,
        tour_projectplanner: project_planner_tour_guide,
        tour_projectplanner_part_1: project_planner_part_1_tour_guide,
        tour_projectplanner_part_2: project_planner_part_2_tour_guide
    }

}