<template>
  <v-container>
    <v-card
      class="mx-auto my-3"
    >
      <v-card-title style="background: #e3e3e4 !important;">
        <small>
          <span style="position: relative; top: 5px;" class="material-icons-outlined mr-1">
            book
          </span>
            DAILY LOGS

        </small>
      </v-card-title>

      <!-- <v-card-text>
        <v-row dense>
          <v-col cols="12">
            <v-btn
              depressed
              color="primary"
              class="mr-5 mt-5"
            >
              Budget Manager
            </v-btn>
            <v-btn
              depressed
              color="primary"
              class="mt-5"
            >
              Cost Estimation
            </v-btn>
          </v-col>  
        </v-row>
      </v-card-text> -->

      <v-card-text>
        <v-row dense>
          <v-col cols="12" v-if="cost_estimation_template.length !== 0">
            <v-data-table
              :headers="headersDailyLogsCostEstimation"
              :items="cost_estimation_template"
              :expanded.sync="expanded"
              :options.sync="pagination"
              item-key="item"
              disable-sort
              class="elevation-1 mt-5"
            >
              <template v-slot:item="{ headers, item, expand, isExpanded, index }">
                <tr
                  :colspan="headers.length"
                  @click="expand(!isExpanded)"
                  style="cursor: pointer;background-color:#f0f0f0"
                >
                  <td><v-icon small v-if="selected_item_no.predecessor == item.item">mdi-circle-medium</v-icon></td>
                  <td>{{ item.item }}</td>
                  <td>{{ item.name }}</td>
                  <td></td>
                  <td><v-icon v-if="cost_estimation_child_data[(pagination.page-1)*pagination.itemsPerPage + index].length !== 0">{{ isExpanded ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon></td>
                </tr>
              </template>
              
              <template v-slot:expanded-item="{ headers, index }">
                <tr
                  v-for="(i,_index) in cost_estimation_child_data[(pagination.page-1)*pagination.itemsPerPage + index]"
                  :key="i.id"
                  :colspan="headers.length"
                  class="childData"
                  :class="{ active : selected_item_no.item == cost_estimation_child_data[(pagination.page-1)*pagination.itemsPerPage + index][_index].item }"
                  @click="rowClick(cost_estimation_child_data[(pagination.page-1)*pagination.itemsPerPage + index][_index])"
                  style="cursor: pointer;"
                >
                  <td class="pe-0"><v-icon small dark v-if="selected_item_no.item == cost_estimation_child_data[(pagination.page-1)*pagination.itemsPerPage + index][_index].item">mdi-check-bold</v-icon></td>
                  <td>{{ cost_estimation_child_data[(pagination.page-1)*pagination.itemsPerPage + index][_index].item }}</td>
                  <td>{{ cost_estimation_child_data[(pagination.page-1)*pagination.itemsPerPage + index][_index].name }}</td>
                  <td>{{ cost_estimation_child_data[(pagination.page-1)*pagination.itemsPerPage + index][_index].unit }}</td>
                  <td></td>
                </tr>
              </template>
            </v-data-table>
          </v-col>
          <v-col cols="12" v-else>
            <v-data-table
              :headers="headersDailyLogsCostEstimation"
              :expanded.sync="expanded"
              :options.sync="pagination"
              item-key="item"
              disable-sort
              class="elevation-1 mt-5"
            >
            </v-data-table>
          </v-col>
          
          <v-col cols="12" v-if="selected_item_no.length !== 0">
            <v-col cols="12">
              <v-text-field
                label="Rate Per Hour"
                v-model="rate_per_hour"
                v-if="role==1"
                class="mt-3"
              ></v-text-field>
            </v-col>
            
            <v-col cols="12">
              <v-text-field
                label="Working Hours"
                v-model="working_hours"
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-text-field
                label="Material Bought"
                placeholder="Ex. Wood, Hammer, Nails"
                v-model="material_bought"
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-text-field
                label="Material Price"
                placeholder="Ex. 10, 20, 25"
                v-model="material_price"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-file-input
                show-size
                multiple
                truncate-length="15"
                placeholder="Receipts Picture Upload"
                v-model="upload_images"
              ></v-file-input>
            </v-col>

            <v-col cols="12">
              <v-text-field
                label="Comments / Notes / Suggestions"
                v-model="remarks"
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-btn
                depressed
                block
                color="primary"
                @click="saveWorkerLogsInformation()"
              >
                Save Daily Logs
              </v-btn>
            </v-col>
          </v-col>
          <v-col cols="12" v-else-if="cost_estimation_template.length !== 0">
            <br>
            Please select an item
          </v-col>
        </v-row>
      </v-card-text>

    </v-card>

    <!-- <v-tour name="myTour" :steps="steps"></v-tour> -->

  </v-container>
</template>

<script>
// import { mapGetters } from 'vuex';
  export default {
    data: () => ({
      userId: null,
      urlParams: 'empty',
      role: 0,
      template: [],
      selected_template_info: [],
      rate_per_hour: "",
      working_hours: "",
      complete_work: "",
      description: "",
      material_bought: "",
      material_price: "",
      upload_images: [],
      images_length: 0,
      remarks: "",
      project_id: 0,
      expanded: [],
      pagination: {},
      selected_item_no: [],
      cost_estimation_template: [],
      cost_estimation_child_data: [],
      headersDailyLogsCostEstimation: [
        {text: '', value: 'select', width: '3%', class: "grey lighten-2"},
        {text: 'Item No.', value: 'item', width: '8%', class: "grey lighten-2"},
        {text: 'Description', value: 'name', width: '30%', class: "grey lighten-2"},
        {text: 'Unit', value: 'unit', width: '5%', class: "grey lighten-2"},
        {text: '', value: 'dropdown', width: '1%', class: "grey lighten-2"}
      ],
    }),
    mounted () {
      // if(localStorage.getItem('introBudgetManager') === null){
      //   this.$tours['myTour'].start()
      //   localStorage.setItem('introBudgetManager', true)
      // }
    },
    created () {
      if(localStorage.getItem("token") === null){
        this.$router.push({name: 'index'});
        return
      }
      if(localStorage.getItem('project_id') !== null){
        this.urlParams = localStorage.getItem('project_id');
      }
      if(localStorage.getItem('role') !== null){
        this.role = localStorage.getItem('role');
      }
      this.userId = localStorage.getItem('id');
      this.$store.dispatch('setDrawer', true);
      this.project_id = localStorage.getItem('project_id');
      this.fetchChosenTemplate();
      // this.fetchUserProjectInformation();
    },
    watch: {
      reloadFn(data){
        if(data){
          this.project_id = localStorage.getItem('project_id');
        }
      }
    },
    computed: {
      reloadFn(){
        return this.$store.getters['getReload'];
      },
    },
    methods: {
          showSnackBar(message){
          this.$store.commit("setMessage", 
            {
              show: true, 
              message: message
            }, 
            {
              root: 1
            }
          );
        },
        rowClick(item){
          this.selected_item_no = item
        },
        ExpandSection(section){
          this.expanded = []
          this.expanded.push(section)
        },
        fetchChosenTemplate(){
          let payload = {
            user_id: this.userId,
            project_id: this.urlParams,
            role: this.role,
          }
          this.showSnackBar("Loading template...")
          this.$axios.get('/budgetmanager/fetch/chosentemplateworker', payload)
          .then(({data}) => {
            this.selected_template = data.template;
            this.FetchCostEstimationTemplate();
          });
        },
        FetchCostEstimationTemplate(){
          let payload = {
            user_id: this.userId,
            project_id: this.urlParams,
            template_id: this.selected_template.id,
            template_name: this.selected_template.template_name,
            template_info: this.selected_template_info.value
          };
          this.$axios.get('/costestimation/data', payload)
          .then(({data}) => {
            if(data.list_data){
              this.cost_estimation_template = data.list_data
              this.cost_estimation_child_data = data.children
            }
            else{
              this.cost_estimation_template = [];
            }
            this.showAddCostEstimationEntryButton = true;
          });
        },
        fetchUserProjectInformation(){
          let payload = {
              user_id: this.userId
          };

          this.$axios.get('/workerlogs/project', payload).then(({data}) => {
            if(data.success){
              data.result.forEach(value => {
                this.template.push({
                  text: value.template_name,
                  value: value.id
                });
              });
            }
          });
        },
        saveWorkerLogsInformation(){
          const formData = new FormData();
          formData.append('template_id', this.selected_template.id);
          formData.append('project_id', this.project_id);
          formData.append('user_id', this.userId);
          formData.append('role', this.role);
          formData.append('rate_per_hour', this.rate_per_hour);
          formData.append('working_hours', this.working_hours);
          formData.append('item', this.selected_item_no.item);
          formData.append('item_name', this.selected_item_no.name);
          formData.append('item_id', this.selected_item_no.id);
          formData.append('material_bought', this.material_bought);
          formData.append('material_price', this.material_price);
          formData.append('remarks', this.remarks);
          for(let i=0;i<this.upload_images.length;i++){
            formData.append('images_' + i, this.upload_images[i]);
          }
          formData.append('images_count', this.upload_images.length);
          this.$axios.post('/workerlogs/save', formData)
          .then(({data}) => {
            if(data.success){
              this.showSnackBar(data.message)
              this.ClearDailyLogs()
              this.selected_item_no = []
            }
            else{
              this.showSnackBar(data.message)
            }
          });
        },
        ClearDailyLogs(){
            this.selected_template_info = [];
            this.rate_per_hour = "";
            this.working_hours = "";
            this.complete_work = "";
            this.description = "";
            this.material_bought = "";
            this.material_price = "";
            this.upload_images = [];
            this.remarks = "";
        }
        // OpenNewTemplate(){
        //     this.new_template = true;
        //     this.template_name = "";
        // },
        // CloseNewTemplate(){
        //     this.new_template = false;
        // },
        // async SaveTemplateInformation(){

        //     if(this.template_name==""){
        //         this.showSnackBar("Please input a template name.");
        //     }
        //     else{

        //         let payload = {
        //             user_id: this.userId,
        //             project_id: this.urlParams,
        //             template_name: this.template_name
        //         };

        //         await this.$axios.post('/budgetmanager/save/template', payload).then(({data}) => {
        //             console.log(data);
        //             if(data.success){
        //                 this.FetchTemplates();
        //                 this.CloseNewTemplate();
        //             }
        //         });

        //     }

        // },
        // FetchTemplates(){
            
        //     let payload = {
        //         user_id: this.userId,
        //         project_id: this.urlParams
        //     };

        //     this.$axios.get('/budgetmanager/fetch/template', payload).then(({data}) => {

        //         if(data.success){

        //             this.template = [];

        //             data.template.forEach(value => {
        //               this.template.push({
        //                   text: value.template_name,
        //                   value: value.id
        //               });
        //             });

        //         }

        //     });

        // },
        // async FetchTemplateInformation(){

        //     let payload = this.selected_template_info;

        //     await this.$axios.get('/budgetmanager/fetch/templateinformation', payload).then(({data}) => {

        //         console.log(data);

        //         if(data.success){
        //             this.selected_template = data.template;
        //             this.ReloadShowAllTemplateChild();
        //         }

        //     });

        // },
        // OpenNewTemplateChild(){
        //     this.new_child = {};
        //     this.new_template_child = true;
        // },
        // CloseNewTemplateChild(){
        //     this.new_template_child = false;
        // },
        // async SaveTemplateChildInformation(){

        //     if(typeof this.new_child.item_description === "undefined"){
        //         this.showSnackBar("Please input a item description.");
        //     }
        //     else if(typeof this.new_child.budget === "undefined"){
        //         this.showSnackBar("Please input the budget.");
        //     }
        //     else if(typeof this.new_child.actual_price === "undefined"){
        //         this.showSnackBar("Please input an actual price.");
        //     }
        //     else if(typeof this.new_child.new_date_paid === "undefined"){
        //         this.showSnackBar("Please select a date to paid.");
        //     }
        //     else{

        //         let payload = {
        //             project_id: this.urlParams,
        //             user_id: this.userId,
        //             parent_id: this.selected_template.id,
        //             item_description: this.new_child.item_description,
        //             budget: this.new_child.budget,
        //             actual_price: this.new_child.actual_price,
        //             date_paid: this.new_child.new_date_paid
        //         };

        //         await this.$axios.post('/budgetmanager/save/templatechild', payload).then(({data}) => {

        //             console.log(data);

        //             if(data.success){
        //                 this.selected_template = data.template;
        //                 this.CloseNewTemplateChild();
        //                 this.ReloadShowAllTemplateChild();
        //             }

        //         });

        //     }

        // },
        // ReloadShowAllTemplateChild(){

        //     if(this.is_template_show_all){
        //         this.template_show = this.selected_template.budget_chlid_info.length;
        //     }

        // },
        // ShowAllTemplateChild(){

        //     if(this.is_template_show_all){
        //         this.show_all_text = "Show All";
        //         this.template_show = this.template_limit;
        //     }
        //     else{
        //         this.show_all_text = "Hide";
        //         this.template_show = this.selected_template.budget_chlid_info.length;
        //     }

        //     this.is_template_show_all = !this.is_template_show_all;

        // },
        // UpdateTemplateChildInformation(label, value, id, props){

        //     let payload = {
        //         label: label,
        //         value: value
        //     };

        //     this.$axios.post('/budgetmanager/update/templatechild/' + id, payload).then(({data}) => {
        //         if(data.success){
        //             console.log(data);
        //             props.variation = data['variation']['variation'];
        //             props.percent = data['variation']['percent'];
        //             props.outstanding = data['variation']['outstanding'];
        //         }
        //     });

        // },
        // async RemoveTemplateChild(data){

        //     let payload = {
        //         parent_id: this.selected_template.id,
        //         id: data.id
        //     };

        //     await this.$axios.post('/budgetmanager/delete/templatechild', payload).then(({data}) => {
        //         if(data.success){
        //             console.log(data);
        //             this.selected_template = data.template;
        //             this.ReloadShowAllTemplateChild();
        //         }
        //     });

        // },
        // Section(section){
        //     this.section = section;
        // },
        // FetchCostEstimationTemplate(){

        //     let payload = {
        //         user_id: this.userId,
        //         project_id: this.urlParams
        //     };

        //     this.$axios.get('/costestimation/fetch/template', payload).then(({data}) => {

        //         if(data.success){
        //             this.cost_estimation_template = data.cost_estimation;
        //         }

        //     });

        // },
        // UpdateEstimatedCostBudgetAmount(){

        //     let payload = {
        //         user_id: this.userId,
        //         project_id: this.urlParams,
        //         budget_amount: this.cost_estimation_budget_amount
        //     };

        //     this.$axios.post('/costestimation/update/budgetamount', payload).then(({data}) => {

        //         if(data.success){
        //             this.FetchCostEstimationTemplate();
        //             this.cost_estimation_budget_amount = "";
        //         }
        //         else{
        //             this.showSnackBar(data.message);
        //         }

        //     });

        // },
        // UpdateCostEstimationChildInformation(label, value, id, props){

        //     let payload = {
        //       label: label,
        //       value: value,
        //       id: id
        //     };

        //     this.$axios.post('/costestimation/update/templatechild', payload).then(({data}) => {

        //         if(data.success){
        //             this.FetchCostEstimationTemplate();
        //             this.cost_estimation_budget_amount = "";
        //             props.amount = data['data']['amount'];
        //         }
        //         else{
        //             this.showSnackBar(data.message);
        //         }

        //     });

        // }
    }
  }
</script>
<style scoped lang="scss">
// css part para sa page na ito
.costEstimationSection {
  background-color:#f0f0f0
}
.childData {
  background-color:rgb(255, 255, 255)!important
}
.active {
  background-color:rgb(106, 132, 155)!important;
  font-weight:bold;
  color:white;
}
</style>