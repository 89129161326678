<template>
    <v-row justify="center">
        <v-dialog
            v-model="sync_from_boq_dialog"
            max-width="600">
            <v-card>
                <v-card-title>
                    <v-icon class="mr-2">mdi-sync</v-icon>Synchronize Bill of Quantities to Project Planner.
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text class="pt-6">
                    Do you want to sync your Bill of Quantities into your Project Task? Note: This can only be done
                    one time and it will not ask again. The Bill of Quantities can be found under Cost Estimation Items.
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text color="success" class="px-16" @click="evnt_syncfromboq_to_pp()" id="start_step_13">Yes</v-btn>
                    <v-btn color="primary" class="px-16" id="start_step_14" @click="evnt_close_dialog()">No</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import { SyncFromBOQ } from '../classes/syncfromboq.js';

export default {
    data: () => ({
        sync_from_boq_dialog: false,
        syncfromboq: null
    }),
    props: {
        evnt_open_dialog: Boolean
    },
    created() {
        this.syncfromboq = new SyncFromBOQ(localStorage.getItem('project_id'), localStorage.getItem('id'))
    },
    methods: {
        evnt_close_dialog() {
            this.syncfromboq.save_syncfromboq_to_pp_config(0)
            this.$emit('evnt_close_dialog');
        },
        evnt_syncfromboq_to_pp() {
            this.syncfromboq.save_syncfromboq_to_pp_config(1)
            this.evnt_close_dialog();
        }
    },
    watch: {
        evnt_open_dialog() {
            this.sync_from_boq_dialog = this.evnt_open_dialog;
        }
    }
}
</script>