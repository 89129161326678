<template>
  <v-app>
    <v-main>
      <v-snackbar
        v-model="notification"
        :timeout="5000"
        :value="true"
        color="blue-grey"
        fixed
        right
        shaped
        top
      >
        <span class="material-icons-outlined mr-1">
          notifications
        </span><h2>Quote Receive</h2><br/>
        <label>Project Name: {{notifDetails.desc}}</label><br/>
        <label>Message: {{notifDetails.message}}</label><br/>
        <label>From: {{notifDetails.from}}</label><br/>
      </v-snackbar>

      <!-- message notification -->
      
      <v-snackbars
        :messages.sync="messages"
        color="#326fd1"
        top
        right
        timeout="8000"
      >
        <template v-slot:default="{ message }">
          <div style="max-width:300px">
            <div class="d-flex">
              <span class="material-icons-outlined mr-1">notifications</span>
              <h3 class="mb-2">New Message</h3>
            </div>
            <h5 class="mb-2">Project: {{ message.project }}</h5>
            <p class='mb-0 ms-7'>{{ message.from }}</p>
            <h3 class="ms-7" style="font-weight:bold">{{ message.message }}</h3>
          </div>
        </template>
        <template v-slot:action="{ close }">
          <v-btn icon @click="close()">x</v-btn>
        </template>
      </v-snackbars>

      <v-snackbar
        v-model="messageNotification"
        :timeout="5000"
        color="blue-grey"
        fixed
        right
        shaped
        top
        class="mt-15"
      >
        <label>You have a new message, go and check inbox</label>
      </v-snackbar>
      
      <!--<v-snackbar
        v-model="messageNotification"
        :timeout="5000"
        color="blue-grey"
        fixed
        right
        shaped
        top
        class="mt-15"
      >
        <div class="d-flex">
          <span class="material-icons-outlined mr-1">notifications</span>
          <h2 style="padding-top:2px">New Message</h2>
          <button @click="messageNotification = false" icon style="float:right;">
            <span class="material-icons-outlined">mdi-close</span>
          </button>
        </div>
        
        <br/>
        <label>Project: {{notifDetails.project_name}}</label><br/>
        <label>From: {{notifDetails.from}}</label><br/>
        <label>Message: {{notifDetails.message}}</label><br/>
      </v-snackbar>-->
      <!-- end notification -->
      <v-snackbar v-model="mSnackbar.show" bottom="bottom" multi-line="multi-line" :timeout="4000" :vertical="true">
        {{ mSnackbar.message }}
      </v-snackbar>
      <HelloWorld @ShowMessage='showMessage'/>     
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
import HelloWorld from './components/HelloWorld';
import VSnackbars from "v-snackbars";

export default {
  name: 'App',

  components: {
    HelloWorld,
    "v-snackbars": VSnackbars,    
  },

  data: () => ({
    notification: false,
    messageNotification: false,
    messages: [],
    notifDetails: {},
    url: null,
    // soundurl: new Audio(
    //   'https://www.freesoundslibrary.com/wp-content/uploads/2018/01/ding-sound-effect.mp3'
    // ),
  }),
  async mounted(){
    await this.$echo.private('quote-notification.' + localStorage.getItem('id'))
    .listen('QuoteNotification', (payload) => {
      this.notifDetails = payload
      this.showNotif()
    });

    await this.$echo.private('message-notification.' + localStorage.getItem('id'))
    .listen('ChatMessage', (payload) => {
      this.notifDetails = payload
      this.showMessage()
    })

    await this.$echo.private('reload-message.' + localStorage.getItem('id'))
    .listen('ReloadMessages', (payload) => {
      this.notifDetails = payload
      console.log(payload)
      this.showMessage()
    })

    /*if(localStorage.getItem('token') === null || localStorage.getItem('token') !== null){
      await this.$axios.get('auth/is_auth')
      .then(({data}) => {
        if(data.message === 'Unauthenticated.'){
          this.logout()
        }
      })
    }*/
  },
  created () {
    this.url = process.env.VUE_APP_URL
    // if (localStorage.getItem('token') != null){
    //   this.soundurl.volume = 0.5
    //   this.soundurl.play()
    // }
  },
  computed: {
    ...mapGetters(['mSnackbar'])    
  },
  methods : {
    showNotif(){
      const soundurl = new Audio(
        'https://www.freesoundslibrary.com/wp-content/uploads/2018/01/ding-sound-effect.mp3'
      )
      this.notification = true
      soundurl.volume = 0.5;
      soundurl.play()
    },
    showMessage(){
      this.$store.dispatch('setGrabConvo', true)
      const soundurl = new Audio(
        'https://www.freesoundslibrary.com/wp-content/uploads/2018/01/ding-sound-effect.mp3'
      )
      //this.messageNotification = true
      this.messages.push(this.notifDetails)
      soundurl.volume = 0.5;
      soundurl.play()
    },
    // showMessage(message) {
    //   this.messages.push(message)
    // },
    async logout(){
      this.$axios.post('auth/logout')
      localStorage.clear()
      this.$router.push({name: 'index'})
    }
  }
};
</script>

<style>
@import "~vue-wysiwyg/dist/vueWysiwyg.css";
.v-snackbars {
  padding-top:60px!important;
}
</style>