import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router.js'
import axios from './plugins/axios.js'
import store from './states/index.js'
import date from 'datejs'
import numeral from 'numeral'
import VueTour from 'vue-tour'
import Pusher from "pusher-js"
import VueEcho from "vue-echo-laravel"
import VSnackbars from "v-snackbars"
import wysiwyg from "vue-wysiwyg"
import TourView from "./modules/tour_guide/components/TourView.vue"

require('vue-tour/dist/vue-tour.css')
// import vImage from '@ispa.io/vimage';

Vue.filter('formatDate', function(value) {
  if (value) {
    return Date.parse(value).format('d M, Y')
  }
});

Vue.filter('formatDateChat', function(value) {
  if (value) {
    return Date.parse(value).format('d M, Y H:m')
  }
});

Vue.filter("formatNumber", function (value) {
  return numeral(value).format("0,0.00"); // displaying other groupings/separators is possible, look at the docs
});

Vue.filter("bytes", function(value){
  return numeral(value).format('0b')
})

Vue.component("TourView", TourView)

// Enable pusher logging - don't include this in production
// Pusher.logToConsole = false;
Pusher.logToConsole = true;
const token = JSON.parse(localStorage.getItem('token'))
Vue.use(VueEcho, {
  broadcaster: "pusher",
  key: process.env.VUE_APP_PUSHER_KEY,
  cluster: process.env.VUE_APP_PUSHER_CLUSTER,
  forceTLS: true,
  authEndpoint: process.env.VUE_APP_API_URL + "broadcasting/auth",
  auth: {
    headers: {
      Accept: 'application/json',
      Authorization: 'Bearer ' + token, // Enabled - If you are using Bearer for authentication
    }
  }
});

Vue.use(axios)
Vue.use(date)
Vue.use(numeral)
Vue.use(VueTour)
Vue.use(VSnackbars)
Vue.use(wysiwyg, {});
Vue.config.productionTip = false

new Vue({
  router: router,
  vuetify,
  store: store,
  render: h => h(App)
}).$mount('#app')
