<template>
  <div>
    <v-app-bar
      color="#062a82"
      dark
      fixed
    >
      <v-app-bar-nav-icon v-if="userId != null" @click="drawer = true; move_next()" id="start_step_11"></v-app-bar-nav-icon>
      <span
        v-if="hasNewMessage && pName != 'empty'"
        class="material-icons material-icons.md-18"
        style="color:red;font-size:15px;position:relative;right:22px;bottom:8px;pointer-events:none"
      >
        circle
      </span>
      <v-toolbar-title>BuildPro</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
        v-if="userId != null && pName !== 'empty' && pName != null"
        rounded
        color="#ffcf00"
        @click="selectProject"
        light
      >
        <i>{{pName}}</i>
      </v-btn>
      <v-btn
        v-else-if="userId != null"
        rounded
        color="#ffcf00"
        @click="selectProject"
        light
      >
      <span class="material-icons-outlined">legend_toggle</span>
      </v-btn>
    </v-app-bar>
    <router-view style="padding-top:70px; padding-bottom:70px" ref="pagecontent" :key="$route.path"/>
    <v-navigation-drawer
      v-if="getDrawer"
      v-model="drawer"
      fixed
      temporary
      style="height: 100vh"
    >
      <v-list
        nav
        dense
      >
        <v-list-item-group
          v-model="group"
          active-class="deep-purple--text text--accent-4"
        >
          <v-subheader>Build Related</v-subheader>
          <v-list-item @click="$router.push({ name: 'profile' })">
            <v-list-item-icon>
              <span class="material-icons-outlined">home</span>
            </v-list-item-icon>
            <v-list-item-title>Home</v-list-item-title>
          </v-list-item>

          <v-list-item v-if="role == 1 || hasaccess('sitediary')" @click="$router.push({ name: 'sitediary' })">
            <v-list-item-icon>
              <span class="material-icons-outlined">note</span>
            </v-list-item-icon>
            <v-list-item-title>Site Diary</v-list-item-title>
          </v-list-item>
          <!--<v-list-item v-if=(role == 1 || role == 2 || role == 3) && hasaccess('ganttchart')" @click="$router.push({ name: 'ganttchart' })">
            <v-list-item-icon>
              <span class="material-icons-outlined">note</span>
            </v-list-item-icon>
            <v-list-item-title>Gantt Chart</v-list-item-title>
          </v-list-item>-->
          <v-list-item v-if="(role == 1 || role == 3) && hasaccess('budgetmanager')" @click="$router.push({ name: 'budgetmanager' })">
            <v-list-item-icon>
              <span class="material-icons-outlined">payments</span>
            </v-list-item-icon>
            <v-list-item-title>Budget Manager</v-list-item-title> 
          </v-list-item>

          <v-list-item v-if="role == 1 || role == 2" @click="$router.push({ name: 'dailyworkerlogs' })">
            <v-list-item-icon>
              <span class="material-icons-outlined">book</span>
            </v-list-item-icon>
            <v-list-item-title>Daily Logs</v-list-item-title> 
          </v-list-item>
          
          <v-list-item v-if="(role == 1 || role == 2 || role == 3) && hasaccess('filemanager')" @click="$router.push({ name: 'filemanager' })">
            <v-list-item-icon>
              <span class="material-icons-outlined">insert_drive_file</span>
            </v-list-item-icon>
            <v-list-item-title>File Manager</v-list-item-title> 
          </v-list-item>
          <v-list-item @click="$router.push({ name: 'projectplanner' })" id="start_step_12">
            <v-list-item-icon>
              <span class="material-icons-outlined">ballot</span>
            </v-list-item-icon>
            <v-list-item-title>Project Planner</v-list-item-title> 
          </v-list-item>
          <v-list-item v-if="(role == 1 || role == 2 || role == 3) && hasaccess('designtracker')" @click="$router.push({ name: 'designtracker' })">
            <v-list-item-icon>
              <span class="material-icons-outlined">view_agenda</span>
            </v-list-item-icon>
            <v-list-item-title>Design Tracker</v-list-item-title> 
          </v-list-item>
          <v-list-item v-if="(role == 1 || role == 2 || role == 3) && hasaccess('decision')" @click="$router.push({ name: 'decision' })">
            <v-list-item-icon>
              <v-icon>
                mdi-arrow-decision
              </v-icon>
              <!-- <span class="material-icons-outlined">view_agenda</span> -->
            </v-list-item-icon>
            <v-list-item-title>Decisions</v-list-item-title> 
          </v-list-item>
          <v-list-item v-if="(role == 1 || role == 2 || role == 3) && hasaccess('snags')" @click="$router.push({ name: 'snags' })">
            <v-list-item-icon>
              <span class="material-icons-outlined">splitscreen</span>
            </v-list-item-icon>
            <v-list-item-title>Snags</v-list-item-title> 
          </v-list-item>

          <!-- <v-list-item @click="$router.push({ name: 'workerlogs' })">
            <v-list-item-icon>
              <span class="material-icons-outlined">book</span>
            </v-list-item-icon>
            <v-list-item-title>Worker Logs</v-list-item-title> 
          </v-list-item> -->

          <!--<v-list-group
            v-if="role == 1"
            prepend-icon="article"
            no-action
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>Project(s)</v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item @click="$router.push({name: 'projectnew'})">
              <v-list-item-content>
                <v-list-item-title>Add Project</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              v-for="(project, projectIndex) in dropDownInfo"
              :key="projectIndex"
            >
              <v-list-item-content @click="selectProjectItem(project)">
                <v-list-item-title>
                  {{project.text}}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>-->
          <v-subheader>Requests</v-subheader>
            <v-list-item v-if="role == 1 || role == 2 || role == 3 || hasaccess('messages')" @click="$router.push({ name: 'messages' }); SeenNewMessage()">
              <v-list-item-icon>
                <span class="material-icons-outlined">chat</span>
                <span
                  v-if="hasNewMessage && pName != 'empty'"
                  class="material-icons material-icons.md-18"
                  style="color:red;font-size:15px;position:relative;right:10px;bottom:5px;pointer-events:none"
                >
                  circle
                </span>
              </v-list-item-icon>
              <v-list-item-title>Inbox</v-list-item-title> 
            </v-list-item>
          <v-subheader v-if="role === 1 || hasaccess('teams')">Settings</v-subheader>
            <v-list-group
              v-if="role === 1 || hasaccess('teams')"
              prepend-icon="settings"
              no-action
            >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>Settings</v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item @click="$router.push({name: 'invitemember'})">
              <v-list-item-content>
                <v-list-item-title>
                  Team Members
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="$router.push({ name: 'inviteclient' })">
              <v-list-item-content>
                <v-list-item-title>
                  Client
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        <v-subheader>Logout</v-subheader>
          <v-list-item @click="logout">
            <v-list-item-icon>
              <span class="material-icons-outlined">logout</span>
            </v-list-item-icon>
            <v-list-item-title>Logout</v-list-item-title> 
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <!-- content -->
    <v-footer
      dark
      padless
      style="position:absolute; width:100%; bottom:0"
    >
      <v-card
        flat
        color="#062a82"
        style="width: 100%;"
        class="white--text text-center"
      >
        <!-- <v-card-text>
          <v-btn
            v-for="icon in icons"
            :key="icon"
            class="mx-4 white--text"
            icon
          >
            <v-icon size="24px">
              {{ icon }}
            </v-icon>
          </v-btn>
        </v-card-text> -->

        <!-- <v-card-text class="white--text pt-0">
          Phasellus feugiat arcu sapien, et iaculis ipsum elementum sit amet. Mauris cursus commodo interdum. Praesent ut risus eget metus luctus accumsan id ultrices nunc. Sed at orci sed massa consectetur dignissim a sit amet dui. Duis commodo vitae velit et faucibus. Morbi vehicula lacinia malesuada. Nulla placerat augue vel ipsum ultrices, cursus iaculis dui sollicitudin. Vestibulum eu ipsum vel diam elementum tempor vel ut orci. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.
        </v-card-text> -->

        <v-divider></v-divider>

        <v-card-text class="white--text mx-4">
          {{ new Date().getFullYear() }} — <strong>BuildPro</strong>
        </v-card-text>
      </v-card>
    </v-footer>
    
    <v-row justify="space-around">
      <v-col cols="auto">
        <v-dialog
          transition="dialog-bottom-transition"
          v-model="projectModel"
          persistent
          width="500"
        >
          <v-card>
            <v-toolbar
              color="primary"
              dark
              id="start_step_0"
            >Choose a project
            <v-spacer></v-spacer>
            <v-btn
              id="start_step_2"
              v-if="this.role == 1"
              color="primary lighten-1"
              light
              @click="$router.push({name: 'projectnew'}); projectModel = false">Add Project</v-btn>
            </v-toolbar>
            <v-card-text>
              <v-list
                three-line
                subheader
              >
                <div>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-subtitle>
                        <v-select
                          v-if="role < 2"
                          class="mt-3"
                          v-model="houseName"
                          :items="dropDownInfo"
                          item-text="text"
                          label="Choose A Project"
                          @change="setProjectRoot"
                          id="start_step_1"
                        ></v-select>
                        <v-select
                          v-else
                          class="mt-3"
                          v-model="houseName"
                          :items="dropDownInfo"
                          item-text="name"
                          label="Choose A Project"
                          @change="setProjectRoot"
                          id="start_step_1"
                        ></v-select>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </div>
              </v-list>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn
                text
                @click="projectModel = false"
              >Close</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>     
    </v-row>    
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

  export default {
    data: () => ({
      drawer: false,
      group: null,
      houseName: '',
      projectModel: false,
      hasNewMessage: false,
      showNotification: false,
      interval: null,
      userId: '',
      pName: '',
      role: null,
      access: null,
      // isLoggedIn: false,
      icons: [
        'mdi-facebook',
        'mdi-twitter',
        'mdi-linkedin',
        'mdi-instagram',
      ],
      items: [
        {
          action: 'mdi-ticket',
          items: [{ title: 'List Item' }],
          title: 'Attractions',
        },
        {
          action: 'mdi-silverware-fork-knife',
          active: true,
          items: [
            { title: 'Breakfast & brunch' },
            { title: 'New American' },
            { title: 'Sushi' },
          ],
          title: 'Dining',
        },
        {
          action: 'mdi-school',
          items: [{ title: 'List Item' }],
          title: 'Education',
        },
        {
          action: 'mdi-run',
          items: [{ title: 'List Item' }],
          title: 'Family',
        },
        {
          action: 'mdi-bottle-tonic-plus',
          items: [{ title: 'List Item' }],
          title: 'Health',
        },
        {
          action: 'mdi-content-cut',
          items: [{ title: 'List Item' }],
          title: 'Office',
        },
        {
          action: 'mdi-tag',
          items: [{ title: 'List Item' }],
          title: 'Promotions',
        },
      ],
    }),
    watch: {
      getReloadProjects(data){
        if(data){
          return this.getProjectList()
        }
      },
      getPName(data){
        return this.pName = data
      }
    },
    computed: {
      ...mapGetters({
        getDrawer:          'getDrawer',
        dropDownInfo:       'getDropDown'
      }),
      getReloadProjects(){
        return this.$store.getters['getGrabProjects']
      },
      getPName(){
        return this.$store.getters['getPName']
        // return localStorage.getItem('project_id')
      }
    },
    components: {
      //TourView
    },
    created () {
      // localStorage.getItem("token") === null ? this.isLoggedIn = false : this.isLoggedIn = true
      this.userId = localStorage.getItem('id')
      this.houseName = localStorage.getItem('project_id')
      if(localStorage.getItem('project_id') !== 'empty'){
        this.urlParams = localStorage.getItem('project_id')
      }
      this.getProjectList()
      this.pName = localStorage.getItem('project_id')
      this.role = localStorage.getItem('role')
      this.access = localStorage.getItem('access')
      if(localStorage.getItem("token") === null){
        return
      }
      // this.getMessageNotification(); // Get notification as soon as page starts
      // this.interval = setInterval(function () {
      //   this.getMessageNotification();
      // }.bind(this), 5000);
    },
    mounted() { 
      if ( this.pName == 'empty') {
        setTimeout(() => { this.selectProject()}, 500);
      }

    },
    methods: {
      move_next() {
        this.$tours.tourGuide.nextStep()
      },
      ShowMessageNotification() {
        let payload = {
          project_name: this.pName
        }
        this.$emit('ShowMessage', payload)
      },
      hasaccess(data){
        return this.access.includes(data)
      },
      async getMessageNotification(){
        //console.log('getting new messages...')
        await this.$axios.get('getMessageNotification')
        .then(({data}) => {
            //console.log(data)
          if (data.has_new_message == true && this.$route.name !== "messages") {
            if(data.new_message_id !== null && data.message_delivered == false){
              this.$emit('ShowMessage', data) // Show pop up notification
            }
            if(data.project_name === this.pName){
              this.hasNewMessage = true
            }
          }
          else if(this.$route.name === "messages" && data.has_new_message){
            if(data.new_message_id !== null && data.project_name != this.pName){
              this.$emit('ShowMessage', data) // Show pop up notification
            }
            this.SeenNewMessage()
            this.$refs.pagecontent.LoadConversation(this.$refs.pagecontent.conversation_type)
            this.$refs.pagecontent.switched_tab = true
          }
          //this.$emit('ShowMessage', data.data)
        })
      },
      async SeenNewMessage(){
        if(this.pName != 'empty'){
          this.$axios.post('seenNewMessage')
          this.hasNewMessage = false
        }
      },
      selectProjectItem(data){
        localStorage.setItem('project_id', data.text)
        this.drawer = false
        this.$store.dispatch('setReload', true)
        this.$store.dispatch('setRMessage', true)
        this.$store.dispatch('setPName', data.text)
        setTimeout(() => location.reload(), 500)
      },
      async getProjectList(){
        await this.$axios.get(`taskmanager/fetch/projects/${this.userId}`)
        .then(({data}) => {
          const ddData = data.data.filter((n) => {
            return n.text !== null
          })
          let toOut = []
          ddData.forEach(q => {
            toOut.push({
              text: q.text
            })
          })
          // console.log(toOut)
          const datatt = Object.values(toOut.reduce((acc,cur)=>Object.assign(acc,{[cur.text]:cur}),{}))
          // console.log(datatt)
          this.$store.dispatch('setMyTemplate', datatt)
        })
      },
      async selectProject(){
        if (this.role > 1){
          await this.$axios.get(`projectlist/${localStorage.getItem('id')}`)
          .then(({data}) => {
            if(data.data.length != 0){
              this.$store.dispatch('setMyTemplate', data.data)
              this.projectModel = true
            }
          })
        }
        this.projectModel = true
      },
      showSnackBar(message){
        this.$store.commit("setMessage", 
        {show: true, message: message}, 
        {root: 1})
      },
      setProjectRoot(){        
        localStorage.setItem('project_id', this.houseName)
        this.projectModel = false
        this.showSnackBar('Please wait...')
        let payload = {
          user_id: this.userId,
          project_id: this.houseName,
        }
        this.$axios.get('/budgetmanager/fetch/chosentemplate', payload)
        .then(({data}) => {
          if(data.template !== null){
            localStorage.setItem('chosen_template', [ data.template.template_name, data.template.id ])
          }
          else{
            localStorage.removeItem('chosen_template')
          }
          setTimeout(() => location.reload(), 500)
        })
      },
      async logout(){
        this.$store.dispatch('setDrawer', false);
        await this.$axios.post('logout')
        .then(({data}) => {
          if(data.response){
            localStorage.removeItem('id');
            localStorage.removeItem('token');
            localStorage.removeItem('username');
            localStorage.removeItem('email');
            localStorage.removeItem('name');
            localStorage.removeItem('phone');
            localStorage.removeItem('project_id')
            localStorage.removeItem('chosen_template')
            localStorage.removeItem('cs')
            localStorage.removeItem('access')
            localStorage.removeItem('role')
            this.$router.go({name: 'index'})
          }
        })
      },
      beforeDestroy: function(){
        clearInterval(this.interval);
      }
    }
  }
</script>
