<template>
    <v-container>
      <v-card
        class="mx-auto my-12"
      >
        <!-- <label><strong>What would you like to do today?</strong></label> -->
        <v-card-title style="background: #e3e3e4 !important;"><strong><small><span style="position: relative; top:5px;" class="material-icons-outlined mr-1">home</span>What would you like to do today?</small></strong></v-card-title>
        <div id="v-step-0">
        <v-row
            class="mb-5 mt-5 justify-center"
            no-gutters
        >
            <!-- <div style="width: 100%;">
              <stripe-element-card
                ref="elementRef"
                :pk="publishableKey"
                @token="tokenCreated"
              />
              <button @click="submit">Generate token</button>
            </div> -->
                <!-- <div id="addReminderIntro">
                  <v-btn
                      v-if="role === 1 || hasaccess('profile')"
                      class="ma-2"
                      rounded
                      color="#c6d5fc"
                      elevation="0"
                      @click="addReminders = true"
                  >
                      Add Reminders
                  </v-btn>
                </div> -->
                <div id="siteDiaryButton">
                  <v-btn
                      v-if="role === 1 || hasaccess('sitediary')"
                      class="ma-2"
                      rounded
                      color="#c6d5fc"
                      elevation="0"
                      @click="$router.push({ name: 'sitediary' })"
                  >
                      Site Diary
                  </v-btn>
                </div>
                <div id="budgetManagerButton">
                  <v-btn
                      v-if="(role == 1 || role == 3) && hasaccess('budgetmanager')"
                      class="ma-2"
                      rounded
                      color="#c6d5fc"
                      elevation="0"
                      @click="$router.push({ name: 'budgetmanager' })"
                  >
                      Budget Manager
                  </v-btn>
                </div>
                <div id="fileManagerButton">
                  <v-btn
                      v-if="role === 1 || hasaccess('filemanager')"
                      class="ma-2"
                      rounded
                      color="#c6d5fc"
                      elevation="0"
                      @click="$router.push({ name: 'filemanager' })"
                  >
                      File Manager
                  </v-btn>
                </div>
                <div id="projectPlannerButton">
                  <v-btn
                      v-if="role === 1 || hasaccess('projectplanner')"
                      class="ma-2"
                      rounded
                      color="#c6d5fc"
                      elevation="0"
                      @click="$router.push({ name: 'projectplanner' })"
                  >
                      Project Planner
                  </v-btn>
                </div>
                <div id="designTrackerButton">
                  <v-btn
                      v-if="role === 1 || hasaccess('designtracker')"
                      class="ma-2"
                      rounded
                      color="#c6d5fc"
                      elevation="0"
                      @click="$router.push({ name: 'designtracker' })"
                  >
                      Design Tracker
                  </v-btn>
                </div>
                <div id="decisionButton">
                  <v-btn
                      v-if="role === 1 || hasaccess('decision')"
                      class="ma-2"
                      rounded
                      color="#c6d5fc"
                      elevation="0"
                      @click="$router.push({ name: 'decision' })"
                  >
                      Decisions
                  </v-btn>
                </div>
                <div id="snagsButton">
                  <v-btn
                      v-if="role === 1 || hasaccess('snags')"
                      class="ma-2"
                      rounded
                      color="#c6d5fc"
                      elevation="0"
                      @click="$router.push({ name: 'snags' })"
                  >
                      Snags
                  </v-btn>
                </div>
                <div id="profileEditBtn">
                  <v-btn
                      class="ma-2"
                      rounded
                      color="#c6d5fc"
                      elevation="0"
                      @click="profileDialog = true"
                  >
                      Profile Edit
                  </v-btn>
                </div>
              <!-- <v-btn
                  class="ma-2"
                  rounded
                  outlined
                  @click="paymentDialog = true"
              >
                  Pay
              </v-btn> -->
          </v-row>
        </div>
        <v-card-text>

        <v-dialog 
          max-width="500px"
          v-model="addReminders"
          persistent
        >
          <v-card>
            <v-card-title>
              Add Reminders
            </v-card-title>
            <v-card-text>
              <v-text-field
                name="input-7-1"
                v-model="reminder"
                label="Reminder"
                placeholder="Reminder"
              ></v-text-field>
              <v-checkbox
                v-model="reminder_shared"
                :label="`Share With Others`"
              ></v-checkbox>
                <v-btn
                  text
                  @click="addReminders = false; reminder = ''"
                  class="float-end"
                >
                  <span class="material-icons-outlined">cancel</span>

                </v-btn>

                <v-btn
                  text
                  @click="addReminderFn"
                  class="float-end"
                >
                  <span class="material-icons-outlined">save</span>
                </v-btn>

            </v-card-text>
            <v-card-actions>
                <p></p>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog 
          max-width="500px"
          v-model="editReminders"
          persistent
        >
          <v-card>
            <v-card-title>
              Edit Reminders
            </v-card-title>
            <v-card-text>
              <v-text-field
                name="input-7-1"
                label="Reminder"
                v-model="edit_reminder.item.todo"
                placeholder="Reminder"
              ></v-text-field>
              <v-checkbox
                v-model="edit_reminder.item.is_shared"
                :label="`Share With Others`"
              ></v-checkbox>
                <v-btn
                  text
                  @click="editReminders = false; reminder = ''"
                  class="float-end"
                >
                  <span class="material-icons-outlined">cancel</span>

                </v-btn>

                <v-btn
                  text
                  @click="saveEditedReminder(edit_reminder)"
                  class="float-end"
                >
                  <span class="material-icons-outlined">save</span>
                </v-btn>

            </v-card-text>
            <v-card-actions>
                <p></p>
            </v-card-actions>
          </v-card>
        </v-dialog>
          
          <div id="reminders">
            <v-row
                no-gutters
            >
              <v-col
                cols="6"
                offset="3"
              >
                <div id="addReminderIntro">
                  <v-btn
                      v-if="role === 1 || hasaccess('profile')"
                      class="mb-2 float-end"
                      color="#c6d5fc"
                      elevation="0"
                      @click="addReminders = true"
                  >
                      Add Reminders
                  </v-btn>
                </div>
              </v-col>

              <v-col cols="6" offset="3">
                <v-data-table
                  :headers="headers"
                  :items="reminders"
                  :single-select="singleSelect"
                  class="elevation-1"
                  v-model="selected"
                  show-select
                  @input="triggerComplete"
                  mobile-breakpoint="0">
                  <template v-slot:item.todo="props">
                      <div @click="editReminders = true; SetEditTodo(props)">
                        <v-row>
                          <v-col cols="10">
                            {{ props.item.todo }}<br/>
                            <small v-if="props.item.getuser != null">{{props.item.getuser.name}}</small>
                          </v-col>
                          <v-col cols="2">
                            <v-icon v-if="props.item.is_shared" class="ma-2">
                              mdi-folder-account
                            </v-icon>
                          </v-col>

                        </v-row>

                      </div>
                    <!-- <v-edit-dialog
                      :return-value.sync="props.item.todo"
                      large
                      persistent
                      @save="saveEditedReminder(props)"
                      @cancel="cancel"
                      @open="open"
                      @close="close"
                    >
                      <template v-slot:input>
                        <div class="mt-4 text-h6">
                          Update Reminder
                        </div>
                        <v-text-field
                          v-model="props.item.todo"
                          label="Edit"
                          single-line
                          autofocus
                        ></v-text-field>
                      </template>
                    </v-edit-dialog> -->
                  </template>
                </v-data-table>
              </v-col>


            </v-row>
          </div>
          <div id="completeReminders">
          <v-row
            class="justify-center mt-5"
            no-gutters
          >
            <div id="completedReminderBtn">
              <v-btn
                class="ma-2"
                rounded
                outlined
                @click="toShow"
              >
                Completed Reminders
              </v-btn>
            </div>
          </v-row>
          </div>
          <v-row
            class="justify-center mt-5"
            no-gutters
          >
            <v-data-table
              v-if="showDone"
              :headers="headersDone"
              :items="doneReminders"
              class="elevation-1"
              :single-select="singleSelect"
              v-model="selectedCompeleted"
              @input="triggerCompleteDelete"
              show-select
              mobile-breakpoint="0">
              <template v-slot:item.todo="props">
                <div>
                  <label style="text-decoration: line-through;">{{ props.item.todo }}<br/><small v-if="props.item.getuser != null">{{props.item.getuser.name}}</small></label>
                </div>
              </template>
            </v-data-table>
          </v-row>
        </v-card-text>
      </v-card>
    <!-- profile edit -->
    <v-row justify="center">
      <v-dialog
        v-model="profileDialog"
        hide-overlay
        transition="dialog-bottom-transition"
      >
        <v-card>
          <v-toolbar
            dark
            color="#062a82"
          >
            <v-btn
              icon
              dark
              @click="profileDialog = false"
            >
              <span class="material-icons-outlined">close</span>
            </v-btn>
            <v-toolbar-title>Profile</v-toolbar-title>
          </v-toolbar>
          <v-list
            three-line
            subheader
          >
            <v-subheader>Profile Edit</v-subheader>
            <v-btn text light class="mb-3" @click="addPartnerModal = true"><span class="material-icons-outlined">add</span> Add Partner</v-btn>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Name</v-list-item-title>
                <v-list-item-subtitle>
                  <v-text-field
                    name="input-7-1"
                    v-model="userInfo.name"
                    :placeholder="userInfo.name"
                  ></v-text-field>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Username</v-list-item-title>
                <v-list-item-subtitle>
                  <v-text-field
                    name="input-7-1"
                    v-model="userInfo.username"
                    :placeholder="userInfo.username"
                  ></v-text-field>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Email</v-list-item-title>
                <v-list-item-subtitle>
                  <v-text-field
                    name="input-7-1"
                    v-model="userInfo.email"
                    :placeholder="userInfo.email"
                    disabled
                  ></v-text-field>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Phone</v-list-item-title>
                <v-list-item-subtitle>
                  <v-text-field
                    name="input-7-1"
                    v-model="userInfo.phone"
                    :placeholder="userInfo.phone"
                  ></v-text-field>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-card-actions>
          <v-spacer></v-spacer>
            <v-btn
              light
              text
              @click="editProfileSave"
            >
              <span class="material-icons-outlined">save</span> Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <!-- Add partner modal -->
    <v-row justify="center">
      <v-dialog
        v-model="addPartnerModal"
        hide-overlay
        transition="dialog-bottom-transition"
      >
        <v-card>
          <v-toolbar
            dark
            color="#062a82"
          >
            <v-btn
              icon
              dark
              @click="addPartnerModal = false; clearPartnerInfo();"
            >
              <span class="material-icons-outlined">close</span>
            </v-btn>
            <v-toolbar-title>Add Partner</v-toolbar-title>
          </v-toolbar>
          <v-list
            three-line
            subheader
          >
            <v-subheader>Partner Information</v-subheader>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Name</v-list-item-title>
                <v-list-item-subtitle>
                  <v-text-field
                    name="input-7-1"
                    v-model="partnerInfo.name"
                  ></v-text-field>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Username</v-list-item-title>
                <v-list-item-subtitle>
                  <v-text-field
                    name="input-7-1"
                    v-model="partnerInfo.username"
                  ></v-text-field>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Email</v-list-item-title>
                <v-list-item-subtitle>
                  <v-text-field
                    name="input-7-1"
                    v-model="partnerInfo.email"
                  ></v-text-field>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Password</v-list-item-title>
                <v-list-item-subtitle>
                  <v-text-field
                    :append-icon="showpasswordpartner ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="showpasswordpartner ? 'text' : 'password'"
                    name="input-7-1"
                    v-model="partnerInfo.password"
                    @click:append="showpasswordpartner = !showpasswordpartner"
                  ></v-text-field>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Phone</v-list-item-title>
                <v-list-item-subtitle>
                  <v-text-field
                    name="input-7-1"
                    v-model="partnerInfo.phone"
                  ></v-text-field>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-card-actions>
          <v-spacer></v-spacer>
            <v-btn
              light
              text
              @click="addPartnerSave"
            >
              <span class="material-icons-outlined">save</span> Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <!-- end add partner modal -->
    <!-- payment dialog -->
    <v-row justify="center">
      <v-dialog
        v-model="paymentDialog"
        hide-overlay
        transition="dialog-bottom-transition"
      >
        <v-card>
          <v-toolbar
            dark
            persistent
            color="#062a82"
          >
            <v-btn
              icon
              dark
              @click="paymentDialog = false;"
            >
              <span class="material-icons-outlined">close</span>
            </v-btn>
            <v-toolbar-title>Payment</v-toolbar-title>
          </v-toolbar>
          <v-list
            three-line
            subheader
          >
            <v-list-item>
              <v-list-item-content>
                <v-list-item-subtitle>
                  <stripe-element-card
                    ref="elementRef"
                    :pk="publishableKey"
                    @token="tokenCreated"
                  />
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-card-actions>
          <v-spacer></v-spacer>
            <v-btn
              light
              text
              @click="submit"
            >
              Send <span class="material-icons-outlined">send</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- delete reminder -->
        <div class="text-center">
          <v-dialog
            v-model="dialogDeleteItem"
            width="500"
            persistent
          >
            <v-card>
              <v-card-title class="text-h5 grey lighten-2">
                Complete this reminder?
              </v-card-title>

              <v-card-text class="text-center mt-3">
                Marking this reminder as completed?
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="#062a82"
                  dark
                  class="white--text"
                  @click="completeReminder"
                >
                  Yes
                </v-btn>
                <v-btn
                  color="#062a82"
                  dark
                  class="white--text"
                  @click="cancelDialogComplete"
                >
                  No
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
        <!-- end reminder -->
    </v-row>
    <!--<v-tour name="myTour" :steps="steps"></v-tour>-->
    <div>
      <TourView ref="tourguide"></TourView>
    </div>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import { StripeElementCard } from '@vue-stripe/vue-stripe';

  export default {
    data: () => ({
      bus: null,
      access: null,
      role: null,
      publishableKey: null,
      dialogDeleteItem: false,
      open_dialog: false,
      houseName: '',
      token: null,
      userId: null,
      userInfo: {
        name: '',
        username: '',
        email: '',
        phone: '',
      },
      singleSelect: true,
      selected: [],
      selectedCompeleted: [],
      toggle_exclusive: undefined,
      addReminders: false,
      editReminders: false,
      reminder: '',
      reminder_shared: false,
      edit_reminder: {
        item: {
          todo: '',
          is_shared: false
        }
      },
      headers: [
        {text: 'REMINDERS', value: 'todo'},
        {text: '', value: 'project'},
        // { text: 'Status', value: 'status' },
        // { text: 'Date Added', value: 'created_at' },
      ],
      headersDone: [
        {text: 'Completed Reminders', value: 'todo'}
      ],
      status: ["Pending", "Started", "On-Hold", "Waiting-on Info", "Complete"],
      statusUpdate: '',
      profileDialog: false,
      addPartnerModal: false,
      showpasswordpartner: false,
      partnerInfo: {
        name: '',
        username: '',
        email: '',
        password: '',
        phone: ''
      },
      paymentDialog: false,
      showDone: false,
      steps: [
          {
            target: '#addReminderIntro',
            content: 'You can add your Reminders here.'
          },
          {
            target: '#reminders',
            content: 'Your Reminders are displayed on your home screen, to ensure they are never overlooked.'
          },
          {
            target: '#completedReminderBtn',
            content: 'You can find your completed reminders here.'
          },
          {
            target: '#siteDiaryButton',
            content: 'Keep a log of your site visits here.'
          },
          {
            target: '#budgetManagerButton',
            content: 'Keep a record of your entire Build\'s Budget here.'
          },
          {
            target: '#fileManagerButton',
            content: 'Access all your most important Self Build documents here.'
          },
          {
            target: '#projectPlannerButton',
            content: 'Here you will find the Project Planner, with a built in typical "Construction Schedule" template, to help keep your Self Build on track.'
          },
          {
            target: '#snagsButton',
            content: 'Keep a record of any Snags that may need to be addressed here.'
          },
          {
            target: '#profileEditBtn',
            content: 'You can edit your details here.'
          },
        ],
      projectModel: false,
      urlParams: 'empty',
    }),
    components: {
      StripeElementCard,      
    },
    mounted () {      
      //if(localStorage.getItem('introProfile') === null){
      const introData = JSON.parse(localStorage.getItem('intro_data'));
      
        if ( localStorage.getItem('project_id') == 'empty') {
          if ( introData.after_login == false ) {
            this.$refs.tourguide.set_tour_steps('tour_start')
            setTimeout(() => {
              this.$refs.tourguide.start_tour()
            }, 200)
            
            let updatedIntroData = introData
            updatedIntroData.after_login = true
            localStorage.setItem('intro_data', JSON.stringify(updatedIntroData))
          }
        } else {
          if ( introData.profile == false ) {
            this.$refs.tourguide.set_tour_steps('tour_profile')
            this.$refs.tourguide.start_tour()

            let updatedIntroData = introData
            updatedIntroData.after_login = true
            localStorage.setItem('profile', JSON.stringify(updatedIntroData))
          }
        }
        //this.$tours['tourGuide'].start()
        //localStorage.setItem('introProfile', true)
      //}
    },
    watch: {
      reloadFn(data){
        if(data){
          this.urlParams = localStorage.getItem('project_id')
          return this.getData()
        }
      }
    },
    created () {      
      this.userId = localStorage.getItem('id')
      if(localStorage.getItem("token") === null){
        this.$router.push({name: 'index'})
        return
      }
      if(localStorage.getItem('project_id') === 'empty'){
        this.getData()
      }else{
        this.urlParams = localStorage.getItem('project_id')
        this.$store.dispatch('setDrawer', true)
        this.getData()
      }
      this.userInfo = {
        name: localStorage.getItem('name'),
        username: localStorage.getItem('username'),
        email: localStorage.getItem('email'),
        phone: localStorage.getItem('phone')
      }
      this.publishableKey = process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY
      this.access = localStorage.getItem('access')
      this.role = localStorage.getItem('role')
      this.getProjectList()
    },
    computed: {
      ...mapGetters({
        reminders:          'getReminders',
        doneReminders:      'getDoneReminders',
      }),
      reloadFn(){
        return this.$store.getters['getReload']
      }
    },
    methods: {
      hasaccess(data){
        return this.access.includes(data)
      },
      selectProject(){
        this.$router.push({ name: 'index' })
        this.showSnackBar("Please select a template")
      },
      async getProjectList(){
        await this.$axios.get(`taskmanager/fetch/projects/${this.userId}`)
        .then(({data}) => {
          const ddData = data.data.filter((n) => {
            return n.text !== null
          })
          let toOut = []
          ddData.forEach(q => {
            toOut.push({
              text: q.text
            })
          })
          // console.log(toOut)
          const datatt = Object.values(toOut.reduce((acc,cur)=>Object.assign(acc,{[cur.text]:cur}),{}))
          // console.log(datatt)
          this.$store.dispatch('setMyTemplate', datatt)
        })
      },
      setProjectRoot(){
        localStorage.setItem('project_id', this.houseName)
        this.projectModel = false
        this.getData()
      },
      toShow() {
        this.showDone ? this.showDone = false : this.showDone = true
      },
      submit () {
        // this will trigger the process
        this.$refs.elementRef.submit();
      },
      tokenCreated (token) {
        console.log(token);
        // handle the token
        // send it to your server
      },
      async paymentProcess(){
        const data = this.elementRef.submit();
        console.log(data)
        this.showSnackBar('Needs Stripe Configuration from Stripe Services')
      },
      showSnackBar(message){
        this.$store.commit("setMessage", 
        {show: true, message: message}, 
        {root: 1})
      },
      async getData(){
        await this.$axios.get(`getReminders/${this.urlParams}`)
        .then(({data}) => {
          if(data.response){
            //console.log(data)
            if(data.data.length === 0){
              if(this.urlParams === 'empty'){
                this.generateTemplate()
                return
              }
            }
            this.$store.dispatch('setReload', false)
            this.$store.dispatch('setReminders', data.data)
            this.$store.dispatch('setDrawer', true)
          }else{
            localStorage.removeItem('token')
            localStorage.removeItem('id')
            localStorage.removeItem('name')
            localStorage.removeItem('username')
            localStorage.removeItem('email')
            localStorage.removeItem('phone')
            this.$router.push({ name: 'index' })
          }
          this.$axios.get(`getDoneReminders/${this.urlParams}`)
          .then(({data}) => {
            this.$store.dispatch('setDoneReminders', data.data)
          })
        })
      },
      async generateTemplate(){
        this.showSnackBar('Generating Template, please wait...')
        await this.$axios.get(`gTempRem/${this.urlParams}`)
        .then(({data}) => {
          this.showSnackBar(data.message)
          this.$store.dispatch('setReminders', data.data)
        })
      },
      async addReminderFn(){
        const toPass = {
          reminder: this.reminder,
          project_id: this.urlParams,
          is_shared: this.reminder_shared
        }
        await this.$axios.post('addReminders', toPass)
        .then(({data}) => {
          if(data.response){
            this.reminder = ''
            this.addReminders = false
            this.reminder_shared = false
            this.$store.dispatch('setReminders', data.data)
            this.showSnackBar(data.message)
          }
        })
      },
      async save(item){
        await this.$axios.patch('updateReminder', item.item)
        .then(({data}) => {
          if(data.response){
            this.$store.dispatch('setReminders', data.data)
            this.$store.dispatch('setDoneReminders', data.deleted)
            this.showSnackBar(data.message)
          }
        })
      },
      cancel(){},
      open(){},
      close(){},
      async saveEditedReminder(data){
        const toPass = {
          id: data.item.id,
          todo: data.item.todo,
          is_shared: data.item.is_shared
        }
        await this.$axios.patch(`updateReminderNote/${this.urlParams}`, toPass)
        .then(({data}) => {
          // console.log(data)
          if(data.response){
            this.$store.dispatch('setReminders', data.data)
            this.showSnackBar(data.message)
            this.editReminders = false
          }
        })
      },
      async editProfileSave(){
        const dataPass = {
          data: this.userInfo
        }
        await this.$axios.post(`editProfile/${this.userId}`, dataPass)
        .then(({data}) => {
          if(data.response){
            this.userInfo.name = data.info.name
            this.userInfo.username = data.info.username
            this.userInfo.email = data.info.email
            this.userInfo.phone = data.info.phone
            this.profileDialog = false
            this.showSnackBar(data.message)
            return
          }else{
            this.showSnackBar(data.message)
          }
        })
      },
      async completeReminder(){
        const toPass = {
          id: this.selected[0].id,
          status: "Complete",
          project_id: this.urlParams
        }
        await this.$axios.patch('updateReminder', toPass)
        .then(({data}) => {
          if(data.response){
            this.$store.dispatch('setReminders', data.data)
            this.$store.dispatch('setDoneReminders', data.deleted)
            this.showSnackBar(data.message)
            this.dialogDeleteItem = false
          }
        })
      },
      async cancelDialogComplete(){

        //Remove Dialog
        this.selected = [];
        this.dialogDeleteItem = false;
        
      },
      async triggerComplete(){

        if(!this.open_dialog){
          this.dialogDeleteItem = true
          this.open_dialog = true;
        }
        else{
          this.open_dialog = false;
        }

      },
      async triggerCompleteDelete(){
        await this.$axios.delete(`deleteCompleted/${this.selectedCompeleted[0].id}/${this.urlParams}`)
        .then(({data}) => {
          if(data.response){
            this.$store.dispatch('setDoneReminders', data.data)
          }
        })
      },
      clearPartnerInfo(){
        this.partnerInfo = {
          name: '',
          username: '',
          email: '',
          password: '',
          phone: ''
        }
      },
      async addPartnerSave(){
        const dataToPass = {
          name: this.partnerInfo.name,
          username: this.partnerInfo.username,
          email: this.partnerInfo.email,
          password: this.partnerInfo.password,
          phone: this.partnerInfo.phone
        }
        await this.$axios.post('registerPartner', dataToPass)
        .then(({data}) => {
          if(data.response){
            this.showSnackBar(data.message)
            this.addPartnerModal = false
            this.clearPartnerInfo()
          }else{
            this.showSnackBar(data.message)
          }
        })
      },

      //
      SetEditTodo(value){
        this.edit_reminder = value;
      }

    }
  }
</script>
<style lang="scss" scoped>
    tbody tr:nth-of-type(odd) {
        background-color: rgba(0, 0, 0, .05);
    }
</style>