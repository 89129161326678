<template>
    <v-container fluid fill-height>
        <v-layout align-center justify-center>
            <v-flex xs12 sm8 md4>
                <h4 v-if="!process">Thank you for your request, a member of our team will contact you shortly to confirm your registration</h4>
                <h4 v-else><label @click="$router.push({ name: 'index' })">Your account is now verified. Thank you, click here to login</label></h4>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
export default {
  components: {
  },
  props: [
  ],
  data () {
    return {
        token: null,
        process: false
    }
  },
  mounted () {

  },
  created () {
    const path = this.$route.params.token
    this.token = path
    this.verify()
  },
  computed: {
  },
  methods: {
    async verify(){
      // const verify = await this.$axios.post(`verify/${datatopass}`)
      // const response = await verify.data;
      // if(response.response){
      //   this.process = true
      // }
      this.$axios.post(`verify/${this.$route.params.token}`)
      .then(({data}) => {
        if(data){
          this.process = true
          return
        }
        alert('Verify in desktop browser!')
      })
    }
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
</style>