<template>
    <v-container fluid fill-height>
        <v-layout align-center justify-center>
            <v-card
                width="450"
                align="center"
                justify="center"
            >
                <v-card-title>
                    <small style="fontSize: 14px;">
                        Create an Account to Join this Team
                        {{contractor.name}} invited you to join their BuildPro Team. 
                        Please fill out the form below to create a free BuildPro account and accept this invitation.
                    </small>
                </v-card-title>
                <v-card-text>
                    <v-card>
                        <v-row
                            no-gutters
                            class="pa-5"
                        >
                            <v-col
                                cols="12"
                            >
                                <v-text-field
                                    filled
                                    v-model="r.name"
                                    label="Name"
                                ></v-text-field>
                            </v-col>
                            <v-col
                                cols="12"
                            >
                                <v-text-field
                                    filled
                                    disabled
                                    v-model="r.email"
                                    :label="data.email"
                                ></v-text-field>
                            </v-col>
                            <v-col
                                cols="12"
                            >
                                <v-text-field
                                    :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                                    :type="show2 ? 'text' : 'password'"
                                    name="Password"
                                    label="Password"
                                    v-model="r.password"
                                    class="input-group--focused"
                                    @click:append="show2 = !show2"
                                ></v-text-field>
                            </v-col>
                            <v-col
                                cols="12"
                            >
                                <v-btn
                                    block
                                    color="#062a82"
                                    dark
                                    @click="cacreation(r)"
                                >
                                    Create Account
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-card-text>
            </v-card>
        </v-layout>
    </v-container>
</template>

<script>
export default {
  components: {
  },
  props: [
  ],
  data () {
    return {
      token: null,
      data: {},
      contractor: {},
      show2: false,
      r: {
        name: null,
        password: null
      }
    }
  },
  mounted () {
    const token = this.$route.params.token
    this.token = token
    this.fuser()
  },
  created () {
  },
  computed: {
  },
  methods: {
    showSnackBar(message){
      this.$store.commit("setMessage", 
      {show: true, message: message}, 
      {root: 1})
    },
    async fuser(){
      await this.$axios.get(`fuser/${this.token}`)
      .then(({data}) => {
         this.data = data.user
         this.contractor = data.contractor
         if(data.data.name != null){
           this.showSnackBar('Link already used!')
           this.$router.push({name: 'index'})
         }
      })
    },
    async cacreation(d){
      await this.$axios.post('cacreation', {d, id: this.data.id})
      .then(({data}) => {
        if(data.response){
          this.showSnackBar('Thank you! You may now login')
          this.$router.push({name: 'index'})
        }
      })
    }
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
</style>