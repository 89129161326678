<template>
    <v-container fluid fill-height>
        <v-layout align-center justify-center>
            <v-flex xs12 sm8 md4>
                <v-card>
                    <v-toolbar dark color="#062a82">
                    <v-toolbar-title>Registration Form</v-toolbar-title>
                    </v-toolbar>
                    <v-card-text>
                        <v-text-field
                            prepend-icon="email"
                            v-model="register.email"
                            name="Email"
                            label="Email"
                            type="email"
                        ></v-text-field>
                        <v-text-field
                            prepend-icon="lock"
                            :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                            :type="show2 ? 'text' : 'password'"
                            name="Password"
                            label="Password"
                            v-model="register.password"
                            class="input-group--focused"
                            @click:append="show2 = !show2"
                        ></v-text-field>
                        <!-- <v-text-field
                            prepend-icon="person"
                            v-model="register.username"
                            name="Username"
                            label="Username"
                            type="text"
                        ></v-text-field> -->
                        <v-text-field
                            prepend-icon="person"
                            v-model="register.name"
                            name="Full Name"
                            label="Full Name"
                            type="text"
                        ></v-text-field>
                        <v-text-field
                            prepend-icon="phone"
                            v-model="register.phone"
                            name="Phone"
                            label="Phone"
                            type="text"
                            @keypress.enter="registerBtn"
                        ></v-text-field>
                        <!-- <v-text-field
                            id="password"
                            prepend-icon="lock"
                            v-model="register.password"
                            name="password"
                            label="Password"
                            type="password"
                        ></v-text-field> -->
                    </v-card-text>
                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="#062a82" class="white--text" @click="login">Login</v-btn>
                    <v-btn color="#062a82" class="white--text" @click="registerBtn">Register</v-btn>
                    </v-card-actions>
                </v-card>
            </v-flex>
        </v-layout>
        <v-dialog
            transition="dialog-bottom-transition"
            max-width="600"
            persistent
            v-model="OTPdialog"
        >
            <v-card>
                <v-toolbar
                    color="primary"
                    dark
                >An OTP code has been sent to your email: <br/>{{register.email}}</v-toolbar>
                <v-card-text>
                    <v-text-field
                        standard
                        v-model="otpInput"
                        label="OTP Code"
                    >
                    </v-text-field>
                </v-card-text>
                <v-card-actions class="justify-end">
                <v-btn
                    text
                    @click="verifyOtp"
                >Verify</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
  export default {
    data: () => ({
        register: {
            name: '',
            username: '',
            email: '',
            phone: '',
            password: ''
        },
        show2: false,
        OTPdialog: false,
        otpInput: '',
        userId: ''
    }),
    mounted () {
    },
    created () {
        if(localStorage.getItem("token") !== null){
            this.$router.push({name: 'profile'})
        }
    },
    computed: {
    },
    methods: {
        showSnackBar(message){
            this.$store.commit("setMessage", 
            {show: true, message: message}, 
            {root: 1})
        },
        login(){
            this.$router.push({name: 'index'})
        },
        async registerBtn(){
            this.showSnackBar("Please wait...")
            const dataToPass = {
                name: this.register.name,
                username: this.register.username,
                email: this.register.email,
                phone: this.register.phone,
                password: this.register.password,
                frbuilder: true,
            }
            await this.$axios.post('register', dataToPass)
            .then(({data}) => {
                if(data.response){
                    this.showSnackBar(data.message)
                    this.OTPdialog = true
                    this.userId = data.id
                    // this.$router.push({name: 'index'})
                }else{
                    this.showSnackBar(data.message)
                    return
                }
            })
        },
        async verifyOtp(){
            const toPass = {
                id: this.userId,
                otp: this.otpInput
            };
            await this.$axios.post('verifyOtp', toPass)
            .then(({data}) => {
                if(data.response){
                    this.OTPdialog = false
                    this.showSnackBar(data.message)
                    this.$router.push({name: 'index'})
                }else{
                    this.showSnackBar(data.message)
                }
            })
        }
    },
    watch: {

    }
  }
</script>
<style lang="scss" scoped>
    tbody tr:nth-of-type(odd) {
        background-color: rgba(0, 0, 0, .05);
    }
</style>