<template>
    <v-container>
        <v-card>
            <v-card-title><small>Activity Logs</small></v-card-title>
            <v-card-text>
                <v-card>
                    meow
                </v-card>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
export default {
  components: {
  },
  props: [
  ],
  data () {
    return {
      url: null
    }
  },
  mounted () {
  },
  created () {
    if(localStorage.getItem("token") === null){
      this.$router.push({name: 'index'})
      return
    }
    this.$store.dispatch('setDrawer', true)
    this.url = process.env.VUE_APP_URL
  },
  computed: {
  },
  methods: {
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
</style>