<template>
    <v-container>
        <!-- <v-card>
            <v-card-title><small>Activity Logs</small></v-card-title>
            <v-card-text>
                <v-card>
                    
                </v-card>
            </v-card-text>
        </v-card> -->
        <v-btn
            elevation="2"
            class="ml-3 mt-5"
            color="primary"
            @click="DialyLogs()"
        >Daily Logs</v-btn>
        <v-btn
            elevation="2"
            class="ml-5  mt-5"
            color="primary"
            @click="WorkerLogs()"
        >Worker Logs</v-btn>
        <DialyLogs v-if="dailylogs"/>
        <WorkerLogs v-if="workerlogs"/>
    </v-container>
</template>

<script>

import DialyLogs from '../components/DailyLogs';
import WorkerLogs from '../components/WorkerLogs';

export default {
  components: {
      DialyLogs,
      WorkerLogs
  },
  props: [
  ],
  data () {
    return {
      dailylogs: true,
      workerlogs: false
    }
  },
  mounted () {
  },
  created () {
    if(localStorage.getItem("token") === null){
        this.$router.push({name: 'index'})
        return
    }
    this.$store.dispatch('setDrawer', true)
    this.url = process.env.VUE_APP_URL
  },
  computed: {
  },
  methods: {
      DialyLogs(){
        this.dailylogs = true;
        this.workerlogs = false;
      },
      WorkerLogs(){
        this.dailylogs = false;
        this.workerlogs = true;
      }
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
</style>